import { PayloadAction, configureStore } from '@reduxjs/toolkit';

import { RootState, rootReducer } from './rootReducer';

const RESET_STORE = 'RESET_STORE';

function reducer(state: RootState | undefined, action: PayloadAction) {
    if (action.type === RESET_STORE) {
        state = undefined;
    }

    return rootReducer(state, action);
}

export const store = configureStore({
    reducer,
});

export type AppDispatch = typeof store.dispatch;
