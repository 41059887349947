import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';
import Overlay from 'react-bootstrap/Overlay';
import { Tooltip } from 'react-bootstrap';

import { postMethod } from 'services/api.service';
import { EXPLORER_VIEW } from 'utils/constants';
import { customStyles } from 'utils/commonFunctions';
import { DashboardLayout } from 'layouts';
import { ExplorerMap, CardStructure, IconButton, IconLinkButton, Button, Toggle } from 'components';
import { ISelectOption } from 'models';
import { setLocation } from 'store/location';
import { MapLocation, MapViewport } from 'components/ExplorerMap/ExplorerMap.models';
import { useTooltipTimeout } from 'hooks/useTooltipTimeout';

import { stepsData } from './constants';
import { IExplorerData, IRegulation } from './models';
import {
    ExplorerViewHeader,
    CategoriesCheckbox,
    RegulationCard,
    RegulationRuleCardModal,
    StepDetailsModal,
    LevelCheckboxOption,
    LevelMultiValue,
    Messenger,
    StateOverviewModal,
} from './components';

interface IExplorerQuery {
    city?: string;
    county?: string;
    state?: string;
    technology: string[];
    map_viewport?: MapViewport;
    position?: MapLocation['position'];
}

export const ExplorerView = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showEnergyCommunityTooltip, setShowEnergyCommunityTooltip] = useTooltipTimeout();
    const [showLowIncomeTooltip, setShowLowIncomeTooltip] = useTooltipTimeout();
    const energyCommunityTarget = useRef(null);
    const lowIncomeCommunityTarget = useRef(null);
    const [showMessenger, setShowMessenger] = useState(false);
    const [selectedSubHeadings, setSelectedSubHeadings] = useState<string[]>([]);
    const [selectedLevels, setSelectedLevels] = useState<ISelectOption<string>[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [energyCommunityMetadata, setEnergyCommunityMetadata] = useState<string[]>([]);
    const [lowIncomeCommunityData, setLowIncomeCommunityData] = useState<string[]>([]);
    const [query, setQuery] = useState<IExplorerQuery>({
        technology: ['Solar', 'Energy Storage'],
        city: 'Southbridge',
        county: 'Worcester County',
        state: 'Massachusetts',
        map_viewport: {
            neLat: 42.11508597319612,
            neLng: -71.98830507108484,
            swLat: 42.0269160382098,
            swLng: -72.09981097695916,
        },
        position: {
            lat: 42.0751065,
            lng: -72.0333905,
        },
    });
    const [explorerData, setExplorerData] = useState<IExplorerData>();
    const [currentView, setCurrentView] = useState<string>();
    const [selectedRegulation, setSelectedRegulation] = useState<IRegulation>();
    const [showStepDetails, setShowStepDetails] = useState(false);
    const [showStateOverview, setShowStateOverview] = useState(false);
    const [showCoalClosure, setShowCoalClosure] = useState<boolean>(true);
    const [showMSA, setShowMSA] = useState<boolean>(true);

    const [showPPC, setShowPPC] = useState<boolean>(true);
    const [showCEJST, setShowCEJST] = useState<boolean>(true);
    const [showCategory1, setShowCategory1] = useState<boolean>(true);

    const [showLargeScaleSolar, setShowLargeScaleSolar] = useState<boolean>(true);
    const [showMediumScaleSolar, setShowMediumScaleSolar] = useState<boolean>(true);

    const regulationsFiltered = useMemo(() => {
        const regulations = explorerData?.regulations_data || [];

        const subHeadingsFilters = [
            ...selectedSubHeadings,
            ...selectedCategories.filter((category) => ['Incentive', 'Permit', 'Interconnection'].includes(category)),
        ];

        const categoriesFilters = [
            ...selectedCategories.filter((category) => !['Incentive', 'Permit', 'Interconnection'].includes(category)),
        ];

        return regulations
            .filter(
                (regulation) =>
                    selectedLevels.length === 0 || selectedLevels.some((level) => regulation.level?.split(' / ').includes(level.value)),
            )
            .filter((regulation) => subHeadingsFilters.length === 0 || subHeadingsFilters.includes(regulation.category || ''))
            .filter(
                (regulation) =>
                    categoriesFilters.length === 0 ||
                    categoriesFilters.some((category) => regulation.policy_type?.split(', ').includes(category)),
            );
    }, [explorerData?.regulations_data, selectedCategories, selectedLevels, selectedSubHeadings]);

    const mapPins = useMemo(
        () =>
            regulationsFiltered
                .filter(
                    (item) =>
                        typeof item?.lat === 'number' &&
                        typeof item?.lon === 'number' &&
                        !Number.isNaN(item?.lat) &&
                        !Number.isNaN(item?.lon),
                )
                .map((item) => ({
                    title: item.names,
                    lat: item.lat,
                    lng: item.lon,
                })),
        [regulationsFiltered],
    );

    const checkEnergyCommunity = () => {
        return explorerData?.energy_community_data?.is_coal_closure || explorerData?.energy_community_data?.is_non_msas;
    };

    const checkLowCommunity = () => {
        return (
            explorerData?.low_income_community_data?.cat_1_eligibility ||
            explorerData?.low_income_community_data?.cejst_energy ||
            explorerData?.low_income_community_data?.persistent_poverty_county
        );
    };

    const levelOptions = useMemo(() => {
        return explorerData?.filters?.levels?.map((item) => ({ value: item, label: item }));
    }, [explorerData?.filters?.levels]);

    const getExplorerViewData = useCallback(async () => {
        try {
            const { data } = await postMethod(EXPLORER_VIEW.GET_EXPLORER_VIEW, query);
            setExplorerData(data?.data);
        } catch (error) {
            if (error instanceof AxiosError && error?.response?.data?.status === false) {
                toast(error?.response?.data?.message);
            }
        }
    }, [query]);

    useEffect(() => {
        getExplorerViewData();
    }, [getExplorerViewData]);

    const handleNavigateToCreateProject = useCallback(() => {
        const locationValue = localStorage.getItem('locationValue');

        if (locationValue) {
            const locationData = JSON.parse(locationValue);
            dispatch(setLocation(locationData));
        }

        navigate('/create-project');
    }, [dispatch, navigate]);

    const handleUpdateCurrentView = useCallback(
        (view: string) => {
            if (view === currentView) {
                setCurrentView(undefined);
            } else {
                setCurrentView(view);
            }
        },
        [currentView],
    );

    const onChangeExplorerAddress = (address: MapLocation) => {
        setQuery({
            city: address.city,
            county: address.county,
            state: address.state,
            technology: query.technology,
            map_viewport: address.map_viewport,
            position: address.position,
        });
    };

    const communityData = useMemo(() => {
        const energyCommunityText = [];
        const lowIncomeText = [];

        if (explorerData?.energy_community_data?.is_coal_closure) energyCommunityText.push('2024 Coal Closure Energy Community');

        if (explorerData?.energy_community_data?.is_non_msas)
            energyCommunityText.push(
                '2024 MSA/non-MSA that meets both the Fossil Fuel Employment (FFE) threshold and unemployment rate requirement',
            );

        if (explorerData?.low_income_community_data?.cat_1_eligibility)
            lowIncomeText.push("Category 1: Meets the New Market Tax Credit Program's threshold for Low Income");

        if (explorerData?.low_income_community_data?.cejst_energy)
            lowIncomeText.push("Geographic Option 1: Meets the CEJST's threshold for Energy Burden disadvantage");

        if (explorerData?.low_income_community_data?.persistent_poverty_county)
            lowIncomeText.push("Geographic Option 2: Meets the USDA's threshold for Persistent Poverty");
        setEnergyCommunityMetadata(energyCommunityText);
        setLowIncomeCommunityData(lowIncomeText);

        return (
            explorerData?.energy_community_data?.is_coal_closure ||
            explorerData?.energy_community_data?.is_non_msas ||
            explorerData?.low_income_community_data?.cat_1_eligibility ||
            explorerData?.low_income_community_data?.cejst_energy ||
            explorerData?.low_income_community_data?.persistent_poverty_county
        );
    }, [explorerData]);

    return (
        <>
            <DashboardLayout headerTitle='Explorer View'>
                <div className='explorer-main pt4 pb10'>
                    <ExplorerViewHeader
                        technologies={explorerData?.filters?.technologies}
                        subHeadings={explorerData?.filters?.sub_headings}
                        selectedTechnologies={query.technology}
                        selectedSubHeadings={selectedSubHeadings}
                        onChangeTechnologies={(technologies) => setQuery({ ...query, technology: technologies })}
                        onChangeSubHeadings={setSelectedSubHeadings}
                    />

                    <div className='explorer-map-section pt12'>
                        <CardStructure className='card-spacing-24'>
                            <div className={`explorer-map-outer ${currentView}`}>
                                <div className={currentView === 'development-view' ? 'explorer-left d-none' : 'explorer-left'}>
                                    <div className='v-border-right' />
                                    <div className='btn-absolute'>
                                        <IconButton size='sm' className='border-icon' onClick={() => handleUpdateCurrentView('map-view')}>
                                            <i className='fi fi-rr-angle-small-left' />
                                        </IconButton>
                                        <div className='toolbar-tex'>Map</div>
                                    </div>
                                    <div className='search-property-main'>
                                        <ExplorerMap
                                            isSearch
                                            fullScreenView
                                            mapPins={mapPins}
                                            onChangeExplorerAddress={onChangeExplorerAddress}
                                            showCategory1={showCategory1}
                                            showCEJST={showCEJST}
                                            showCoalClosure={showCoalClosure}
                                            showMSA={showMSA}
                                            showPPC={showPPC}
                                            showLargeScaleSolar={showLargeScaleSolar}
                                            showMediumScaleSolar={showMediumScaleSolar}
                                        />
                                    </div>
                                    <h4 style={{ marginTop: 5 }} className='regulation-header'>
                                        Legend
                                    </h4>
                                    <span>IRA Low-Income Community</span>
                                    <div className='toogle-container'>
                                        <div className='toogle-line-container'>
                                            <div className='low-income-category-1-layer-legend-element'></div>
                                            <Toggle
                                                reverse={true}
                                                id='show-boundary'
                                                label='Category 1 Eligibility'
                                                value={showCategory1}
                                                onChange={() => setShowCategory1(!showCategory1)}
                                            />
                                        </div>
                                        <div className='toogle-line-container'>
                                            <div className='low-income-cejst-legend-element'></div>
                                            <Toggle
                                                reverse={true}
                                                id='show-boundary'
                                                label='Geographic Option 1 | CEJST Energy'
                                                value={showCEJST}
                                                onChange={() => setShowCEJST(!showCEJST)}
                                            />
                                        </div>
                                        <div className='toogle-line-container'>
                                            <div className='low-income-ppc-legend-element'></div>
                                            <Toggle
                                                reverse={true}
                                                id='show-boundary'
                                                label='Geographic Option 2 | Persistent Poverty County'
                                                value={showPPC}
                                                onChange={() => setShowPPC(!showPPC)}
                                            />
                                        </div>
                                    </div>
                                    <span>IRA Energy Community</span>
                                    <div className='toogle-container'>
                                        <div className='toogle-line-container'>
                                            <div className='energy-community-coal-closure-layer-legend-element'></div>
                                            <Toggle
                                                reverse={true}
                                                id='show-boundary'
                                                label='2024 Coal Closure Energy Community'
                                                value={showCoalClosure}
                                                onChange={() => setShowCoalClosure(!showCoalClosure)}
                                            />
                                        </div>
                                        <div className='toogle-line-container'>
                                            <div className='energy-community-msa-nmsa-layer-legend-element'></div>
                                            <Toggle
                                                reverse={true}
                                                id='show-boundary'
                                                label='2024 MSAs/non-MSAs that are Energy Communities'
                                                value={showMSA}
                                                onChange={() => setShowMSA(!showMSA)}
                                            />
                                        </div>
                                    </div>
                                    <span>Solar Zoning</span>
                                    <div className='toogle-container'>
                                        <div className='toogle-line-container'>
                                            <div className='solar-zoning-largeScale-legend-element'></div>
                                            <Toggle
                                                reverse={true}
                                                id='show-boundary'
                                                label='Large-scale Solar'
                                                value={showLargeScaleSolar}
                                                onChange={() => setShowLargeScaleSolar(!showLargeScaleSolar)}
                                            />
                                        </div>
                                        <div className='toogle-line-container'>
                                            <div className='solar-zoning-mediumScale-legend-element'></div>
                                            <Toggle
                                                reverse={true}
                                                id='show-boundary'
                                                label='Medium-scale Solar'
                                                value={showMediumScaleSolar}
                                                onChange={() => setShowMediumScaleSolar(!showMediumScaleSolar)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='explorer-right'>
                                    <div className='v-border-right' />
                                    <div className='btn-absolute disabled'>
                                        <IconButton
                                            size='sm'
                                            className='border-icon'
                                            onClick={() => handleUpdateCurrentView('regulation-view')}
                                        >
                                            <i className='fi fi-rr-angle-small-left' />
                                        </IconButton>
                                        <div className='toolbar-tex regular-toolbar'>Regulation list</div>
                                    </div>
                                    <div className='explorer-regulation-outer pl12'>
                                        <div className='explorer-regular-list'>
                                            <div className='regulation-select-outer pb4 pt1'>
                                                <div className='regulation-group-select'>
                                                    <ul className='regulation-rules-list'>
                                                        <li>
                                                            <div className='custom_form mb7'>
                                                                <Select
                                                                    isMulti
                                                                    options={levelOptions}
                                                                    className='custom-select-box'
                                                                    placeholder='Level'
                                                                    styles={customStyles}
                                                                    hideSelectedOptions={false}
                                                                    closeMenuOnSelect={false}
                                                                    isClearable={false}
                                                                    components={{
                                                                        Option: LevelCheckboxOption,
                                                                        MultiValue: LevelMultiValue,
                                                                    }}
                                                                    onChange={(options) => {
                                                                        setSelectedLevels([...options]);
                                                                    }}
                                                                    value={selectedLevels}
                                                                />
                                                            </div>
                                                        </li>
                                                        {explorerData?.filters?.categories && (
                                                            <li>
                                                                <div className='custom_form mb7'>
                                                                    <CategoriesCheckbox
                                                                        categories={explorerData.filters.categories}
                                                                        selectedCategories={selectedCategories}
                                                                        onChangeCategories={setSelectedCategories}
                                                                    />
                                                                </div>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                            {explorerData?.state_data?.overview && (
                                                <>
                                                    <h6 className='regulation-header pb-2'>State Overview</h6>
                                                    <RegulationCard
                                                        title={`${explorerData?.state_data?.name} Overview`}
                                                        btnName='View'
                                                        onClick={() => setShowStateOverview(true)}
                                                    />
                                                </>
                                            )}
                                            <h6 className='regulation-header'>IRA Bonus Adders</h6>
                                            <div className='area-category-container'>
                                                <div className='d-flex gap-4'>
                                                    <div className='regulation-title-container'>
                                                        <h5 className='categories-text'>
                                                            {checkEnergyCommunity() && <i className='fi fi-rr-check mr2 text-success' />}
                                                            <span className={checkEnergyCommunity() ? 'text-success' : ''}>
                                                                IRA Energy Community
                                                            </span>
                                                            {checkEnergyCommunity() && (
                                                                <i
                                                                    style={{ marginLeft: 5 }}
                                                                    ref={energyCommunityTarget}
                                                                    onClick={() =>
                                                                        setShowEnergyCommunityTooltip(!showEnergyCommunityTooltip)
                                                                    }
                                                                    className='fi fi-rr-comment-info text-success'
                                                                />
                                                            )}
                                                        </h5>
                                                        <Overlay
                                                            target={energyCommunityTarget.current}
                                                            show={showEnergyCommunityTooltip}
                                                            placement='top'
                                                        >
                                                            {(props) => (
                                                                <Tooltip {...props}>
                                                                    <div>
                                                                        <span>
                                                                            This address meets the following IRA {` `}
                                                                            <a
                                                                                target='blank2'
                                                                                style={{ color: 'blue', textDecoration: 'underline' }}
                                                                                href='https://arcgis.netl.doe.gov/portal/apps/experiencebuilder/experience/?data_id=dataSource_4-18fc72465d4-layer-9%3A123&id=a2ce47d4721a477a8701bd0e08495e1d'
                                                                            >
                                                                                Energy Community
                                                                            </a>
                                                                            {` `} eligibility criteria:
                                                                        </span>
                                                                        <ul style={{ marginTop: 10 }}>
                                                                            {energyCommunityMetadata.map((item, index) => (
                                                                                <li key={`energy-community-${index}`}>- {item}</li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                </Tooltip>
                                                            )}
                                                        </Overlay>
                                                    </div>
                                                </div>
                                                <div className='regulation-title-container'>
                                                    <h4 className='categories-text'>
                                                        {checkLowCommunity() && <i className='fi fi-rr-check mr2 text-success' />}
                                                        <span className={checkLowCommunity() ? 'text-success' : ''}>
                                                            IRA Low-Income Community
                                                        </span>
                                                        {checkLowCommunity() && (
                                                            <i
                                                                style={{ marginLeft: 5 }}
                                                                ref={lowIncomeCommunityTarget}
                                                                onClick={() => setShowLowIncomeTooltip(!showLowIncomeTooltip)}
                                                                className='fi fi-rr-comment-info text-success'
                                                            />
                                                        )}
                                                    </h4>
                                                    <Overlay
                                                        target={lowIncomeCommunityTarget.current}
                                                        show={showLowIncomeTooltip}
                                                        placement='top'
                                                    >
                                                        {(props) => (
                                                            <Tooltip {...props}>
                                                                <div>
                                                                    <span>This address meets the following IRA {` `}</span>
                                                                    <a
                                                                        style={{ color: 'blue', textDecoration: 'underline' }}
                                                                        target='blank'
                                                                        href='https://experience.arcgis.com/experience/12227d891a4d471497ac13f60fffd822/page/Page/#data_s=id%3AdataSource_3-18feb6fd304-layer-6%3A426'
                                                                    >
                                                                        Low-Income Community
                                                                    </a>
                                                                    {` `} eligibility criteria:
                                                                    <ul style={{ marginTop: 10 }}>
                                                                        {lowIncomeCommunityData.map((item, index) => (
                                                                            <li key={`low-income-${index}`}>- {item}</li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            </Tooltip>
                                                        )}
                                                    </Overlay>
                                                </div>
                                            </div>
                                            {communityData && (
                                                <div className='d-flex gap-4 text-success'>
                                                    <div className='regulation-title-container'>
                                                        <Overlay
                                                            target={energyCommunityTarget.current}
                                                            show={showEnergyCommunityTooltip}
                                                            placement='top'
                                                        >
                                                            {(props) => (
                                                                <Tooltip {...props}>
                                                                    <div>
                                                                        <ul style={{ marginTop: 10 }}>
                                                                            {energyCommunityMetadata.map((item, index) => (
                                                                                <li key={`energy-community-${index}`}>- {item}</li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                </Tooltip>
                                                            )}
                                                        </Overlay>
                                                    </div>
                                                </div>
                                            )}
                                            <hr className='h-border-top' />
                                        </div>
                                        <div className='regulation-card-wrap pt4'>
                                            <div className='regulation-card-inner max-h470'>
                                                {regulationsFiltered.map((item) => (
                                                    <RegulationCard
                                                        key={item.names}
                                                        title={item.names}
                                                        description={item?.description}
                                                        btnName='Find More'
                                                        onClick={() => setSelectedRegulation(item)}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                        <div className='regulation-bottom text-center pt10'>
                                            {Object.keys(query).length > 1 ? (
                                                <IconLinkButton className='filter-btn' disabled='disabled'>
                                                    Select a Property to Built a Development Plan
                                                </IconLinkButton>
                                            ) : (
                                                <Button
                                                    variant='outline-primary'
                                                    btnFullClassName='w-100'
                                                    size='sm'
                                                    onClick={() => handleUpdateCurrentView('development-view')}
                                                >
                                                    Synthesize Regulations with AI
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={
                                        currentView === 'development-view' ? 'development-plan-outer' : 'development-plan-outer d-none'
                                    }
                                >
                                    <div className='v-border-right' />
                                    <div className='btn-absolute'>
                                        <IconButton
                                            size='sm'
                                            className='border-icon '
                                            onClick={() => handleUpdateCurrentView('development-view')}
                                        >
                                            <i className='fi fi-rr-angle-small-left' />
                                        </IconButton>
                                        <div className='toolbar-tex development-toolbar'>Key Decisions Points</div>
                                    </div>

                                    <div className='explorer-regulation-outer pl12'>
                                        <div className='explorer-regular-list'>
                                            <div className='regular-header-box d-flex align-items-center justify-content-between gap-2 pb5'>
                                                <h4 className='text-semibold-16 text-cool-grey-800'>Key Decisions Points</h4>
                                            </div>
                                        </div>
                                        <div className='regulation-card-wrap pt4'>
                                            <div className='regulation-card-inner max-h556'>
                                                {stepsData.map((step) => (
                                                    <RegulationCard
                                                        key={step.title}
                                                        title={step.title}
                                                        description={step.descriptions.join(', ')}
                                                        btnName='Find More'
                                                        onClick={() => setShowStepDetails(true)}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                        <div className='regulation-bottom text-center pt10'>
                                            <Button
                                                variant='outline-primary'
                                                btnFullClassName='w-100'
                                                size='sm'
                                                onClick={handleNavigateToCreateProject}
                                            >
                                                Create a Project with this Plan
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardStructure>
                    </div>
                </div>
            </DashboardLayout>

            <Messenger className={showMessenger ? 'messenger-show' : ''} regulations={regulationsFiltered} />
            <Button variant='primary' size='lg' className='help-btn' onClick={() => setShowMessenger(!showMessenger)}>
                Get Help from AI
                <span className='icon-box icon-box-next ml4'>
                    <i className='fi fi-rr-messages' />
                </span>
            </Button>

            {selectedRegulation && (
                <RegulationRuleCardModal onClose={() => setSelectedRegulation(undefined)} regulation={selectedRegulation} />
            )}

            {showStepDetails && <StepDetailsModal onClose={() => setShowStepDetails(false)} />}

            {showStateOverview && <StateOverviewModal onClose={() => setShowStateOverview(false)} stateData={explorerData?.state_data} />}
        </>
    );
};
