import React from 'react';

import { CardStructure, TextChip } from 'components';

export const ProjectTimelineTab = () => (
    <div className='project-info-tab-card'>
        <CardStructure className='project-info-tab-card-box'>
            <div className='project-tab-card-inner'>
                <div className='project-info-header d-flex flex-wrap gap-2'>
                    <div className='project-info-left'>
                        <h4 className='text-cool-grey-800 text-bold-18'>Project Timeline</h4>
                    </div>
                    <div className='project-info-right'>
                        <TextChip bgColor='grey' className='badge-md'>
                            Draft
                        </TextChip>
                    </div>
                </div>
                <div className='project-info-body pt8'>
                    <div className='gantt-chart-box'>
                        <img src='/assets/images/Gantt-Chart.jpg' alt='gantt-chart' />
                    </div>
                </div>
            </div>
        </CardStructure>
    </div>
);
