import React from 'react';

import { Modal, ModalBody } from 'components';

interface SaveDraftModalProps {
    show: boolean;
    imageUrl?: string;
    title?: string;
    onClose?: () => void;
}

export const SaveDraftModal = ({ show, onClose, imageUrl, title }: SaveDraftModalProps) => (
    <Modal show={show} onClose={onClose} className='modal-wrapper modal-header-none' size='sm'>
        <ModalBody>
            <div className='verify-email-box max-w440 m-auto'>
                <div className='header-icon-box pb16 text-center'>
                    <img src={imageUrl} alt='icon' />
                </div>
                <div className='text-bold-28 pb16 text-cool-grey-800 text-center w-100'>{title}</div>
            </div>
        </ModalBody>
    </Modal>
);
