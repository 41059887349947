import React, { ReactNode } from 'react';
import Button from 'react-bootstrap/Button';

interface IconLinkButtonProp {
    className?: string;
    disabled?: string;
    size?: 'sm' | 'lg';
    variant?: string;
    onClick?: () => void;
    children?: ReactNode;
}

export const IconLinkButton = ({ className, disabled, size, variant, children, onClick }: IconLinkButtonProp) => (
    <Button
        className={`cstm-link-btn-icon ${className} ${disabled}`}
        size={size || 'sm'}
        variant={variant || 'link'}
        onClick={() => onClick && onClick()}
    >
        {children}
    </Button>
);
