import { KML_BUCKET_BASE_URL, US_STATES } from 'utils/constants';

type CategoryKey = 'MSA_NMSA' | 'lowIncomeCEJST' | 'lowIncomeCategory1' | 'lowIncomePPC' | 'CoalClosure';

type MultiFileState = {
    [state: string]: {
        [key in CategoryKey]?: number;
    };
};

const categoryBucketDirMaping: Record<CategoryKey, string> = {
    MSA_NMSA: 'energy-community/MSA_NMSA',
    lowIncomeCEJST: 'low-income/lowIncomeCEJST',
    lowIncomeCategory1: 'low-income/lowIncomeCategory1',
    lowIncomePPC: 'low-income/lowIncomePPC',
    CoalClosure: 'energy-community/CoalClosure',
};

const multiFileStates: MultiFileState = {
    Kentucky: { CoalClosure: 3, lowIncomeCategory1: 6 },
    'West Virginia': { CoalClosure: 4, lowIncomeCategory1: 3 },
    Texas: { MSA_NMSA: 6, lowIncomeCategory1: 13 },
    Alabama: { lowIncomeCategory1: 5 },
    Arizona: { lowIncomeCategory1: 4 },
    Arkansas: { lowIncomeCategory1: 4 },
    California: { lowIncomeCategory1: 13 },
    Florida: { lowIncomeCategory1: 6 },
    Georgia: { lowIncomeCategory1: 8 },
    Louisiana: { lowIncomeCategory1: 3 },
    Mississippi: { lowIncomeCategory1: 4 },
    Missouri: { lowIncomeCategory1: 4 },
    'North Carolina': { lowIncomeCategory1: 8 },
    Oregon: { lowIncomeCategory1: 4 },
    'South Carolina': { lowIncomeCategory1: 4 },
    Tennessee: { lowIncomeCategory1: 6 },
    Virginia: { lowIncomeCategory1: 8 },
    Washington: { lowIncomeCategory1: 3 },
};

const categories = ['CoalClosure', 'MSA_NMSA', 'lowIncomeCEJST', 'lowIncomePPC', 'lowIncomeCategory1'] as const;
type Category = (typeof categories)[number];

type State = (typeof US_STATES)[number];

export type KmlMappingType = {
    [key in Category | 'solarMedium' | 'solarLarge']: {
        [state in State | 'Southbridge']?: string[];
    };
};

const generateUrl = (category: string, state: string, index?: number) => {
    const baseUrl = `${KML_BUCKET_BASE_URL}/${categoryBucketDirMaping[category as CategoryKey]}`;
    const fileName = index ? `${state}/${state}-${index}.kmz` : `${state}.kmz`;

    return encodeURI(`${baseUrl}/${fileName}`);
};

export const kmlMapping = categories.reduce(
    (acc, category) => {
        acc[category] = US_STATES.reduce(
            (stateAcc, state) => {
                if (multiFileStates[state]?.[category as CategoryKey]) {
                    stateAcc[state] = Array.from({ length: multiFileStates[state][category as CategoryKey]! }, (_, i) =>
                        generateUrl(category, state, i + 1),
                    );
                } else {
                    stateAcc[state] = [generateUrl(category, state)];
                }

                return stateAcc;
            },
            {} as Record<State, string[]>,
        );

        return acc;
    },
    {
        solarMedium: {
            Southbridge: [generateUrl('zoning-map/mediumScale', 'Southbridge')],
        },
        solarLarge: {
            Southbridge: [generateUrl('zoning-map/largeScale', 'Southbridge')],
        },
    } as KmlMappingType,
);
