import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './NewPermitSubmitted.styles.scss';

import { DashboardLayout } from 'layouts';
import { Button, CardStructure, IconLinkButton, PermitModal, TextChip } from 'components';
import { PermitStatus } from 'pages/NewPermit/NewPermit.models';
import { selectPermit } from 'store/selectors';

export const NewPermitSubmitted = () => {
    const navigate = useNavigate();
    const permitInfo = useSelector(selectPermit);
    const [filePermitApplication, setFilePermitApplication] = useState(false);

    return (
        <>
            <DashboardLayout
                headerTitle=''
                onClickBackButton={() => {
                    navigate(-1);
                }}
            >
                <div className='new-permit-submitted d-flex flex-column justify-content-center align-items-center min-h100vh'>
                    <CardStructure className='align-items-center card-spacing-24 card-structure d-flex flex-column flex-grow-1 justify-content-center w-100'>
                        <div className='d-flex align-items-center gap-2'>
                            <span className='icon-box icon-sm pr4'>
                                <i className='fi fi-rr-check' />
                            </span>
                            <h2 className='text-bold-28 text-cool-grey-800'>Permit Application Submitted</h2>
                        </div>
                        <div className='my-4 d-flex flex-column align-items-center'>
                            <span className='text-cool-grey-800'>Your application is currently under review</span>
                            <span className='text-cool-grey-800'>Thank you for your patience.</span>
                        </div>
                        <TextChip bgColor='yellow'>{PermitStatus.IN_REVIEW}</TextChip>
                        <IconLinkButton
                            className='filter-btn mt-4'
                            onClick={() => setFilePermitApplication(true)}
                            disabled={!permitInfo?.id ? 'disabled' : ''}
                        >
                            <span className='icon-sm pr4'>
                                <i className='fi fi-rr-download' />
                            </span>
                            Download Application
                        </IconLinkButton>
                        <Button variant='primary' size='sm' className='mt-5' onClick={() => navigate('/projects')}>
                            Back to Projects
                        </Button>
                    </CardStructure>
                </div>
            </DashboardLayout>

            <PermitModal
                show={filePermitApplication}
                onClose={() => setFilePermitApplication(false)}
                title='Permit Application'
                permitId={permitInfo?.id}
            />
        </>
    );
};
