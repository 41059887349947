import React from 'react';

import { Button, Modal, ModalBody, ModalFooter } from 'components';

interface OtpPasswordModalProps {
    email: string;
    onClose: () => void;
    onConfirm: () => void;
}

export const OtpPasswordModal = ({ email, onClose, onConfirm }: OtpPasswordModalProps) => (
    <Modal show size='sm' onClose={onClose} title='Verify Your Email Address' backdrop='static' className='modal-wrapper modal-header-none'>
        <ModalBody>
            <div className='verify-email-box'>
                <div className='header-icon-box pb16 text-center'>
                    <img src='/assets/images/email-icon.svg' alt='email-icon' />
                </div>
                <div className='text-bold-28 pb8 text-cool-grey-800 text-center w-100'>OTP Password Sent to Your Email</div>
                <div className='subtitle'>
                    <p className='mb-0 text-center text-regular-16 text-cool-grey-500 pb16'>
                        Please check <span className='text-bold-16'>{email}</span> inbox
                    </p>
                </div>
            </div>
        </ModalBody>
        <ModalFooter className='flex-nowrap gap-2 max-w450 m-auto'>
            <Button variant='primary' size='sm' btnFullClassName='w-50 m-auto' onClick={onConfirm}>
                Next
                <span className='icon-box icon-box-next ml3'>
                    <i className='fi fi-rr-angle-small-right' />
                </span>
            </Button>
        </ModalFooter>
    </Modal>
);
