import Table from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { deleteMethod } from 'services/api.service';
import { PERMITS } from 'utils/constants';
import { TextChip, IconButton, ConfirmModal } from 'components';
import { IPermit } from 'models';

interface ProjectPermitsTableProps {
    permitListing: IPermit[];
}

export const ProjectPermitsTable = ({ permitListing }: ProjectPermitsTableProps) => {
    const [permitId, setPermitId] = useState<string | null | number>(null);
    const [permitName, setPermitName] = useState<string>();
    const [deletePermits, setDeletePermits] = useState(false);
    const navigate = useNavigate();

    const getPermitId = (permitId: number, permitName?: string) => {
        setPermitId(permitId);
        setPermitName(permitName);
        setDeletePermits(true);
    };

    const handleDeletePermit = async () => {
        try {
            const { data } = await deleteMethod(`${PERMITS.DELETE_PERMITS}?id=${permitId}`);

            if (data?.status === true) {
                const indexToRemove = permitListing.findIndex((permit) => permit?.id === permitId);

                if (indexToRemove !== -1) {
                    permitListing.splice(indexToRemove, 1);
                }
                toast(data?.message);
            }
            setDeletePermits(false);
        } catch (error) {
            if (error instanceof AxiosError && error?.response?.data?.status === false) {
                toast(error?.response?.data?.message);
            }
        }
    };

    return (
        <>
            <Table responsive className='custom-table table-project-permits'>
                <thead>
                    <tr>
                        <th>Permit Name</th>
                        <th className='status-th text-center w-120'>Status</th>
                        <th className='action-th w-90 text-center'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {permitListing?.map((permit) => (
                        <React.Fragment key={permit.id}>
                            <tr className='cursor-pointer'>
                                <td
                                    onClick={(e) => {
                                        if (permit.is_demo) return;
                                        e.stopPropagation();
                                        navigate(`/permit-details/${permit.id}`);
                                    }}
                                >
                                    <div className='table-content'>
                                        <p>{permit.name}</p>
                                    </div>
                                </td>
                                <td>
                                    <TextChip
                                        bgColor={
                                            permit.permit_status === 'Not Started'
                                                ? 'blue'
                                                : permit.permit_status === 'In Review'
                                                  ? 'yellow'
                                                  : permit.permit_status === 'Approved'
                                                    ? 'green'
                                                    : 'grey'
                                        }
                                        className='badge-xs'
                                    >
                                        {permit.permit_status}
                                    </TextChip>
                                </td>
                                <td className='action-button text-center'>
                                    <IconButton
                                        size='sm'
                                        onClick={() => getPermitId(permit.id, permit.name)}
                                        disabled={permit.is_demo ? 'disabled' : ''}
                                    >
                                        <i className='fi fi-rr-trash' />
                                    </IconButton>
                                    <IconButton
                                        size='sm'
                                        onClick={() => navigate(`/edit-permit/${permit.id}`)}
                                        disabled={permit.is_demo ? 'disabled' : ''}
                                    >
                                        <i className='fi fi-rr-edit' />
                                    </IconButton>
                                </td>
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </Table>

            <ConfirmModal
                show={deletePermits}
                onClose={() => setDeletePermits(false)}
                imageUrl='/assets/images/questionmark-icon.svg'
                title={`Delete the ${permitName}`}
                leftButtonLabel='Cancel'
                onClickLeftButton={() => setDeletePermits(false)}
                onClickRightButton={handleDeletePermit}
                rightButtonLabel='Confirm'
            />
        </>
    );
};
