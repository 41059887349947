import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import Stack from 'react-bootstrap/Stack';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { AxiosError } from 'axios';

import { Button, Card, HeaderLogo, Input, LinkButton } from 'components';
import { postWithOutAuthMethod } from 'services/api.service';
import { AUTH } from 'utils/constants';
import { setEmail } from 'store/email';

import { ForgotPasswordInput } from './ForgotPassword.models';

export const ForgotPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const initialData = { email: '' };

    const handleForgotPassword = async (values: ForgotPasswordInput) => {
        setIsLoading(true);
        dispatch(setEmail(values?.email));

        try {
            const { data } = await postWithOutAuthMethod(AUTH.FORGOT_PASSWORD, values);

            if (data?.status === true) {
                navigate('/link-sent');
            }
        } catch (error) {
            if (error instanceof AxiosError && error?.response?.data?.message) {
                toast(error?.response?.data?.message);
            } else {
                toast('Something went wrong');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
    });

    return (
        <div className='form-wrapper position-relative overflow-hidden'>
            <div className='bottom-shape bg-gradient-primary ' />
            <div className='from-inner-box'>
                <Stack gap={3} className='min-h100vh p20'>
                    <div className='form-header pb15'>
                        <HeaderLogo />
                    </div>
                    <div className='m-auto inner-form-box'>
                        <Card>
                            <Formik<ForgotPasswordInput>
                                enableReinitialize
                                initialValues={initialData}
                                onSubmit={(values) => {
                                    handleForgotPassword(values);
                                }}
                                validationSchema={validationSchema}
                            >
                                {({ values, errors, isValid, handleChange, handleSubmit }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <div className='form-header pb16'>
                                            <h3 className='text-bold-28 text-center pb5'>Forgot Password?</h3>
                                            <p className='text-center text-cool-grey-500 text-regular-15 mb-0'>
                                                Enter your email. We will email instructions on how to reset your password.
                                            </p>
                                        </div>
                                        <div className='inner-form-box-detail'>
                                            <Input
                                                labelName='Email'
                                                placeholder='Enter your email'
                                                type='email'
                                                size='md'
                                                value={values.email}
                                                onChange={handleChange('email')}
                                                isInvalid={!!errors.email}
                                                errorMsg={errors?.email}
                                            />
                                            <div className='btn-list pt15'>
                                                <Button
                                                    type='submit'
                                                    variant='primary'
                                                    btnFullClassName='w-100'
                                                    size='lg'
                                                    className='mb8'
                                                    disabled={isValid ? '' : 'disabled'}
                                                    spinner={isLoading}
                                                >
                                                    Submit
                                                </Button>
                                                <div className='account-login'>
                                                    <p className='text-regular-16 text-cool-grey-800 text-center'>
                                                        Back to
                                                        <LinkButton linkTo='/' className='ml2'>
                                                            Sign In
                                                        </LinkButton>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </Card>
                    </div>
                </Stack>
            </div>
        </div>
    );
};
