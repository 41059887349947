import React, { FunctionComponent, ReactNode } from 'react';
import Badge from 'react-bootstrap/Badge';

interface TextChipProps {
    className?: string;
    children: ReactNode;
    bgColor: string;
}

export const TextChip: FunctionComponent<TextChipProps> = ({ children, className, bgColor }: TextChipProps) => (
    <div className='custom-chip'>
        <Badge pill bg={bgColor} className={`badge-sm ${className}`}>
            {children}
        </Badge>
    </div>
);
