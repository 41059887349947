import React from 'react';
import { ToggleButtonGroup, Nav, Tab, ToggleButton } from 'react-bootstrap';

interface ExplorerViewHeaderProps {
    technologies?: string[];
    subHeadings?: string[];
    selectedTechnologies: string[];
    selectedSubHeadings: string[];
    onChangeTechnologies: (technologies: string[]) => void;
    onChangeSubHeadings: (subHeadings: string[]) => void;
}

export const ExplorerViewHeader = ({
    technologies = [],
    subHeadings = [],
    selectedTechnologies,
    selectedSubHeadings,
    onChangeTechnologies,
    onChangeSubHeadings,
}: ExplorerViewHeaderProps) => {
    const getIconForTechnology = (technology: string) => {
        switch (technology) {
            case 'Solar':
                return 'fi fi-rr-sun';
            case 'Energy Storage':
                return 'fi fi-rr-apps';
            case 'EV Charging':
                return 'fi fi-rr-loading';
            default:
                return 'fi fi-rr-wind';
        }
    };

    return (
        <div className='custom-tab explorer-view-tab'>
            <Tab.Container id='left-tabs-example' defaultActiveKey='Solar'>
                <div className='tab-header'>
                    <Nav variant='pills'>
                        <ToggleButtonGroup
                            type='checkbox'
                            className='custom-multi-check-tab'
                            value={selectedTechnologies}
                            onChange={onChangeTechnologies}
                        >
                            {technologies.map((technology) => (
                                <ToggleButton id={`tbg-check-${technology}`} key={technology} value={technology}>
                                    <span className='icon-tab'>
                                        <i className={getIconForTechnology(technology)} />
                                    </span>
                                    {technology}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                        <ToggleButtonGroup
                            type='checkbox'
                            className='custom-multi-check-sub-heading py4'
                            value={selectedSubHeadings}
                            onChange={onChangeSubHeadings}
                        >
                            {subHeadings.map((subHeading) => (
                                <ToggleButton id={`tbg-check-${subHeading}`} key={subHeading} value={subHeading}>
                                    {subHeading}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </Nav>
                </div>
            </Tab.Container>
        </div>
    );
};
