import React, { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface PrivateRouteProps {
    children: ReactNode;
}

export const PrivateRoute = ({ children }: PrivateRouteProps) => {
    const location = useLocation();

    const token = localStorage.getItem('token');

    const isAuthenticated = token !== null;

    if (!isAuthenticated) {
        return <Navigate to='/' state={{ from: location }} />;
    }

    return children;
};
