import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import {
    ProjectsSearch,
    Projects,
    ProjectInfo,
    Dashboard,
    Login,
    ChangePassword,
    ForgotPassword,
    LinkSent,
    Register,
    CreateProject,
    CreateProjectStepTwo,
    EditPermit,
    EditPermitStepTwo,
    EditProject,
    ExplorerView,
    NewPermit,
    NewPermitStepTwo,
    NewPermitSubmitted,
    Incentives,
    Mobile,
    PermitDetails,
    Permits,
    Profile,
    EditProjectStepTwo,
} from 'pages';

import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from './PrivateRoute';

export const AppRoutes = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Routes>
            <Route path='/' element={<PublicRoute>{isMobile ? <Navigate to='/mobile' /> : <Login />}</PublicRoute>} />
            <Route path='/sign-up' element={<PublicRoute>{isMobile ? <Navigate to='/mobile' /> : <Register />}</PublicRoute>} />
            <Route
                path='/forgot-password'
                element={<PublicRoute>{isMobile ? <Navigate to='/mobile' /> : <ForgotPassword />}</PublicRoute>}
            />
            <Route path='/link-sent' element={<PublicRoute>{isMobile ? <Navigate to='/mobile' /> : <LinkSent />}</PublicRoute>} />
            <Route
                path='/change-password?/:id?/:token'
                element={<PublicRoute>{isMobile ? <Navigate to='/mobile' /> : <ChangePassword />}</PublicRoute>}
            />
            <Route path='/dashboard' element={<PrivateRoute>{isMobile ? <Navigate to='/mobile' /> : <Dashboard />}</PrivateRoute>} />
            <Route
                path='/create-project/:projectId?'
                element={<PrivateRoute>{isMobile ? <Navigate to='/mobile' /> : <CreateProject />}</PrivateRoute>}
            />
            <Route
                path='/create-project-step-two/:projectId?'
                element={<PrivateRoute>{isMobile ? <Navigate to='/mobile' /> : <CreateProjectStepTwo />}</PrivateRoute>}
            />
            <Route path='/explorer-view' element={<PrivateRoute>{isMobile ? <Navigate to='/mobile' /> : <ExplorerView />}</PrivateRoute>} />
            <Route path='/profile' element={<PrivateRoute>{isMobile ? <Navigate to='/mobile' /> : <Profile />}</PrivateRoute>} />
            <Route path='/projects' element={<PrivateRoute>{isMobile ? <Navigate to='/mobile' /> : <Projects />}</PrivateRoute>} />
            <Route
                path='/project-info?/:projectId'
                element={<PrivateRoute>{isMobile ? <Navigate to='/mobile' /> : <ProjectInfo />}</PrivateRoute>}
            />
            <Route
                path='/edit-project/:projectId'
                element={<PrivateRoute>{isMobile ? <Navigate to='/mobile' /> : <EditProject />}</PrivateRoute>}
            />
            <Route
                path='/edit-project-step-two/:projectId'
                element={<PrivateRoute>{isMobile ? <Navigate to='/mobile' /> : <EditProjectStepTwo />}</PrivateRoute>}
            />
            <Route
                path='/new-permit/:projectId?'
                element={<PrivateRoute>{isMobile ? <Navigate to='/mobile' /> : <NewPermit />}</PrivateRoute>}
            />
            <Route
                path='/new-permit-step-two/:projectId?'
                element={<PrivateRoute>{isMobile ? <Navigate to='/mobile' /> : <NewPermitStepTwo />}</PrivateRoute>}
            />
            <Route
                path='/new-permit-submitted/:projectId?'
                element={<PrivateRoute>{isMobile ? <Navigate to='/mobile' /> : <NewPermitSubmitted />}</PrivateRoute>}
            />
            <Route
                path='/permit-details/:permitId?'
                element={<PrivateRoute>{isMobile ? <Navigate to='/mobile' /> : <PermitDetails />}</PrivateRoute>}
            />
            <Route
                path='/edit-permit/:permitId?'
                element={<PrivateRoute>{isMobile ? <Navigate to='/mobile' /> : <EditPermit />}</PrivateRoute>}
            />
            <Route
                path='/edit-permit-step-two/:permitId?'
                element={<PrivateRoute>{isMobile ? <Navigate to='/mobile' /> : <EditPermitStepTwo />}</PrivateRoute>}
            />
            <Route path='/permits' element={<PrivateRoute>{isMobile ? <Navigate to='/mobile' /> : <Permits />}</PrivateRoute>} />
            <Route
                path='/search-filed'
                element={<PrivateRoute>{isMobile ? <Navigate to='/mobile' /> : <ProjectsSearch />}</PrivateRoute>}
            />
            <Route path='/incentives' element={<PrivateRoute>{isMobile ? <Navigate to='/mobile' /> : <Incentives />}</PrivateRoute>} />
            <Route path='/mobile' element={!isMobile ? <Navigate to='/' /> : <Mobile />} />
        </Routes>
    );
};
