import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { Button, Modal, ModalBody, ModalFooter } from 'components';

interface StepDetailsModalProps {
    onClose: () => void;
}

export const StepDetailsModal = ({ onClose }: StepDetailsModalProps) => (
    <Modal show size='lg' onClose={onClose} className='modal-wrapper modal-project'>
        <ModalBody>
            <div className='verify-email-box'>
                <div className='text-bold-18 text-cool-grey-800  w-100 text-center'>Required Permits</div>
            </div>
            <div className='project-popup-card-outer-box'>
                <div className='project-popup-card-outer'>
                    <div className='project-popup-main'>
                        <div className='project-popup-inner'>
                            <div className='project-popup-body'>
                                <Row>
                                    <Col xs='12' md='12' lg='6'>
                                        <div className='project-popup-detail'>
                                            <div className='label-name text-regular-14 text-cool-grey-500'>1. Zoning Permit</div>
                                            <div className='project-popup-content text-regular-12 text-cool-grey-800 pt1'>
                                                Obtaining a zoning permit for your project incorporating Photovoltaic (PV) solar panels,
                                                battery storage systems, and Electric Vehicle (EV) charging stations is a key step in
                                                project management. This permit ensures your project aligns with local regulations and
                                                zoning codes, a critical aspect of project planning and execution. The process includes a
                                                comprehensive review of your project&#39;s specifications, site plans, and its potential
                                                impact on the surrounding environment and community.
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs='12' md='12' lg='6'>
                                        <div className='project-popup-detail'>
                                            <div className='label-name text-regular-14 text-cool-grey-500'>2. Electrical Permit</div>
                                            <div className='project-popup-content text-regular-12 text-cool-grey-800 pt1'>
                                                In San Diego, obtaining an electrical permit for a project incorporating Photovoltaic (PV)
                                                solar, battery storage, and Electric Vehicle (EV) charging systems demands a thorough
                                                understanding of the California Electrical Code (CEC) and a meticulous assessment of circuit
                                                loads. This process ensures that the design and installation of your renewable energy system
                                                not only align with state and local safety standards but also efficiently manage the
                                                electrical demand and distribution across the system.
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs='12' md='12' lg='6'>
                                        <div className='project-popup-detail'>
                                            <div className='label-name text-regular-14 text-cool-grey-500'>3. Building Permit:</div>
                                            <div className='project-popup-content text-regular-12 text-cool-grey-800 pt1'>
                                                Securing a building permit for a PV solar, battery storage, and EV charging project in San
                                                Diego requires adherence to both California state and local San Diego regulations.
                                                Applicants must submit detailed site plans, structural assessments, and environmental impact
                                                analyses that demonstrate compliance with building codes, zoning laws, and energy efficiency
                                                standards. This step ensures the project&#39;s structural integrity, safety, and alignment
                                                with San Diego&#39;s commitment to sustainable development and renewable energy initiatives.
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs='12' md='12' lg='6'>
                                        <div className='project-popup-detail'>
                                            <div className='label-name text-regular-14 text-cool-grey-500'>
                                                4. Permit Approval & Inspections:
                                            </div>
                                            <div className='project-popup-content text-regular-12 text-cool-grey-800 pt1'>
                                                After obtaining permit approval for a PV solar, battery storage, and EV charging project in
                                                San Diego, the next steps involve project execution and compliance with inspection
                                                protocols.
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ModalBody>
        <ModalFooter className='flex-nowrap justify-content-center gap-2'>
            <Button variant='primary' size='sm' className='w-100 max-w200' onClick={onClose}>
                Got It
            </Button>
        </ModalFooter>
    </Modal>
);
