import React from 'react';

import { DashboardLayout } from 'layouts';
import { CardStructure, ProjectsTable } from 'components';

export const ProjectsSearch = () => (
    <DashboardLayout>
        <div className='latest-permits-table pt4'>
            <CardStructure>
                <ProjectsTable />
            </CardStructure>
            <div className='no-data-search d-flex flex-column justify-content-center align-items-center d-none'>
                <div className='no-data-inner'>
                    <img src='/assets/images/no-data.svg' alt='no-data' />
                    <p className='text-bold-20 text-cool-grey-800 pt5'>No Data Found</p>
                </div>
            </div>
        </div>
    </DashboardLayout>
);
