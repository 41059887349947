import React, { ChangeEvent, useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';

import { SearchBox } from 'components';

interface StatusOption {
    id: string;
    label: string;
}

interface FilterAccordionProps {
    title: string;
    statuses: StatusOption[];
    selectedStatuses?: string[];
    onCheckboxChange: (title: string, name: string, checked: boolean) => void;
    titleName?: string;
}

export const FilterAccordion = ({ title, statuses, selectedStatuses = [], onCheckboxChange, titleName }: FilterAccordionProps) => {
    const [newStatuses, setNewStatuses] = useState<StatusOption[]>([]);

    useEffect(() => {
        setNewStatuses(statuses);
    }, [statuses]);

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        onCheckboxChange(title, name, checked);
    };

    const handleSearch = (data: string) => {
        if (statuses?.length > 4) {
            const filteredData = statuses.filter((item) => item.label.toLowerCase().includes(data.toLowerCase()));
            setNewStatuses(filteredData);
        }
    };

    return (
        <Accordion className='accordion-custom-light' defaultActiveKey={['technology', 'state']} alwaysOpen>
            <Accordion.Item eventKey={title}>
                <Accordion.Header>{titleName}</Accordion.Header>
                <Accordion.Body>
                    <ul className='filter-listing-check'>
                        <div className='search-filter pb6'>
                            {titleName === 'State' && (
                                <SearchBox
                                    placeholder='Search by title'
                                    type='text'
                                    size='sm'
                                    searchClassName='w-100'
                                    onSearch={handleSearch}
                                />
                            )}
                        </div>
                        {newStatuses.length > 0 &&
                            newStatuses.map(({ id, label }) => (
                                <li key={id}>
                                    <div className='cstm-checkbox form-check'>
                                        <input
                                            type='checkbox'
                                            name={id}
                                            id={`${title}-${id}`}
                                            onChange={handleCheckboxChange}
                                            checked={selectedStatuses.includes(id)}
                                            className='form-check-input'
                                        />
                                        <label className='text-regular-15 text-cool-grey-500 form-check-label' htmlFor={`${title}-${id}`}>
                                            {label}
                                        </label>
                                    </div>
                                </li>
                            ))}
                        {newStatuses.length === 0 && <div className='text-regular-15 text-cool-grey-500'>No state has been found</div>}
                    </ul>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};
