import {
    ICreatePermit,
    ICreatePermitBackendPayload,
    PropertyOwnershipType,
    ElectricUtilityType,
    ResponsibleForPayingElectricServicesType,
    GasUtilityType,
    PermitStatus,
} from 'pages/NewPermit/NewPermit.models';

export function mapCreatePermitToModel(createPermit: ICreatePermit): ICreatePermitBackendPayload {
    return {
        name: createPermit.name,
        project_id: createPermit.project_id,
        host_customer_date: createPermit.host_customer.date,
        host_customer_name: createPermit.host_customer.name,
        host_customer_company: createPermit.host_customer.company,
        host_customer_address: createPermit.host_customer.address,
        host_customer_email: createPermit.host_customer.email,
        host_customer_phone: createPermit.host_customer.phone,
        project_site_name: createPermit.project_site.name,
        project_site_total_units: Number(createPermit.project_site.total_units),
        project_site_address: createPermit.project_site.address,
        project_site_ownership: createPermit.project_site.ownership_type,
        property_eligibility: {
            is_within_median_income_area: createPermit.property_eligibility.is_within_median_income_area,
            is_disadvantaged_community: createPermit.property_eligibility.is_disadvantaged_community,
            is_owned_by_a_tribe: createPermit.property_eligibility.is_owned_by_a_tribe,
            is_rental_housing: createPermit.property_eligibility.is_rental_housing,
            census_tract_code: createPermit.property_eligibility.census_tract_code,
            has_certificate_of_occupancy: createPermit.property_eligibility.has_certificate_of_occupancy,
            has_solar_construction_started: createPermit.property_eligibility.has_solar_construction_started,
            was_referral_to_SOMAH_program: createPermit.property_eligibility.was_referral_to_SOMAH_program,
        },
        electrical_utility_name: createPermit.electrical_utility.utility,
        electrical_utility_is_CCA: createPermit.electrical_utility.does_receive_electric_service_from_CCA,
        electrical_utility_payment_responsible: createPermit.electrical_utility.responsible_for_paying_electric_services,
        electrical_utility_meters_of_common_areas: Number(createPermit.electrical_utility.meters_of_common_areas),
        gas_utility_name: createPermit.gas_utility.utility,
        permit_status: createPermit?.status || PermitStatus.NOT_STARTED,
        is_active: true,
        ...(createPermit.id ? { id: createPermit.id } : {}),
    };
}

export function mapBackendPayloadToCreatePermit(backendPayload: ICreatePermitBackendPayload): ICreatePermit {
    return {
        id: backendPayload.id,
        name: backendPayload.name,
        project_id: backendPayload.project_id,
        host_customer: {
            date: backendPayload.host_customer_date,
            name: backendPayload.host_customer_name,
            company: backendPayload.host_customer_company,
            address: backendPayload.host_customer_address,
            email: backendPayload.host_customer_email,
            phone: backendPayload.host_customer_phone,
        },
        project_site: {
            name: backendPayload.project_site_name,
            total_units: Number(backendPayload.project_site_total_units || 0), // Ensure numeric conversion
            address: backendPayload.project_site_address,
            ownership_type: backendPayload.project_site_ownership as keyof typeof PropertyOwnershipType, // Cast to keyof enum type
        },
        property_eligibility: {
            is_within_median_income_area: backendPayload.property_eligibility?.is_within_median_income_area,
            is_disadvantaged_community: backendPayload.property_eligibility?.is_disadvantaged_community,
            is_owned_by_a_tribe: backendPayload.property_eligibility?.is_owned_by_a_tribe,
            is_rental_housing: backendPayload.property_eligibility?.is_rental_housing,
            census_tract_code: backendPayload.property_eligibility?.census_tract_code,
            has_certificate_of_occupancy: backendPayload.property_eligibility?.has_certificate_of_occupancy,
            has_solar_construction_started: backendPayload.property_eligibility?.has_solar_construction_started,
            was_referral_to_SOMAH_program: backendPayload.property_eligibility?.was_referral_to_SOMAH_program,
        },
        electrical_utility: {
            utility: backendPayload.electrical_utility_name as keyof typeof ElectricUtilityType, // Cast to keyof enum type
            does_receive_electric_service_from_CCA: backendPayload.electrical_utility_is_CCA,
            responsible_for_paying_electric_services:
                backendPayload.electrical_utility_payment_responsible as keyof typeof ResponsibleForPayingElectricServicesType, // Cast to keyof enum type
            meters_of_common_areas: Number(backendPayload.electrical_utility_meters_of_common_areas), // Ensure numeric conversion
        },
        gas_utility: {
            utility: backendPayload.gas_utility_name as keyof typeof GasUtilityType, // Cast to keyof enum type
        },
        project_data: backendPayload.project_data,
    };
}
