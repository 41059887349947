import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import { Modal, ModalBody, ModalFooter, Button, OtpInput } from 'components';
import { postWithOutAuthMethod } from 'services/api.service';
import { AUTH } from 'utils/constants';
import { LoginInput } from '../../Login.models';
import { setEmail } from 'store/email';

interface VerifyEmailModalProps {
    onClose?: () => void;
    loginData: LoginInput;
    isGoogle?: boolean;
}

export const VerifyEmailModal = ({ onClose, loginData, isGoogle }: VerifyEmailModalProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [otp, setOtp] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleChangeOtp = (value: string) => {
        const otpValue = value.trim();
        setOtp(otpValue);
    };

    const handleSubmit = useCallback(async () => {
        setIsLoading(true);
        const params = { ...loginData, otp };
        let url = '';

        if (isGoogle) {
            url = AUTH.GOOGLE_REDIRECT;
        } else {
            url = AUTH.REDIRECT_DASHBOARD;
        }
        try {
            const { data } = await postWithOutAuthMethod(url, params);

            if (loginData.remember_me) {
                sessionStorage.setItem('remember_me', `${loginData.remember_me}`);
                sessionStorage.setItem('email', loginData.email);
            }

            if (data?.status === true) {
                localStorage.setItem('token', data?.access);
                dispatch(setEmail(data?.user_email));
                localStorage.setItem('email', data?.user_email);
                navigate('/dashboard');
            } else if (data.status === false) {
                toast(data?.message);
                setOtp('');
            }
        } catch (error) {
            if (error instanceof AxiosError && error?.response?.data?.status === false) {
                toast(error?.response?.data?.message);
            }
        } finally {
            setIsLoading(false);
        }
    }, [dispatch, isGoogle, loginData, navigate, otp]);

    return (
        <Modal show onClose={onClose} title='Verify Your Email Address' backdrop='static' className='modal-wrapper'>
            <ModalBody>
                <div className='verify-email-box'>
                    <div>
                        <div className='subtitle'>
                            <p className='mb-0 text-center text-regular-16 text-cool-grey-500 pb16'>
                                Enter the 6 digit code we have sent to your email
                            </p>
                        </div>
                        <div className='custom_form verify-email-form max-w450 m-auto pb15'>
                            <div className='verify-email-list verify-field d-flex align-items-center justify-content-between gap-4'>
                                <OtpInput value={otp} valueLength={6} onChange={handleChangeOtp} />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className='flex-nowrap gap-2 max-w450 m-auto'>
                <Button onClick={onClose} variant='outline-primary' btnFullClassName='w-100' size='sm'>
                    Cancel
                </Button>
                <Button
                    variant='primary'
                    size='sm'
                    btnFullClassName='w-100'
                    onClick={handleSubmit}
                    disabled={otp?.length < 6 ? 'disabled' : ''}
                    spinner={isLoading}
                >
                    Verify
                </Button>
            </ModalFooter>
        </Modal>
    );
};
