import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { DashboardLayout } from 'layouts';
import { Button, CardStructure, ExplorerMap, InfoModal, ProjectStepHeader } from 'components';
import { getMethod } from 'services/api.service';
import { PROJECTS } from 'utils/constants';
import { setProject } from 'store/project';
import { IProjectDetail } from 'models';

export const EditProject = () => {
    const [infoModal, setInfoModal] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { projectId } = useParams<{ projectId: string }>();
    const [projectDetails, setProjectDetails] = useState<IProjectDetail>();

    const getProjectData = useCallback(
        async (params?: string) => {
            try {
                let url: string = PROJECTS.PROJECT;

                if (params) {
                    url = `${PROJECTS.PROJECT}?id=${projectId}&sort=all`;
                }
                const { data } = await getMethod(url);
                dispatch(setProject(data?.data));
                setProjectDetails(data?.data);
            } catch (error) {
                // TODO: Error handling
            }
        },
        [dispatch, projectId],
    );

    useEffect(() => {
        if (projectId) {
            getProjectData(projectId);
        }
    }, [getProjectData, projectId]);

    return (
        <>
            <DashboardLayout headerTitle='Edit Project' onClickBackButton={() => navigate(-1)}>
                <div className='create-project-main pt4'>
                    <CardStructure className='card-spacing-24'>
                        <ProjectStepHeader
                            activeStep1='active'
                            value1='1'
                            value2='2'
                            step1Label='Select Property'
                            step2Label='Project Details'
                        />
                        <div className='create-project-body edit-project-map d-flex flex-wrap pt12'>
                            <div className='map-left map-full'>
                                <ExplorerMap isSearch locationData={projectDetails?.location} />
                            </div>
                        </div>

                        <div className='footer-project text-end pt12'>
                            <Button variant='primary' size='sm' onClick={() => navigate(`/edit-project-step-two/${projectId}`)}>
                                Next
                            </Button>
                        </div>
                    </CardStructure>
                </div>
            </DashboardLayout>

            <InfoModal
                show={infoModal}
                onClose={() => setInfoModal(false)}
                modalTitle='Zip Code'
                title='A ZIP Code is a postal code used by the United States Postal Service (USPS) and is an acronym for Zone Improvement Plan. The first five digits indicate the destination post office or delivery area. The last 4 digits represent specific delivery routes within delivery areas.'
                confirmButtonLabel='Got It'
                confirmButtonClassName='min-w190'
                onConfirm={() => setInfoModal(false)}
            />
        </>
    );
};
