export const baseURL = process.env.REACT_APP_STAGE_API_BASE_URL;
export const KML_BUCKET_BASE_URL = 'https://storage.googleapis.com/map-view-kmls';

export const AUTH = {
    LOGIN: 'api/login/',
    REGISTER: 'api/register/',
    FORGOT_PASSWORD: 'api/forgot_password/',
    RESET_PASSWORD: 'api/reset_password_from_link/',
    VERIFY_EMAIL: 'api/verify_email/',
    REDIRECT_DASHBOARD: 'api/redirect_to_dashboard/',
    GOOGLE_AUTH_LOGIN: 'api/google_login/',
    GOOGLE_AUTH_REGISTER: 'api/google_register/',
    GOOGLE_REDIRECT: 'api/google_redirect/',
};

export const DASHBOARD = {
    DASHBOARD_INFO: 'api/dashboard/',
};

export const PROFILE = {
    LOGOUT: 'api/logout/',
    DELETE_ACCOUNT: 'api/delete_account/',
};

export const EXPLORER_VIEW = {
    GET_EXPLORER_VIEW: 'api/explorer/',
};

export const PROJECTS = {
    PROJECT: 'api/project_management/',
    PROJECTS_LISTS: 'api/project_list/',
    GET_RECENT_PROJECT: 'api/recent_projects/',
};

export const CREATE_PROJECT = {
    GET_PROJECT_DATA: 'api/project/',
    CREATE_PROJECT: 'api/project_management/',
};

export const PERMITS = {
    PERMITS: 'api/permit_management/',
    GET_PERMITS_PROJECTS: 'api/project_list/',
    GET_PERMIT_PROJECT_DETAILS: 'api/permit/project-details',
    GET_PERMITS_LISTINGS: 'api/permit_list/',
    DELETE_PERMITS: 'api/permit_management/',
    DOWNLOAD_PDF: 'api/download_pdf/',
};

export const CHATBOT = {
    CHAT: 'bot/bot_answers/',
};

export const NOTIFICATIONS = {
    NOTIFICATION: 'api/notifications/',
};

export const US_STATES = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
] as const;
