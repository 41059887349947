import React from 'react';

import { Button, Input, Modal, ModalBody, ModalFooter } from 'components';

interface ChangeEmailModalProps {
    show: boolean;
    modalTitle?: string;
    imageUrl?: string;
    title?: string;
    leftButtonLabel?: string;
    rightButtonLabel?: string;
    labelName?: string;
    placeholder?: string;
    imageClassName?: string;
    disabled?: boolean;
    onClose?: () => void;
    onClickLeftButton?: () => void;
    onClickRightButton?: () => void;
}

export const ChangeEmailModal = ({
    show,
    onClose,
    modalTitle,
    imageUrl,
    title,
    onClickLeftButton,
    leftButtonLabel,
    onClickRightButton,
    rightButtonLabel,
    labelName,
    placeholder,
    imageClassName,
    disabled,
}: ChangeEmailModalProps) => (
    <Modal show={show} onClose={onClose} title={modalTitle} className='modal-wrapper modal-header-none' size='sm'>
        <ModalBody>
            <div className='verify-email-box max-w440 m-auto'>
                <div className='header-icon-box pb16 text-center '>
                    <img src={imageUrl} alt='email-icon' className={`${imageClassName}`} />
                </div>
                <div className='text-bold-28 pb12 text-cool-grey-800 text-center w-100'>{title}</div>
                <div className='email-address-field pb8'>
                    <Input labelName={labelName} placeholder={placeholder || ''} type='email' size='md' />
                </div>
            </div>
        </ModalBody>
        <ModalFooter className='flex-nowrap gap-2 max-w440 m-auto'>
            <Button onClick={onClickLeftButton} variant='outline-primary' btnFullClassName='w-100' size='sm'>
                {leftButtonLabel}
            </Button>
            <Button variant='primary' size='sm' btnFullClassName='w-100' onClick={onClickRightButton} disabled={disabled ? 'disabled' : ''}>
                {rightButtonLabel}
            </Button>
        </ModalFooter>
    </Modal>
);
