import React, { useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import parseHtml from 'html-react-parser';

import { IconButton, IconLinkButton } from 'components';
import { INotification } from 'models';
import { fetchNotifications, readNotification, readNotifications } from 'store/notification';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { selectNotifications } from 'store/selectors';

export const Notifications = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const notifications = useSelector(selectNotifications);
    const POLLING_INTERVAL = 4000;

    useEffect(() => {
        const intervalId = setInterval(() => {
            dispatch(fetchNotifications());
        }, POLLING_INTERVAL);

        return () => clearInterval(intervalId);
    }, [dispatch]);

    const handleNavigation = (notification: INotification) => {
        if (notification?.notification_type === 'permit') {
            navigate(`/permit-details/${notification?.permit_id}`);
            dispatch(readNotification(notification?.id));
        } else {
            navigate(`/project-info/${notification?.project_id}`);
            dispatch(readNotification(notification?.id));
        }
    };

    return (
        <Dropdown
            className={notifications.length > 0 ? 'notification-custom custom-dropdown show-alert' : 'notification-custom custom-dropdown'}
        >
            <Dropdown.Toggle id='dropdown-basic'>
                <i className='fi fi-rr-bell' />
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <div className='notification-card-list'>
                    <div className='notification-header'>
                        <h4 className='text-cool-grey-800 text-semibold-18'>Notifications</h4>
                    </div>
                    <div className='notification-body notification-scroll'>
                        {notifications.length > 0 ? (
                            <>
                                {notifications.map((notification, index: number) => (
                                    <React.Fragment key={index}>
                                        <div className='notification-body-inner d-flex flex-wrap unread-meassage'>
                                            <div className='icon-left'>
                                                <img src='/assets/images/alert-circle.svg' alt='alert-icon' />
                                            </div>
                                            <div
                                                className='center-message pl8 pr8 cursor-pointer'
                                                onClick={() => handleNavigation(notification)}
                                            >
                                                <div className='message-header'>
                                                    <strong>{notification?.project_name} </strong>
                                                    <span>- High severity</span>
                                                </div>
                                                <div className='message-detail'>{parseHtml(notification.message || '')}</div>
                                            </div>
                                            <div className='right-icon text-end'>
                                                <IconButton onClick={() => dispatch(readNotification(notification?.id))} size='sm'>
                                                    {' '}
                                                    <i className='fi fi-rr-cross-small' />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ))}
                            </>
                        ) : (
                            <div className='notification-empty text-center'>
                                <div className='img-box-notification pb12'>
                                    <img src='/assets/images/notification-empty.svg' alt='notification-icon' />
                                </div>
                                <h4 className='text-cool-grey-800 text-bold-20'>No New Notifications</h4>
                            </div>
                        )}
                    </div>
                    {notifications.length > 0 ? (
                        <div className='notification-footer text-center'>
                            <IconLinkButton className=' mb10' onClick={() => dispatch(readNotifications())}>
                                Mark All as Read
                                <span className='icon-sm pl4'>
                                    <i className='fi fi-rr-check' />
                                </span>
                            </IconLinkButton>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};
