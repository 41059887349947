import React from 'react';

import { DashboardLayout } from 'layouts';

export const Incentives = () => (
    <DashboardLayout headerClassName='d-none'>
        <div className='project-incentive-blank d-flex flex-column justify-content-center align-items-center min-h100vh'>
            <div className='proj-incentive-info text-center'>
                <div className='img-box pb8'>
                    <img src='/assets/images/available-version.svg' alt='icon' />
                </div>
                <div className='label-tex text-bold-24 text-cool-grey-800'>Coming Soon!</div>
            </div>
        </div>
    </DashboardLayout>
);
