/* eslint-disable @typescript-eslint/no-explicit-any */
export const customStyles = {
    borderRadius: '16px',
    border: 'none',
    option: (provided: any, state: any) => ({
        ...provided,
        color: state.isSelected ? '#1F2937' : '',
        backgroundColor: state.isSelected || state.isFocused ? '#EEF2FF' : '#fff',
    }),
    control: (provided: any, state: any) => {
        return {
            ...provided,
            boxShadow: state?.isFocused ? '0px 0px 0px 1px #4f46e5' : 'none',
        };
    },
};
