import React from 'react';

import { Modal, ModalBody, ModalFooter, Button } from 'components';

interface ChangeEmailSentModalProps {
    show: boolean;
    modalTitle?: string;
    imageUrl?: string;
    title?: string;
    confirmButtonLabel?: string;
    onClose?: () => void;
    onConfirm?: () => void;
}

export const ChangeEmailSentModal = ({
    show,
    onClose,
    modalTitle,
    imageUrl,
    title,
    onConfirm,
    confirmButtonLabel,
}: ChangeEmailSentModalProps) => (
    <Modal show={show} onClose={onClose} title={modalTitle} className='modal-wrapper modal-header-none' size='sm'>
        <ModalBody>
            <div className='verify-email-box max-w440 m-auto'>
                <div className='header-icon-box pb16 text-center'>
                    <img src={imageUrl} alt='email-icon' />
                </div>
                <div className='text-bold-28 pb12 text-cool-grey-800 text-center w-100'>{title}</div>
            </div>
        </ModalBody>
        <ModalFooter className='flex-nowrap gap-2 max-w440 m-auto'>
            <Button variant='primary' size='sm' btnFullClassName='w-50 m-auto' onClick={onConfirm}>
                {confirmButtonLabel}
            </Button>
        </ModalFooter>
    </Modal>
);
