import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';

import { IToken } from 'models';

import { baseURL } from './constants';

const httpClient = axios.create({
    baseURL,
});

httpClient.interceptors.request.use((config) => {
    let token = localStorage.getItem('token');

    if (!token) {
        token = localStorage.getItem('token');
    }

    if (!token) {
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = '/';
    }

    if (token && isTokenExpired(token)) {
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = '/';
    }
    config.headers.Authorization = token ? `Bearer ${token}` : '';

    return config;
});

httpClient.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const { response } = error;

        if (response) {
            if (response.status === 401) {
                // Handling 401 Unauthorized
                sessionStorage.clear();
                localStorage.clear();
                toast('Invalid token or insufficient permissions');
                window.location.href = '/';
            } else if (response.status === 403) {
                toast('Invalid token or insufficient permissions');
            }
        }

        return Promise.reject(error);
    },
);

const isTokenExpired = (token: string) => {
    const decoded: IToken = jwtDecode(token);

    return decoded.exp * 1000 < Date.now();
};

export default httpClient;
