import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { Button, IconButton, Input, Modal, ModalBody, ModalFooter, StrongPasswordList } from 'components';
import { ResetPasswordBody } from '../../Profile.models';

interface CreateNewPasswordModalProps {
    show: boolean;
    onClose?: () => void;
    onResetPassword: (values: ResetPasswordBody) => void;
}

export const CreateNewPasswordModal = ({ show, onClose, onResetPassword }: CreateNewPasswordModalProps) => {
    const initialValues: ResetPasswordBody = {
        new_password: '',
        confirm_new_password: '',
    };

    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const validationSchema = Yup.object().shape({
        new_password: Yup.string()
            .required('No password provided.')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
                'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
            )
            .min(8, 'Password is too short - should be 8 chars minimum.')
            .max(30, 'Password is too long - should be 30 chars maximum.'),
        confirm_new_password: Yup.string()
            .required('Confirm password required.')
            .oneOf([Yup.ref('new_password')], 'Passwords does not match'),
    });

    return (
        <Modal show={show} onClose={onClose} className='modal-wrapper modal-header-none' size='sm'>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(values) => onResetPassword(values)}
                validationSchema={validationSchema}
            >
                {({ values, errors, handleChange }) => (
                    <Form>
                        <ModalBody>
                            <div className='verify-email-box max-w440 m-auto'>
                                <div className='text-bold-28 pb16 text-cool-grey-800 text-center w-100'>New Password</div>
                                <div className='inner-form-box-detail change-pass-strong'>
                                    <div className='eye-icon-filled'>
                                        <Input
                                            labelName='New password'
                                            placeholder='Create a new password'
                                            type={showNewPassword ? 'text' : 'password'}
                                            size='md'
                                            value={values.new_password}
                                            onChange={handleChange('new_password')}
                                            isInvalid={!!errors.new_password}
                                            errorMsg={errors?.new_password}
                                        />
                                        <IconButton className='password-icon' onClick={() => setShowNewPassword(!showNewPassword)}>
                                            <i className={!showNewPassword ? 'fi fi-rr-eye-crossed' : 'fi fi-rr-eye'} />
                                        </IconButton>
                                    </div>
                                    <StrongPasswordList password={values.new_password} errors={errors} />
                                    <div className='eye-icon-filled'>
                                        <Input
                                            labelName='Confirm password'
                                            placeholder='Enter your new password'
                                            type={showPassword ? 'text' : 'password'}
                                            size='md'
                                            value={values.confirm_new_password}
                                            onChange={handleChange('confirm_new_password')}
                                            isInvalid={!!errors.confirm_new_password}
                                            errorMsg={errors?.confirm_new_password}
                                        />
                                        <IconButton className='password-icon' onClick={() => setShowPassword(!showPassword)}>
                                            <i className={!showPassword ? 'fi fi-rr-eye-crossed' : 'fi fi-rr-eye'} />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter className='flex-nowrap gap-2 max-w440 m-auto'>
                            <Button onClick={onClose} variant='outline-primary' btnFullClassName='w-100' size='sm'>
                                Cancel
                            </Button>
                            <Button type='submit' variant='primary' size='sm' btnFullClassName='w-100'>
                                Save Changes
                            </Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};
