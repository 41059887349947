import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { deleteMethod } from 'services/api.service';
import { PROJECTS } from 'utils/constants';
import { Button, CardStructure, TextChip, IconButton, DeleteAccountModal } from 'components';
import { IProjectDetail } from 'models';
import { ProjectDetailsAccordion } from '../ProjectDetailsAccordion/ProjectDetailsAccordion';

interface ProjectDetailsTabProps {
    projectDetails?: IProjectDetail;
}

export const ProjectDetailsTab = ({ projectDetails }: ProjectDetailsTabProps) => {
    const { projectId } = useParams();
    const [deleteProject, setDeleteProject] = useState(false);
    const navigate = useNavigate();

    const handleDeleteProject = async () => {
        const url = `${PROJECTS.PROJECT}?id=${projectId}`;
        try {
            const { data } = await deleteMethod(url);

            if (data?.status) {
                toast(data?.message);
                setDeleteProject(false);
                navigate('/projects');
            }
        } catch (error) {
            if (error instanceof AxiosError && error?.response?.data?.status === false) {
                toast(error?.response?.data?.message);
            }
        }
    };

    return (
        <>
            <div className='project-info-tab-card'>
                <CardStructure className='project-info-tab-card-box'>
                    <div className='project-tab-card-inner'>
                        <div className='project-info-header d-flex flex-wrap gap-2'>
                            <div className='project-info-left'>
                                <h4 className='text-cool-grey-800 text-bold-18'>{projectDetails?.name}</h4>
                            </div>
                            <div className='project-info-right'>
                                <TextChip bgColor='grey' className='badge-md'>
                                    {projectDetails?.overall_status}
                                </TextChip>
                            </div>
                        </div>
                        <hr className='h-border-top' />
                        <div className='project-info-body'>
                            <ProjectDetailsAccordion projectDetails={projectDetails} />
                        </div>
                        <div className='project-info-footer'>
                            <div className='project-info-footer-inner d-flex flex-wrap align-items-center gap-3 justify-content-end'>
                                <IconButton size='sm' onClick={() => setDeleteProject(true)}>
                                    <i className='fi fi-rr-trash' />
                                </IconButton>
                                {projectDetails?.overall_status === 'Draft' ? (
                                    <div className='project-cont-btn max-w240'>
                                        <Button
                                            variant='primary'
                                            size='sm'
                                            className='w-100'
                                            disabled={projectDetails?.is_demo ? 'disabled' : ''}
                                            onClick={() => navigate(`/edit-project/${projectDetails?.id}`)}
                                        >
                                            Continue Project Creation
                                        </Button>
                                    </div>
                                ) : (
                                    <div className='project-cont-btn max-w160'>
                                        <Button
                                            variant='primary'
                                            size='sm'
                                            className='w-100'
                                            onClick={() => navigate(`/edit-project/${projectDetails?.id}`)}
                                        >
                                            Edit Project
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </CardStructure>
            </div>

            <DeleteAccountModal
                show={deleteProject}
                onClose={() => setDeleteProject(false)}
                imageUrl='/assets/images/questionmark-icon.svg'
                title='Delete this Project?'
                subtitle='This action can not be undone'
                leftButtonLabel='Cancel'
                onClickLeftButton={() => setDeleteProject(false)}
                onClickRightButton={() => handleDeleteProject()}
                rightButtonLabel='Delete'
            />
        </>
    );
};
