import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IPermitDetails } from 'models';
import { ICreatePermit } from 'pages/NewPermit/NewPermit.models';

export interface IPermitState {
    permit: (IPermitDetails & ICreatePermit) | null;
}

const initialState: IPermitState = {
    permit: null,
};

export const permitSlice = createSlice({
    name: 'permit',
    initialState,
    reducers: {
        setPermit: (state, action: PayloadAction<IPermitState['permit']>) => {
            return { ...state, permit: action.payload };
        },
    },
});

export const { setPermit } = permitSlice.actions;
export const { reducer } = permitSlice;
