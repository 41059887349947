import React, { useCallback, useEffect, useState } from 'react';
import BootstrapPagination from 'react-bootstrap/Pagination';

interface PaginationProps {
    className?: string;
    totalItems: number;
    totalPages: number;
    onPageChange: (data: number) => void;
}

export const Pagination = ({ className, totalItems, totalPages, onPageChange }: PaginationProps) => {
    const [currentPage, setCurrentPage] = useState(1);
    const loadPreviousPage = () => {
        setCurrentPage((prev: number) => (prev === 1 ? prev : prev - 1));
    };

    const loadNextPage = () => {
        setCurrentPage((prev: number) => (prev === totalItems ? prev : prev + 1));
    };

    const generateArray = (a: number): number[] => {
        const arr: number[] = [];
        for (let i = 1; i <= a; i++) {
            arr.push(i);
        }

        return arr;
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const updateProjectListing = useCallback(() => {
        onPageChange && onPageChange(currentPage);
    }, [currentPage, onPageChange]);

    useEffect(() => {
        updateProjectListing();
    }, [currentPage, updateProjectListing]);

    return (
        <BootstrapPagination className={`custom-pagination ${className}`}>
            <BootstrapPagination.Prev className='prev-btn' onClick={loadPreviousPage}>
                <i className='fi fi-rr-angle-small-left' />
            </BootstrapPagination.Prev>
            {generateArray(totalPages).map((_, index) => (
                <BootstrapPagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => handlePageChange(index + 1)}>
                    {index + 1}
                </BootstrapPagination.Item>
            ))}
            <BootstrapPagination.Next className='next-btn' onClick={loadNextPage}>
                <i className='fi fi-rr-angle-small-right' />
            </BootstrapPagination.Next>
        </BootstrapPagination>
    );
};
