import React, { MouseEvent } from 'react';

import { IconButton } from 'components';

interface ProjectCardHeaderProps {
    projectName?: string;
    scope?: string;
    onClickDelete?: (e: MouseEvent<HTMLButtonElement>) => void;
    onClickEdit?: (e: MouseEvent<HTMLButtonElement>) => void;
    isDemo?: boolean;
}

export const ProjectCardHeader = ({ projectName, scope, onClickDelete, onClickEdit, isDemo }: ProjectCardHeaderProps) => (
    <div className='project-card-header'>
        <div className='cstm-row'>
            <div className='box1'>
                <div className='content-card-project'>
                    <h4 className='label-name text-cool-grey-400 text-regular-12'>Customer</h4>
                    <div className='detail-card-pro text-cool-grey-800 text-regular-14'>Demo Customer</div>
                </div>
            </div>
            <div className='box1'>
                <div className='content-card-project'>
                    <h4 className='label-name text-cool-grey-400 text-regular-12'>Project Name</h4>
                    <div className='detail-card-pro text-cool-grey-800 text-regular-14'>{projectName}</div>
                </div>
            </div>
            <div className='box1'>
                <div className='content-card-project'>
                    <h4 className='label-name text-cool-grey-400 text-regular-12'>Scope</h4>
                    <div className='detail-card-pro text-cool-grey-800 text-regular-14'>{scope}</div>
                </div>
            </div>
            {!isDemo ? (
                <div className='box2'>
                    <div className='project-action-btn text-end'>
                        <IconButton size='sm' onClick={onClickDelete}>
                            <i className='fi fi-rr-trash' />
                        </IconButton>
                        <IconButton size='sm' onClick={onClickEdit}>
                            <i className='fi fi-rr-edit' />
                        </IconButton>
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    </div>
);
