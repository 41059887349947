import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { InfoModal, CardStructure } from 'components';
import { ILocation } from 'models';

interface PropertyIdMapCardProps {
    locationData: ILocation;
}

export const PropertyIdMapCard = ({ locationData }: PropertyIdMapCardProps) => {
    const [infoModal, setInfoModal] = useState(false);

    useEffect(() => {
        const isLocationEmpty = Object.values(locationData).every((value) => value !== '');

        if (isLocationEmpty === true) {
            const newLocationData = { ...locationData, isBack: true };
            localStorage.setItem('locationValue', JSON.stringify(newLocationData));
        }
    }, [locationData]);

    return (
        <>
            <div className='property-id-card'>
                <CardStructure className='property-map-structure'>
                    <div className='property-id-outer'>
                        <div className='property-id-title pb8 d-none'>
                            Property ID: <span className='id-name'>3985304</span>
                        </div>
                        <Row>
                            <Col xs={12} sm={6}>
                                <div className='project-popup-detail'>
                                    <div className='label-name text-regular-12 text-cool-grey-500'>Zip Code</div>
                                    <div className='project-popup-content text-regular-14 text-cool-grey-800'>
                                        {locationData?.zip_code !== '' ? locationData?.zip_code : 'NA'}
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={6}>
                                <div className='project-popup-detail'>
                                    <div className='label-name text-regular-12 text-cool-grey-500'>State</div>
                                    {locationData.country !== '' ? (
                                        <div className='project-popup-content text-regular-14 text-cool-grey-800'>
                                            {locationData?.state}, {locationData?.country}
                                        </div>
                                    ) : (
                                        <div className='project-popup-content text-regular-14 text-cool-grey-800'>NA</div>
                                    )}
                                </div>
                            </Col>
                            <Col xs={12} sm={6}>
                                <div className='project-popup-detail'>
                                    <div className='label-name text-regular-12 text-cool-grey-500'>City / Municipality</div>
                                    <div className='project-popup-content text-regular-14 text-cool-grey-800'>
                                        {locationData?.city !== '' ? locationData?.city : 'NA'}
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={6}>
                                <div className='project-popup-detail'>
                                    <div className='label-name text-regular-12 text-cool-grey-500'>Address</div>
                                    <div className='project-popup-content text-regular-14 text-cool-grey-800'>
                                        {locationData?.street !== '' ? locationData?.street : 'NA'}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </CardStructure>
            </div>

            <InfoModal
                show={infoModal}
                onClose={() => setInfoModal(false)}
                modalTitle='Zip Code'
                title='A ZIP Code is a postal code used by the United States Postal Service (USPS) and is an acronym for Zone Improvement Plan. The first five digits indicate the destination post office or delivery area. The last 4 digits represent specific delivery routes within delivery areas.'
                confirmButtonLabel='Got It'
                confirmButtonClassName='min-w190'
                onConfirm={() => setInfoModal(false)}
            />
        </>
    );
};
