import React from 'react';
import { Link } from 'react-router-dom';

export const Mobile = () => (
    <div className='mobile-version-main'>
        <div className='mobile-version-inner'>
            <div className='mobile-logo'>
                <Link to='/'>
                    <img src='/assets/images/1CLIMATE.svg' alt='home_logo' />
                </Link>
            </div>
            <div className='mobile-content-box'>
                <div className='mobile-content-sm'>
                    <img src='/assets/images/no-data.svg' alt='no-data' />
                    <p>Oops! This app available only in web version</p>
                </div>
                <div className='back-btn-home'>
                    <Link to='https://1climate.app' className='cstm-btn w-100  btn btn-primary btn-lg'>
                        Go back to Home Page
                    </Link>
                </div>
            </div>
        </div>
    </div>
);
