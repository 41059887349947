import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import Stack from 'react-bootstrap/Stack';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { AxiosError } from 'axios';

import { Button, Card, HeaderLogo, IconButton, Input, StrongPasswordList } from 'components';
import { putWithOutAuthMethod } from 'services/api.service';
import { AUTH } from 'utils/constants';

import { ChangePasswordInput } from './ChangePassword.models';

export const ChangePassword = () => {
    const navigate = useNavigate();
    const { id, token } = useParams();
    const initialChangePasswordData = {
        token: `${id}/${token}`,
        new_password: '',
        confirm_new_password: '',
    };

    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    const validationSchema = Yup.object().shape({
        token: Yup.string().required('No token provided.'),
        new_password: Yup.string()
            .required('No password provided.')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
                'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
            )
            .min(8, 'Password is too short - should be 8 chars minimum.')
            .max(30, 'Password is too long - should be 30 chars maximum.'),
        confirm_new_password: Yup.string()
            .required('Confirm password required.')
            .oneOf([Yup.ref('new_password')], 'Passwords does not match'),
    });

    const handleResetPassword = async (values: ChangePasswordInput) => {
        setIsLoading(true);
        try {
            const { data } = await putWithOutAuthMethod(AUTH.RESET_PASSWORD, values);

            if (data?.status === true) {
                toast(data?.message);
                navigate('/');
            }
        } catch (error) {
            if (error instanceof AxiosError && error?.response?.data?.status === false) {
                toast(error?.response?.data?.message);
            } else {
                toast('Something went wrong');
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='form-wrapper position-relative overflow-hidden'>
            <div className='bottom-shape bg-gradient-primary ' />
            <div className='from-inner-box'>
                <Stack gap={3} className='min-h100vh p20'>
                    <div className='form-header pb15'>
                        <HeaderLogo />
                    </div>
                    <div className='m-auto inner-form-box'>
                        <Card>
                            <Formik
                                enableReinitialize
                                initialValues={initialChangePasswordData}
                                onSubmit={(values) => handleResetPassword(values)}
                                validationSchema={validationSchema}
                            >
                                {({ values, errors, isValid, handleChange, handleSubmit }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <h3 className='text-bold-28 text-center pb16'>Change Password</h3>
                                        <div className='inner-form-box-detail change-pass-strong'>
                                            <div className='eye-icon-filled'>
                                                <Input
                                                    labelName='New password'
                                                    placeholder='Create a new password'
                                                    type={showNewPassword ? 'text' : 'password'}
                                                    size='md'
                                                    value={values.new_password}
                                                    onChange={handleChange('new_password')}
                                                    isInvalid={!!errors.new_password}
                                                    errorMsg={errors?.new_password}
                                                />
                                                <IconButton className='password-icon' onClick={() => setShowNewPassword(!showNewPassword)}>
                                                    <i className={!showNewPassword ? 'fi fi-rr-eye-crossed' : 'fi fi-rr-eye'} />
                                                </IconButton>
                                            </div>
                                            <StrongPasswordList password={values.new_password} errors={errors} />
                                            <div className='eye-icon-filled'>
                                                <Input
                                                    labelName='Confirm password'
                                                    placeholder='Enter your new password'
                                                    type={showConfirmPassword ? 'text' : 'password'}
                                                    size='md'
                                                    value={values.confirm_new_password}
                                                    onChange={handleChange('confirm_new_password')}
                                                    isInvalid={!!errors.confirm_new_password}
                                                    errorMsg={errors?.confirm_new_password}
                                                />
                                                <IconButton
                                                    className='password-icon'
                                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                >
                                                    <i className={!showConfirmPassword ? 'fi fi-rr-eye-crossed' : 'fi fi-rr-eye'} />
                                                </IconButton>
                                            </div>

                                            <div className='btn-list pt15'>
                                                <Button
                                                    type='submit'
                                                    variant='primary'
                                                    btnFullClassName='w-100'
                                                    size='lg'
                                                    className='mb8'
                                                    disabled={isValid ? '' : 'disabled'}
                                                    spinner={isLoading}
                                                >
                                                    Save changes
                                                </Button>
                                                <Button
                                                    variant='outline-primary'
                                                    btnFullClassName='w-100'
                                                    size='lg'
                                                    className='mb8'
                                                    onClick={() => navigate('/')}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </Card>
                    </div>
                </Stack>
            </div>
        </div>
    );
};
