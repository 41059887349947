import { IProjectDetail } from 'models';

export enum PropertyOwnershipType {
    FOR_PROFIT = 'For-Profit',
    NON_PROFIT = 'Non-Profit',
    HYBRID = 'Hybrid',
    PUBLIC_HOUSING_AUTHORITY = 'Public Housing Authority',
    TRIBAL_ENTITY = 'Tribal Entity',
}

export enum ElectricUtilityType {
    PG_AND_E = 'Pacific Gas and Electric (PG&E)',
    SCE = 'Southern California Edison (SCE)',
    SDG_AND_E = 'San Diego Gas & Electric (SDG&E)',
}

export enum GasUtilityType {
    PG_AND_E = 'Pacific Gas and Electric (PG&E)',
    SCE = 'Southern California Edison (SCE)',
    SDG_AND_E = 'San Diego Gas & Electric (SDG&E)',
}

export enum ResponsibleForPayingElectricServicesType {
    TENANTS = 'Tenants',
    PROPERTY_OWNER = 'Property Owner',
    OTHER = 'Other',
}

// Define enum for permit status
export enum PermitStatus {
    NOT_STARTED = 'Not Started',
    PENDING = 'Pending',
    IN_REVIEW = 'In Review',
    APPROVED = 'Approved',
}

export interface ICreatePermit extends IPermitMeta {
    id?: number;
    name: string;
    project_id: number;
    status?: PermitStatus;
    host_customer: {
        date: string;
        name: string;
        company: string;
        address: string;
        email: string;
        phone: string;
    };
    project_site: {
        name: string;
        total_units: number;
        address: string;
        ownership_type: keyof typeof PropertyOwnershipType;
    };
    property_eligibility: {
        is_within_median_income_area?: boolean;
        is_disadvantaged_community?: boolean;
        is_owned_by_a_tribe?: boolean;
        is_rental_housing?: boolean;
        census_tract_code?: string;
        has_certificate_of_occupancy?: boolean;
        has_solar_construction_started?: boolean;
        was_referral_to_SOMAH_program?: boolean;
    };
    electrical_utility: {
        utility: keyof typeof ElectricUtilityType;
        does_receive_electric_service_from_CCA: boolean;
        responsible_for_paying_electric_services: keyof typeof ResponsibleForPayingElectricServicesType;
        meters_of_common_areas: number;
    };
    gas_utility: {
        utility: keyof typeof GasUtilityType;
    };
}

export interface ICreatePermitBackendPayload extends IPermitMeta {
    id?: number; // Optional, if the backend might return an ID for the created permit
    name: string;
    project_id: number;
    host_customer_date: string; // Date as a string in ISO format
    host_customer_name: string;
    host_customer_company: string;
    host_customer_address: string;
    host_customer_email: string;
    host_customer_phone: string;
    project_site_name: string;
    project_site_total_units: number;
    project_site_address: string;
    project_site_ownership: keyof typeof PropertyOwnershipType;
    property_eligibility: {
        is_within_median_income_area?: boolean;
        is_disadvantaged_community?: boolean;
        is_owned_by_a_tribe?: boolean;
        is_rental_housing?: boolean;
        has_certificate_of_occupancy?: boolean;
        has_solar_construction_started?: boolean;
        was_referral_to_SOMAH_program?: boolean;
        census_tract_code?: string;
    };
    electrical_utility_name: keyof typeof ElectricUtilityType;
    electrical_utility_is_CCA: boolean;
    electrical_utility_payment_responsible: keyof typeof ResponsibleForPayingElectricServicesType;
    electrical_utility_meters_of_common_areas: number;
    gas_utility_name: keyof typeof GasUtilityType;
    permit_status: PermitStatus;
    is_active?: boolean;
    created_at?: string; // DateTime as a string in ISO format
    updated_at?: string; // DateTime as a string in ISO format
}

export interface IPermitMeta {
    project_data?: IProjectDetail;
}
