import React, { ChangeEvent } from 'react';
import Form from 'react-bootstrap/Form';

interface UploadInputProps {
    id: string;
    name: string;
    labelName: string;
    htmlFor: string;
    className?: string;
    size?: string;
    onUploadFileChange: (event: ChangeEvent<HTMLInputElement>, type: string) => void;
}

export const UploadInput = ({ className, labelName, size, onUploadFileChange, name, htmlFor, id }: UploadInputProps) => (
    <Form.Group className={`custom_upload mb7 ${className}`}>
        <Form.Label className='text-regular-15 text-cool-grey-800 upload-file-main' htmlFor={htmlFor}>
            <span className='icon-upload-box'>
                <i className='fi fi-rr-upload' />
            </span>
            <span>{labelName}</span>
        </Form.Label>
        <Form.Control
            id={id}
            type='file'
            name={name}
            className={size}
            onChange={(event: ChangeEvent<HTMLInputElement>) => onUploadFileChange(event, name)}
            accept='.pdf'
        />
    </Form.Group>
);
