import React, { useState } from 'react';

import { ConfirmModal } from 'components';

export const IncentiveClaimsTable = () => {
    const [deletePermits, setDeletePermits] = useState(false);

    return (
        <>
            <div className='no-claims-table'>
                <div className='no-claims-table-inner'>
                    <span className='icon-box pb12'>
                        <img src='assets/images/search-glass.svg' alt='question-mark' />
                    </span>
                    <h2 className='text-regular-16 text-cool-grey-500'>No Pending Incentive Claims</h2>
                </div>
            </div>

            <ConfirmModal
                show={deletePermits}
                onClose={() => setDeletePermits(false)}
                imageUrl='/assets/images/questionmark-icon.svg'
                title='Delete the 111 Main St - Heat Pump Install incentive?'
                leftButtonLabel='Cancel'
                onClickLeftButton={() => setDeletePermits(false)}
                rightButtonLabel='Confirm'
            />
        </>
    );
};
