export const stepsData = [
    {
        title: 'Zoning Bylaws & Classifications',
        descriptions: [
            'Zoning classifications detail specific areas where solar development is allowed, requiring review of local zoning bylaws.',
            'Special Permit required for facilities larger than 700 kilowatts or occupying more than five acres.',
            'Land use and zoning permits are necessary to comply with Section 8.5.3 for all commercial ground-mounted solar power generation installations.',
        ],
    },
    {
        title: 'Site Plan Review & Special Permit Process',
        descriptions: [
            'Commercial solar installations of 250 KW or larger require site plan review by the Planning Board.',
            'A Special Permit is required for developments in specified zones, involving a public hearing and adherence to the Southbridge Master Plan.',
        ],
    },
    {
        title: 'Development Restrictions',
        descriptions: [
            'Front/side/rear yard setbacks: 75 ft for large-scale ground-mounted solar PV.',
            'Maximum tree clearing limited to necessary construction, operation, and maintenance of the installation.',
            'Maximum acreage of array area/limit of disturbance outlined in Section 8.5.2.',
            'Grading limitations prefer no greater than a 3:1 slope, preserving natural land contours.',
        ],
    },
    {
        title: 'Permitting Steps & Requirements',
        descriptions: [
            'Process for securing Special Permits involves application to the Town Clerk and subsequent filing with the special permit granting authority.',
            'Adherence to AHJ-specific wetlands bylaws and other environmental protections during development.',
            'Utility notification is required before construction of commercial ground-mounted solar photovoltaic installations.',
            "Discretionary permitting steps include public hearings, notification requirements, and potential for hiring professional consultants at applicant's expense.",
        ],
    },
    {
        title: 'Other Considerations',
        descriptions: ['No data indicates a moratorium on large-scale solar developments.'],
    },
];
