import React, { ChangeEventHandler, ReactNode } from 'react';
import Form from 'react-bootstrap/Form';

import { IconButton } from 'components/IconButton/IconButton';

export interface InputProps {
    name?: string;
    value?: string | number | string[];
    className?: string;
    labelName?: string;
    size?: string;
    type?: string;
    placeholder?: string;
    isIcon?: boolean;
    iconBtnClassName?: string;
    btnChild?: ReactNode;
    isInvalid?: boolean;
    maxLength?: number;
    errorMsg?: string | string[];
    disabled?: boolean;
    onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onClickIcon?: () => void;
}

export const Input = ({
    className,
    value,
    isInvalid,
    maxLength,
    labelName,
    isIcon,
    size,
    type,
    placeholder,
    iconBtnClassName,
    btnChild,
    errorMsg,
    name,
    disabled,
    onChange,
    onClickIcon,
}: InputProps) => (
    <Form.Group className={`custom_form mb7 ${className} ${disabled ? 'disabled' : ''}`}>
        <div className='form-label-outer d-flex align-items-center flex-wrap justify-content-between gap-2'>
            <Form.Label className='text-regular-15 text-cool-grey-800 flex-grow-1'>
                {labelName}
                {isIcon ? (
                    <IconButton onClick={onClickIcon} size='sm' className={iconBtnClassName}>
                        {btnChild}
                    </IconButton>
                ) : (
                    <></>
                )}
            </Form.Label>
            <Form.Control.Feedback type='invalid' className={`text-regular-12 text-red-400 mb2 ${isInvalid ? 'error-msg' : ''}`}>
                {errorMsg}
            </Form.Control.Feedback>
        </div>
        <Form.Control
            name={name}
            className={`${size}`}
            type={type || 'text'}
            placeholder={placeholder || ''}
            value={value}
            onChange={onChange}
            disabled={disabled}
            isInvalid={isInvalid}
            maxLength={maxLength}
        />
    </Form.Group>
);
