import React, { useCallback, useRef, useState } from 'react';
import { Col, ModalHeader, Overlay, Popover, Row, Table } from 'react-bootstrap';
import parseHtml from 'html-react-parser';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import { pdfjs } from 'react-pdf';

import { ModalBody, Modal, Loader, IconButton } from 'components';
import { IRegulation, IRegulationCup, IRegulationElement } from 'pages/ExplorerView/models';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';

interface RegulationRuleCardModalProps {
    regulation: IRegulation;
    onClose: () => void;
}

export const RegulationRuleCardModal = ({ regulation, onClose }: RegulationRuleCardModalProps) => {
    const [popoverTarget, setPopoverTarget] = useState<HTMLElement>();
    const [selectedElement, setSelectedElement] = useState<IRegulationElement>();

    // const elementsTempData: IRegulationElement[] = useMemo(
    //     () => [
    //         {
    //             title: 'Floodplain District Development Requirements',
    //             summary:
    //                 'All development in the Floodplain District, including structural and non-structural activities, whether permitted by right or by special permit, must comply Massachusetts environmental and building codes.',
    //             full_text:
    //                 'All development in the Floodplain District, including structural and non-structural activities, whether permitted by right or by special permit, must comply with G.L. c. 131, § 40 and the following: 1. 780 CMR (Massachusetts State Building Code); 2. 310 CMR (Commonwealth of Massachusetts Regulations), Department of Environmental Protection, Wetlands Protection Regulations (currently Section 10.00); 3. Minimum Requirements for the Subsurface Disposal of Sanitary Sewage, DEP (currently 310 CMR 15, Title 5).',
    //             full_text_pdf: Math.random() > 0.5 ? 'https://www.princexml.com/samples/invoice/invoicesample.pdf' : undefined,
    //             html: '<iframe src="https://next.axisgis.com/SouthbridgeMA/" width="650" height="450" style="border:0;"></iframe>',
    //         },
    //     ],
    //     [],
    // );

    const elementRef = useRef(null);

    // const handleOpenElementDetailsPopover = useCallback((element: IRegulationElement, e: React.MouseEvent<HTMLButtonElement>) => {
    //     setSelectedElement(element);
    //     setPopoverTarget(e.target as HTMLElement);
    // }, []);

    const handleCloseElementDetailsPopover = useCallback(() => {
        setSelectedElement(undefined);
        setPopoverTarget(undefined);
    }, []);

    const renderFullTextPdfLoader = () => {
        return <Loader />;
    };

    const thumbnailPluginInstance = thumbnailPlugin({
        renderSpinner: () => <Loader />,
    });

    const buildRegulationModalTitle = () => {
        return parseHtml(regulation.names);
    };

    return (
        <>
            <Modal show size='lg' onClose={onClose} className='modal-wrapper modal-project'>
                <ModalHeader className='pb-4'>
                    <div className='verify-email-box text-center w-100'>
                        <div className='text-bold-18 text-cool-grey-800  w-100 text-center headline'>{buildRegulationModalTitle()}</div>
                    </div>
                    <IconButton size='sm' className='close-icon' onClick={onClose}>
                        <i className='fi fi-rr-cross-small' />
                    </IconButton>
                </ModalHeader>
                <ModalBody>
                    <div className='project-popup-card-outer-box'>
                        <div className='project-popup-card-outer'>
                            <div className='project-popup-main'>
                                <div className='project-popup-inner'>
                                    <div className='project-popup-label d-flex flex-wrap align-items-center justify-content-between gap-2 pb9'>
                                        <h4 className='text-semibold-16 text-cool-grey-800 headline'>{parseHtml(regulation.names)}</h4>
                                        {regulation.reference_link && (
                                            <div className='text-right text-indigo-600'>
                                                <a href={regulation.reference_link} target='_blank' rel='noreferrer'>
                                                    Reference link <i className='fi fi-rr-link-alt'></i>
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                    <div className='project-popup-body'>
                                        <Row>
                                            {regulation?.category && (
                                                <Col xs='12' md='6' lg='4'>
                                                    <div className='project-popup-detail'>
                                                        <div className='label-name text-regular-14 text-cool-grey-500'>Category</div>
                                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                            {regulation?.category}
                                                        </div>
                                                    </div>
                                                </Col>
                                            )}
                                            {regulation.technology && (
                                                <Col xs='12' md='6' lg='4'>
                                                    <div className='project-popup-detail'>
                                                        <div className='label-name text-regular-14 text-cool-grey-500'>Technology</div>
                                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                            {regulation?.technology?.replace(/,\s*/g, ', ')}
                                                        </div>
                                                    </div>
                                                </Col>
                                            )}
                                            {regulation.level && (
                                                <Col xs='12' md='6' lg='4'>
                                                    <div className='project-popup-detail'>
                                                        <div className='label-name text-regular-14 text-cool-grey-500'>Level</div>
                                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                            {regulation?.level}
                                                        </div>
                                                    </div>
                                                </Col>
                                            )}
                                            {regulation?.geography && (
                                                <Col xs='12' md='6' lg='4'>
                                                    <div className='project-popup-detail'>
                                                        <div className='label-name text-regular-14 text-cool-grey-500'>Geography</div>
                                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                            {regulation?.geography}
                                                        </div>
                                                    </div>
                                                </Col>
                                            )}
                                            {regulation?.policy_type && (
                                                <Col xs='12' md='6' lg='4'>
                                                    <div className='project-popup-detail'>
                                                        <div className='label-name text-regular-14 text-cool-grey-500'>Policy Type</div>
                                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                            {regulation?.policy_type}
                                                        </div>
                                                    </div>
                                                </Col>
                                            )}
                                            {regulation?.segment && (
                                                <Col xs='12' md='6' lg='4'>
                                                    <div className='project-popup-detail'>
                                                        <div className='label-name text-regular-14 text-cool-grey-500'>Segment</div>
                                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                            {regulation?.segment?.replace(/,\s*/g, ', ')}
                                                        </div>
                                                    </div>
                                                </Col>
                                            )}
                                        </Row>
                                    </div>
                                </div>

                                <div className='project-popup-inner'>
                                    {regulation?.description && (
                                        <div className='project-popup-label d-flex flex-wrap align-items-center justify-content-between gap-2 pb9'>
                                            <h4 className='text-semibold-16 text-cool-grey-800 description-heading'>
                                                Description:{' '}
                                                <span className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                    {regulation?.description}
                                                </span>
                                            </h4>
                                        </div>
                                    )}
                                    {regulation?.solar_ordinance?.items && (
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Description</th>
                                                    <th>Size (kW)</th>
                                                    <th>Size (land)</th>
                                                    <th>Approval Requirements</th>
                                                    <th>Permitted Zones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {regulation?.solar_ordinance?.items.map((item) => (
                                                    <tr key={item.id}>
                                                        <td>{item.name}</td>
                                                        <td>{item.description}</td>
                                                        <td>{item.power_size}</td>
                                                        <td>{item.land_size}</td>
                                                        <td>{item.approval_requirements}</td>
                                                        <td>
                                                            {Array.isArray(item.permitted_zones) ? item.permitted_zones.join(', ') : ''}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    )}
                                    <div className='project-popup-body' ref={elementRef}>
                                        {regulation?.base_credit ? (
                                            <Row>
                                                <Col xs='12' md='6'>
                                                    <div className='project-popup-detail'>
                                                        <div className='label-name text-regular-14 text-cool-grey-500'>Base Credit:</div>
                                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                            {regulation?.base_credit ? regulation.base_credit : 'NA'}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs='12' md='6'>
                                                    <div className='project-popup-detail'>
                                                        <div className='label-name text-regular-14 text-cool-grey-500'>
                                                            Wage / apprenticeship adder:
                                                        </div>
                                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                            {regulation?.wage_apprenticeship_adder
                                                                ? regulation.wage_apprenticeship_adder
                                                                : 'NA'}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs='12' md='6'>
                                                    <div className='project-popup-detail'>
                                                        <div className='label-name text-regular-14 text-cool-grey-500'>
                                                            Domestic content adder:
                                                        </div>
                                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                            {regulation?.domestic_content_adder ? regulation.domestic_content_adder : 'NA'}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs='12' md='6'>
                                                    <div className='project-popup-detail'>
                                                        <div className='label-name text-regular-14 text-cool-grey-500'>
                                                            Energy community adder:
                                                        </div>
                                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                            {regulation?.energy_community_adder ? regulation.energy_community_adder : 'NA'}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs='12' md='6' lg='4'>
                                                    <div className='project-popup-detail'>
                                                        <div className='label-name text-regular-14 text-cool-grey-500'>Status:</div>
                                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                            {regulation?.status ? regulation.status : 'NA'}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs='12' md='6' lg='4'>
                                                    <div className='project-popup-detail'>
                                                        <div className='label-name text-regular-14 text-cool-grey-500'>Transferable:</div>
                                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                            {regulation?.transferable ? regulation.transferable : 'NA'}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs='12' md='6' lg='4'>
                                                    <div className='project-popup-detail'>
                                                        <div className='label-name text-regular-14 text-cool-grey-500'>Direct pay:</div>
                                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                            {regulation?.direct_pay ? regulation.direct_pay : 'NA'}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        ) : regulation?.incentive && regulation.incentive.length > 0 ? (
                                            <Row>
                                                <Col xs='12'>
                                                    <div className='project-popup-detail'>
                                                        <span className='label-name text-regular-14 text-cool-grey-500'>Incentive: </span>
                                                        <span className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                            {regulation?.incentive}
                                                        </span>
                                                    </div>
                                                </Col>
                                                {regulation?.incentive_meta ? (
                                                    <Col xs='12'>
                                                        <div className='project-popup-detail'>
                                                            <span className='label-name text-regular-14 text-cool-grey-500'>
                                                                Incentive Meta:{' '}
                                                            </span>
                                                            <span className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                                {regulation?.incentive_meta ? regulation.incentive_meta : 'NA'}
                                                            </span>
                                                        </div>
                                                    </Col>
                                                ) : null}
                                            </Row>
                                        ) : (
                                            <Row>
                                                {regulation?.extra && (
                                                    <>
                                                        {Object.entries(regulation?.extra).map(([key, value]) => (
                                                            <Col key={key}>
                                                                {key === 'html' && typeof value === 'string' ? (
                                                                    <div className='project-popup-detail'>{parseHtml(value)}</div>
                                                                ) : (
                                                                    <div className='project-popup-detail'>
                                                                        <span className='label-name text-regular-14 text-cool-grey-500'>
                                                                            {key}:{' '}
                                                                        </span>
                                                                        <span className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                                            {value}
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        ))}
                                                        {regulation?.cups?.length && (
                                                            <>
                                                                <h4 className='text-semibold-16 text-cool-grey-800 description-heading'>
                                                                    Conditional Use Permits: ({regulation?.cups?.length}){' '}
                                                                </h4>
                                                                <div
                                                                    style={{
                                                                        maxHeight: '200px',
                                                                        overflowY: 'scroll',
                                                                        border: '1px solid #ccc',
                                                                    }}
                                                                >
                                                                    {regulation?.cups &&
                                                                        regulation.cups.map((cup: IRegulationCup, cupIndex: number) => (
                                                                            <Row key={cupIndex}>
                                                                                {Object.entries(cup).map(([key, value]) => (
                                                                                    <Col key={key} xs='12' md='6' lg='4'>
                                                                                        <span className='label-name text-regular-14 text-cool-grey-500'>
                                                                                            {key}{' '}
                                                                                        </span>
                                                                                        <span className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                                                            {value}
                                                                                        </span>
                                                                                    </Col>
                                                                                ))}
                                                                            </Row>
                                                                        ))}
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                                {/* {elementsTempData?.map((element) => (
                                                    <Col key={element.title}>
                                                        <div className='d-flex align-items-center relative'>
                                                            <ElementTitle>{element.title}</ElementTitle>
                                                            {((element.full_text && element.full_text !== '') ||
                                                                (element.full_text_pdf && element.full_text_pdf !== '')) && (
                                                                <IconButton
                                                                    size='sm'
                                                                    onClick={(e) => handleOpenElementDetailsPopover(element, e)}
                                                                >
                                                                    <i className='fi fi-rr-info' />
                                                                </IconButton>
                                                            )}
                                                        </div>
                                                        <div>{element.summary}</div>
                                                        {element.html && <ElementHtmlWrapper>{parseHtml(element.html)}</ElementHtmlWrapper>}
                                                    </Col>
                                                ))} */}
                                            </Row>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
                {selectedElement && popoverTarget && (
                    <Overlay
                        show={!!selectedElement}
                        target={popoverTarget}
                        container={elementRef}
                        rootClose
                        onHide={handleCloseElementDetailsPopover}
                    >
                        <Popover style={{ minWidth: 320 }}>
                            <Popover.Body>
                                {selectedElement.full_text_pdf && selectedElement.full_text_pdf !== '' ? (
                                    <div style={{ height: 200 }}>
                                        <Viewer
                                            renderLoader={renderFullTextPdfLoader}
                                            fileUrl={selectedElement.full_text_pdf}
                                            plugins={[thumbnailPluginInstance]}
                                        />
                                    </div>
                                ) : (
                                    selectedElement.full_text && selectedElement.full_text !== '' && <div>{selectedElement.full_text}</div>
                                )}
                            </Popover.Body>
                        </Popover>
                    </Overlay>
                )}
            </Worker>
        </>
    );
};
