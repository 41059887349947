import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { pdfjs } from 'react-pdf';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import type { RenderThumbnailItemProps } from '@react-pdf-viewer/thumbnail';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';

import { PERMITS } from 'utils/constants';
import { Loader, Modal, ModalBody, IconButton } from 'components';
import { postMethod } from 'services/api.service';

interface FileDownloadModalProps {
    onClose?: () => void;
    modalTitle?: string;
    title: string;
    pdf?: string;
    type?: string;
    permitId?: string | number;
}

export const FileDownloadModal = ({ modalTitle, title, pdf, type, permitId, onClose }: FileDownloadModalProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const renderLoader = () => {
        return <Loader />;
    };
    const thumbnailPluginInstance = thumbnailPlugin({
        renderSpinner: () => <Loader />,
    });

    const { Thumbnails } = thumbnailPluginInstance;
    const handleDownloadPdf = async (type: string | undefined) => {
        setIsLoading(true);
        try {
            const { data } = await postMethod(PERMITS.DOWNLOAD_PDF, {
                permit_id: permitId,
                pdf_type: type,
            });
            const base64Data = data;
            setIsLoading(false);
            const byteCharacters = atob(base64Data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/octet-stream' });
            const filename = Date.now();
            saveAs(blob, `${filename}.pdf`);
            onClose && onClose();
        } catch (error) {
            toast('Download Failed Please Try Again!!');
            setIsLoading(false);
            onClose && onClose();
        }
    };

    const renderThumbnailItem = ({
        pageIndex,
        currentPage,
        renderPageThumbnail,
        renderPageLabel,
        onJumpToPage,
    }: RenderThumbnailItemProps) => (
        <div
            key={pageIndex}
            className={pageIndex === currentPage ? 'custom-thumbnail-item active-page' : 'custom-thumbnail-item'}
            data-testid={`thumbnail-${pageIndex}`}
        >
            <div style={{ marginBottom: '0.5rem' }} onClick={() => onJumpToPage()}>
                {renderPageThumbnail}
                <div className={pageIndex === currentPage ? 'text-center pt-2 active-label' : 'text-center pt-2'}>{renderPageLabel}</div>
            </div>
        </div>
    );

    return (
        <Modal show onClose={onClose} title={modalTitle} className='modal-wrapper modal-info file-modal' size='lg'>
            <ModalBody>
                <div className='property-map-inner'>
                    <div className='property-map-header d-flex flex-wrap gap-3 pb12'>
                        <div className='prop-map-text'>
                            <h3 className='text-bold-20 text-cool-grey-800'>{title}</h3>
                        </div>
                        <div className='right-close-btn'>
                            <IconButton size='sm' className='close-icon' onClick={onClose}>
                                <i className='fi fi-rr-cross-small' />
                            </IconButton>
                        </div>
                    </div>
                    <div className='file-modal-body pdf-scroll-modal'>
                        <div className='file-modal-inner'>
                            <Row>
                                <Col xs={12}>
                                    <div className='pdf-center-file'>
                                        <IconButton
                                            size='sm'
                                            className='border-icon-btn sticky-btn'
                                            onClick={() => handleDownloadPdf(type)}
                                        >
                                            <i className='fi fi-rr-download' />
                                        </IconButton>
                                        {isLoading ? (
                                            <div className='pdf-loader'>
                                                {' '}
                                                <Loader />{' '}
                                            </div>
                                        ) : (
                                            <div className='pdf-inner-file-section'>
                                                <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
                                                    <Row>
                                                        <Col xs={3}>
                                                            <div className='cursor-pointer left-pdf-thumbnail'>
                                                                <Thumbnails renderThumbnailItem={renderThumbnailItem} />
                                                            </div>
                                                        </Col>
                                                        <Col xs={9}>
                                                            <div className='pdf-center-view'>
                                                                {pdf && (
                                                                    <Viewer
                                                                        renderLoader={renderLoader}
                                                                        fileUrl={pdf}
                                                                        plugins={[thumbnailPluginInstance]}
                                                                    />
                                                                )}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Worker>
                                            </div>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};
