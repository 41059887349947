import { FormikErrors } from 'formik';
import React from 'react';

interface StrongPasswordListProps<T> {
    password: string;
    errors?: FormikErrors<T>;
}

export const StrongPasswordList = <T,>({ errors, password }: StrongPasswordListProps<T>) => {
    const validPassword: boolean | undefined = errors && Object.keys(errors).includes('new_password');

    const getPasswordStrength = () => {
        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(password);
        const isLengthGreaterThan8 = password?.length > 8;

        if (isLengthGreaterThan8 && hasSpecialChar && hasNumber) {
            return 'very strong';
        }

        if (hasSpecialChar) {
            return 'strong';
        }

        if (hasNumber) {
            return 'medium';
        }

        if (hasLowercase) {
            return 'weak';
        }

        if (hasUppercase) {
            return 'very weak';
        }

        return 'very weak';
    };

    const getPasswordColor = () => {
        const strength = getPasswordStrength();

        switch (strength) {
            case 'very strong':
                return 'bg-green-400';
            case 'strong':
                return 'bg-green-400';
            case 'medium':
                return 'bg-yellow-10';
            case 'weak':
                return 'bg-red-400';
            case 'very weak':
                return 'bg-red-400';
            default:
                return ' ';
        }
    };

    return (
        <>
            {password.length > 2 && (
                <div className='password-outer-box'>
                    <div className='password-strong-fill pb8'>
                        <ul className='pass-listing d-flex gap-2'>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => (
                                <li key={index} className={index <= password?.length ? getPasswordColor() : ' '} />
                            ))}
                        </ul>
                    </div>
                    <div className='password-bottom-list'>
                        <ul>
                            <li>
                                <div className='password-fill-char d-flex flex-wrap'>
                                    <span className={`left-icon ${!validPassword ? 'icon-done' : 'icon-circle'}`}>
                                        {!validPassword && <img src='/assets/images/green-check.svg' alt='tick' />}
                                    </span>
                                    <span className='text-char-list'>Should contain a number and special symbol</span>
                                </div>
                            </li>
                            <li>
                                <div className='password-week-char d-flex flex-wrap'>
                                    <span className={`left-icon ${password.length > 8 ? 'icon-done' : 'icon-circle'}`}>
                                        {password.length > 8 && <img src='/assets/images/green-check.svg' alt='tick' />}
                                    </span>
                                    <span className='text-char-list'>Should contain at least 8 characters</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            )}
        </>
    );
};
