import { InputMask } from '@react-input/mask';
import type { MaskEvent } from '@react-input/mask';
import { Form, Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import * as Yup from 'yup';

import { DashboardLayout } from 'layouts';
import { CardStructure, Input, ProjectStepHeader } from 'components';
import { getMethod } from 'services/api.service';
import { customStyles } from 'utils/commonFunctions';
import { CREATE_PROJECT } from 'utils/constants';
import { IProjectLocation } from 'models';
import { selectProjectLocation } from 'store/selectors';

import { ICreateProject } from './CreateProjectStepTwo.models';
import { CreateProjectStepTwoFooter } from './components/CreateProjectStepTwoFooter/CreateProjectStepTwoFooter';

const validationSchema = Yup.object().shape({
    name: Yup.string().max(100, 'Project name is too long - should be 100 chars maximum.'),
    contact_email: Yup.string().email('Invalid email'),
});

export const CreateProjectStepTwo = () => {
    const navigate = useNavigate();
    const [projectFormData, setProjectFormData] = useState<{
        [key: string]: string[];
    }>();
    const locationData = useSelector(selectProjectLocation);

    const initialValues: ICreateProject = {
        name: '',
        address: '',
        city: '',
        country: '',
        state: '',
        technology: '',
        technology_size: 'test',
        contact_email: '',
        contact_phone: '',
        customer_name: '',
        customer_address: locationData?.customer_address || '',
        is_draft: false,
    };

    const getProjectData = async (params?: string) => {
        try {
            let url: string = CREATE_PROJECT.GET_PROJECT_DATA;

            if (params) {
                url = `${CREATE_PROJECT.GET_PROJECT_DATA}?country=${params}`;
            }
            const { data } = await getMethod(url);
            setProjectFormData(data?.data);
        } catch (error) {
            // TODO: Error handling
        }
    };

    const countryOptions = useMemo(
        () => projectFormData?.country.map((item: string) => ({ value: item, label: item })) || [],
        [projectFormData?.country],
    );
    const technologyOptions = useMemo(
        () => projectFormData?.technologies.map((item: string) => ({ value: item, label: item })) || [],
        [projectFormData?.technologies],
    );
    const stateOptions = useMemo(
        () => projectFormData?.states.map((item: string) => ({ value: item, label: item })) || [],
        [projectFormData?.states],
    );

    const handleBackToCreateProject = () => {
        navigate(-1);
        const locationValue = { ...locationData, isBack: true };
        localStorage.setItem('locationValue', JSON.stringify(locationValue));
    };

    useEffect(() => {
        getProjectData();
    }, []);

    return (
        <DashboardLayout headerTitle='Create New Project' onClickBackButton={() => handleBackToCreateProject()}>
            <div className='create-project-main pt4'>
                <CardStructure className='card-spacing-24'>
                    <ProjectStepHeader
                        prevStep1='prev'
                        lineActive='lineActive'
                        value1={<i className='fi fi-rr-check' />}
                        value2='2'
                        activeStep2='active current'
                        step1Label='Select Property'
                        step2Label='Project Details'
                    />
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        onSubmit={() => {
                            // Handle submit in footer component
                        }}
                        validationSchema={validationSchema}
                    >
                        {({ values, errors, isValid, handleChange, handleSubmit }) => (
                            <Form onSubmit={handleSubmit}>
                                <div className='create-project-body d-flex flex-wrap pt12'>
                                    <div className='form-left-project'>
                                        <Row>
                                            <Col xs={12}>
                                                <Input
                                                    name='name'
                                                    labelName='Project name (max 100 characters)'
                                                    placeholder='Enter project name'
                                                    type='text'
                                                    size='sm'
                                                    className='sm-label'
                                                    value={values?.name}
                                                    onChange={handleChange('name')}
                                                    isInvalid={!!errors?.name}
                                                    errorMsg={errors?.name as string}
                                                />
                                            </Col>
                                            <Col md={12} lg={6}>
                                                <Input
                                                    name='customer_name'
                                                    labelName='Customer name'
                                                    placeholder='Enter customer name'
                                                    type='text'
                                                    size='sm'
                                                    className='sm-label'
                                                    value={values?.customer_name}
                                                    onChange={handleChange('customer_name')}
                                                    isInvalid={!!errors?.customer_name}
                                                    errorMsg={errors?.customer_name as string}
                                                />
                                            </Col>
                                            <Col md={12} lg={6}>
                                                <Input
                                                    name='contact_email'
                                                    labelName='Contact email'
                                                    placeholder='Enter contact email'
                                                    type='text'
                                                    size='sm'
                                                    className='sm-label'
                                                    value={values?.contact_email}
                                                    onChange={handleChange('contact_email')}
                                                    isInvalid={!!errors?.contact_email}
                                                    errorMsg={errors?.contact_email as string}
                                                />
                                            </Col>
                                            <Col md={12} lg={6}>
                                                <div className='custom_form mb7 sm-label'>
                                                    <div className='form-label-outer d-flex align-items-center flex-wrap justify-content-between gap-2'>
                                                        <label className='text-regular-15 text-cool-grey-800 flex-grow-1 form-label'>
                                                            Contact phone
                                                        </label>
                                                        <div className='text-regular-12 text-red-400 mb2  invalid-feedback' />
                                                    </div>
                                                    <InputMask
                                                        className='sm form-control'
                                                        mask='+1 (___) ___ ____'
                                                        replacement={{ _: /\d/ }}
                                                        placeholder='+1(123) 987 8346'
                                                        onMask={(e: MaskEvent) => handleChange('contact_phone')(e.detail.value)}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={12} lg={6}>
                                                <Input
                                                    name='address'
                                                    labelName='Address'
                                                    placeholder='Enter project address'
                                                    type='text'
                                                    size='sm'
                                                    className='sm-label'
                                                    value={values?.address}
                                                    isInvalid={!!errors?.address}
                                                    errorMsg={errors?.address as string}
                                                    onChange={handleChange('address')}
                                                />
                                            </Col>
                                            <Col md={12} lg={6}>
                                                <Input
                                                    name='city'
                                                    labelName='City'
                                                    placeholder='Enter City'
                                                    type='text'
                                                    size='sm'
                                                    className='sm-label'
                                                    value={values?.city}
                                                    isInvalid={!!errors?.city}
                                                    errorMsg={errors?.city ? (errors?.city as string) : ''}
                                                    onChange={handleChange('city')}
                                                />
                                            </Col>
                                            <Col md={12} lg={6}>
                                                <div className='custom_form mb7'>
                                                    <label className='select-label'>Country</label>
                                                    <Select
                                                        onChange={(e) => {
                                                            if (e?.value) {
                                                                handleChange('country')(e.value);
                                                            }
                                                        }}
                                                        options={countryOptions}
                                                        value={countryOptions.find((option) => option.value === values?.country)}
                                                        className='custom-select-box'
                                                        placeholder='Choose country'
                                                        styles={customStyles}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={12} lg={6}>
                                                <div className='custom_form mb7'>
                                                    <label className='select-label'>State</label>
                                                    <Select
                                                        onChange={(e) => {
                                                            if (e?.value) {
                                                                handleChange('state')(e.value);
                                                            }
                                                        }}
                                                        options={stateOptions}
                                                        value={stateOptions.find((option) => option.value === values?.state)}
                                                        className='custom-select-box'
                                                        placeholder='Choose state'
                                                        styles={customStyles}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={12} lg={6}>
                                                <div className='custom_form mb7'>
                                                    <label className='select-label'>Technology</label>
                                                    <Select
                                                        onChange={(e) => {
                                                            if (e?.value) {
                                                                handleChange('technology')(e.value);
                                                            }
                                                        }}
                                                        options={technologyOptions}
                                                        value={technologyOptions.find((option) => option.value === values?.technology)}
                                                        className='custom-select-box'
                                                        placeholder='Choose technology'
                                                        styles={customStyles}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Input
                                                name='customer_address'
                                                labelName='Customer Address'
                                                type='hidden'
                                                size='sm'
                                                className='sm-label d-none'
                                                value={values?.customer_address}
                                            />
                                        </Row>
                                    </div>
                                    {/* right form */}
                                    <div className='right-following-info pl12'>
                                        <div className='location-form-inner v-border min-h100 pl12 d-flex flex-wrap'>
                                            <div className='info-following-content'>
                                                <div className='btn-top pb8'>
                                                    <span className='info-icon-box-top'>
                                                        <i className='fi fi-rr-bulb' />
                                                    </span>
                                                </div>
                                                <h4 className='title-main text-cool-grey-800 text-semibold-18 pb8'>
                                                    Please consider the <br /> following info:
                                                </h4>
                                                <div className='info-content-follow'>
                                                    <p className='pb6'>
                                                        <strong>Policy Type:</strong> Choose the type of clean energy policy or incentive
                                                        that best suits your project&#39;s needs. Options may include tax credits, grants,
                                                        loan guarantees, subsidies, or other financial incentives.
                                                    </p>
                                                    <p>
                                                        <strong>Policy type:</strong> Specify the project segment to which your clean energy
                                                        project belongs. Options may include:
                                                    </p>
                                                    <ul>
                                                        <li>
                                                            Residential: Projects targeting individual homes or small-scale residential
                                                            developments.
                                                        </li>
                                                        <li>
                                                            Commercial: Projects intended for commercial properties, businesses, or
                                                            larger-scale developments.
                                                        </li>
                                                        <li>
                                                            Utility: Projects focused on utility-scale clean energy generation, such as
                                                            large solar or wind farms.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <CreateProjectStepTwoFooter
                                    isValid={isValid}
                                    values={values}
                                    location={locationData as IProjectLocation}
                                    onBackToProject={handleBackToCreateProject}
                                />
                            </Form>
                        )}
                    </Formik>
                </CardStructure>
            </div>
        </DashboardLayout>
    );
};
