import React from 'react';

import { TextChip } from 'components';

interface ProjectCardBodyProps {
    overallStatus?: string;
    permittingStatus: string;
    incentivesStatus: string;
    overallColor: string;
    permittingColor: string;
    incentivesColor: string;
}

export const ProjectCardBody = ({
    overallStatus,
    permittingStatus,
    incentivesStatus,
    overallColor,
    permittingColor,
    incentivesColor,
}: ProjectCardBodyProps) => (
    <div className='project-card-bottom'>
        <div className='cstm-row'>
            <div className='box1 overall-status-bx'>
                <div className='content-card-project'>
                    <h4 className='label-name text-cool-grey-400 text-regular-12'>Overall status</h4>
                    <div className='detail-card-pro text-cool-grey-800 text-regular-14'>
                        <TextChip bgColor={overallColor}>{overallStatus}</TextChip>
                    </div>
                </div>
            </div>
            <div className='box1 permitting-status-bx'>
                <div className='content-card-project'>
                    <h4 className='label-name text-cool-grey-400 text-regular-12'>Permitting status</h4>
                    <div className='detail-card-pro text-cool-grey-800 text-regular-14'>
                        <TextChip bgColor={permittingColor}>{permittingStatus}</TextChip>
                    </div>
                </div>
            </div>
            <div className='box1 incentives-status-bx'>
                <div className='content-card-project'>
                    <h4 className='label-name text-cool-grey-400 text-regular-12'>Incentives status</h4>
                    <div className='detail-card-pro text-cool-grey-800 text-regular-14'>
                        <TextChip bgColor={incentivesColor}> {incentivesStatus}</TextChip>
                    </div>
                </div>
            </div>
        </div>
    </div>
);
