import React, { ReactNode } from 'react';

interface ProjectStepHeaderProps {
    className?: string;
    activeStep1?: string;
    value1: ReactNode;
    lineActive?: string;
    activeStep2?: string;
    value2: string;
    prevStep1?: string;
    prevStep2?: string;
    step1Label: string;
    step2Label: string;
}

export const ProjectStepHeader = ({
    className,
    activeStep1,
    value1,
    lineActive,
    activeStep2,
    value2,
    prevStep1,
    prevStep2,
    step1Label,
    step2Label,
}: ProjectStepHeaderProps) => (
    <div className={`project-step-header ${className}`}>
        <ul className='steps-listing'>
            <li>
                <div className={`steps-list-group d-flex align-items-center gap-3 ${activeStep1} ${prevStep1}`}>
                    <div className='step-count-icon'>
                        <div className='steps-counting'>{value1}</div>
                    </div>
                    <div className='step-label'>{step1Label}</div>
                </div>
            </li>
            <li className='line-step-outer'>
                <hr className={`step-line ${lineActive}`} />
            </li>
            <li>
                <div className={`steps-list-group d-flex align-items-center gap-3 ${activeStep2} ${prevStep2}`}>
                    <div className='step-count-icon'>
                        <div className='steps-counting'>{value2}</div>
                    </div>
                    <div className='step-label'>{step2Label}</div>
                </div>
            </li>
        </ul>
    </div>
);
