import React from 'react';
import parseHtml from 'html-react-parser';

import { IconLinkButton } from 'components';

interface RegulationCardProps {
    title: string;
    description?: string;
    btnName: string;
    onClick: () => void;
}

export const RegulationCard = ({ title, description, btnName, onClick }: RegulationCardProps) => (
    <div className='regulation-rule-main'>
        <div className='regulation-rule-head d-flex align-items-center justify-content-between gap-2 pb5'>
            <div className='left-text'>
                <h4 className='text-regular-14 text-cool-grey-800'>{parseHtml(title)}</h4>
            </div>
            <div className='right-btn text-end'>
                <IconLinkButton className='filter-btn text-bold-14 xs-btn' onClick={onClick}>
                    {btnName}
                </IconLinkButton>
            </div>
        </div>
        <div className='regulation-card-body'>
            <p className='text-regular-12 text-cool-grey-800'>{description}</p>
        </div>
        <hr className='h-border-top' />
    </div>
);
