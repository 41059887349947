import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { PERMIT_ELIGIBILITY_QUESTIONNAIRE } from 'pages/NewPermit/NewPermit.constants';
import { selectPermit } from 'store/selectors';
import { ICreateProject } from 'pages/CreateProjectStepTwo/CreateProjectStepTwo.models';
import { getMethod } from 'services/api.service';
import { PERMITS } from 'utils/constants';
import {
    ElectricUtilityType,
    GasUtilityType,
    ICreatePermit,
    PropertyOwnershipType,
    ResponsibleForPayingElectricServicesType,
} from 'pages/NewPermit/NewPermit.models';
import { mapBackendPayloadToCreatePermit } from 'pages/NewPermit/NewPermit.mapper';

export const Permit = () => {
    const getLabelFromEnumKey = <T extends Record<string, string>>(enumObject: T, key?: keyof T) => {
        return key && key in enumObject ? enumObject[key] : '-';
    };

    const { permitId } = useParams();
    const [projectInfo, setProjectInfo] = useState<ICreateProject>();
    const [permitInfo, setPermitInfo] = useState<ICreatePermit>();
    const storePermitInfo = useSelector(selectPermit);

    useEffect(() => {
        const getPermitDetails = async (id: string) => {
            try {
                const { data } = await getMethod(`${PERMITS.PERMITS}?id=${id}`);

                if (data?.status) {
                    setPermitInfo(mapBackendPayloadToCreatePermit(data?.data));
                }

                if (!projectInfo && data?.data?.project_data) {
                    setProjectInfo(data?.data?.project_data);
                }
            } catch (error) {
                if (error instanceof AxiosError && error?.response?.data?.status === false) {
                    toast(error?.response?.data?.message);
                }
            }
        };

        if (storePermitInfo?.id) {
            setPermitInfo(storePermitInfo);

            if (!projectInfo && storePermitInfo?.project_data) {
                setProjectInfo(storePermitInfo?.project_data);
            }
        } else if (permitId) {
            getPermitDetails(permitId);
        }
    }, [permitId, storePermitInfo, projectInfo]);

    return (
        <div className='create-project-body d-flex flex-wrap pt12'>
            <div className='form-left-project'>
                <div className='new-permit-form generate-step-two'>
                    <div className='title-md'>
                        <h3 className='text-cool-grey-800 text-bold-20 pb10'>Permit Application</h3>
                    </div>
                    <div className='new-permit-card'>
                        <h3 className='new-permit-title text-cool-grey-800 text-semibold-18 pb6'>Project Info:</h3>
                        <div className='location-edit-box'>
                            <div className='location-header pb4'>
                                <h5>Location</h5>
                            </div>
                            <Row>
                                <Col xs={12} sm={6} lg={3}>
                                    <div className='project-popup-detail'>
                                        <div className='label-name text-regular-14 text-cool-grey-500'>State</div>
                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                            {projectInfo?.location?.state || projectInfo?.state},{' '}
                                            {projectInfo?.location?.country || projectInfo?.country}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} lg={3}>
                                    <div className='project-popup-detail'>
                                        <div className='label-name text-regular-14 text-cool-grey-500'>City / Municipality</div>
                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                            {projectInfo?.location?.city || projectInfo?.city}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} lg={3}>
                                    <div className='project-popup-detail'>
                                        <div className='label-name text-regular-14 text-cool-grey-500'>Address</div>
                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                            {projectInfo?.customer_address}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} lg={3}>
                                    <div className='project-popup-detail'>
                                        <div className='label-name text-regular-14 text-cool-grey-500'>Zip Code</div>
                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                            {projectInfo?.location?.zip_code}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <hr className='h-border-top' />
                        </div>
                        <div className='location-edit-box'>
                            <div className='location-header pb4'>
                                <h5>Project Details</h5>
                            </div>
                            <Row>
                                <Col xs={12} sm={6} lg={3}>
                                    <div className='project-popup-detail'>
                                        <div className='label-name text-regular-14 text-cool-grey-500'>Customer Name</div>
                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                            {projectInfo?.customer_name}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} lg={3}>
                                    <div className='project-popup-detail'>
                                        <div className='label-name text-regular-14 text-cool-grey-500'>Contact email</div>
                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                            {projectInfo?.contact_email}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} lg={3}>
                                    <div className='project-popup-detail'>
                                        <div className='label-name text-regular-14 text-cool-grey-500'>Contact phone</div>
                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                            {projectInfo?.contact_phone}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={6} lg={3}>
                                    <div className='project-popup-detail'>
                                        <div className='label-name text-regular-14 text-cool-grey-500'>Project type</div>
                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                            {projectInfo?.technology}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} lg={3}>
                                    <div className='project-popup-detail'>
                                        <div className='label-name text-regular-14 text-cool-grey-500'>Technology size</div>
                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>Technology size</div>
                                    </div>
                                </Col>
                            </Row>
                            <hr className='h-border-top' />
                        </div>
                    </div>

                    <div className='new-permit-card'>
                        <h3 className='new-permit-title text-cool-grey-800 text-semibold-18 pb6'>Host Customer</h3>
                        <div className='location-edit-box'>
                            <Row>
                                <Col xs={12} sm={6} lg={3}>
                                    <div className='project-popup-detail'>
                                        <div className='label-name text-regular-14 text-cool-grey-500'>Date</div>
                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                            {permitInfo?.host_customer?.date}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} lg={3}>
                                    <div className='project-popup-detail'>
                                        <div className='label-name text-regular-14 text-cool-grey-500'>Name</div>
                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                            {permitInfo?.host_customer?.name}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} lg={3}>
                                    <div className='project-popup-detail'>
                                        <div className='label-name text-regular-14 text-cool-grey-500'>Company</div>
                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                            {permitInfo?.host_customer?.company}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={6} lg={3}>
                                    <div className='project-popup-detail'>
                                        <div className='label-name text-regular-14 text-cool-grey-500'>Applicant Address</div>
                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                            {permitInfo?.host_customer?.address}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} lg={3}>
                                    <div className='project-popup-detail'>
                                        <div className='label-name text-regular-14 text-cool-grey-500'>Email</div>
                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                            {permitInfo?.host_customer?.email}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} lg={3}>
                                    <div className='project-popup-detail'>
                                        <div className='label-name text-regular-14 text-cool-grey-500'>Phone</div>
                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                            {permitInfo?.host_customer?.phone}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <h3 className='new-permit-title text-cool-grey-800 text-semibold-18 pb6'>Project Site Information</h3>
                        <div className='location-edit-box'>
                            <Row>
                                <Col xs={12} sm={6} lg={3}>
                                    <div className='project-popup-detail'>
                                        <div className='label-name text-regular-14 text-cool-grey-500'>Property Name</div>
                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                            {permitInfo?.project_site?.name}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} lg={3}>
                                    <div className='project-popup-detail'>
                                        <div className='label-name text-regular-14 text-cool-grey-500'>Property Ownership Type</div>
                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                            {getLabelFromEnumKey(PropertyOwnershipType, permitInfo?.project_site?.ownership_type)}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <h3 className='new-permit-title text-cool-grey-800 text-semibold-18 pb6'>Property Eligibility</h3>
                        <div className='location-edit-box'>
                            <Row>
                                <Col xs={12}>
                                    <div className='project-popup-detail'>
                                        <div className='label-name text-regular-14 text-cool-grey-500'>Census Tract Code</div>
                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                            {permitInfo?.property_eligibility?.census_tract_code}
                                        </div>
                                    </div>
                                </Col>
                                {Array.from(PERMIT_ELIGIBILITY_QUESTIONNAIRE.entries()).map(([key, label]) => {
                                    return (
                                        <Col xs={12} key={key}>
                                            <div className='project-popup-detail'>
                                                <div className='label-name text-regular-14 text-cool-grey-500'>{label}</div>
                                                <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                    {permitInfo?.property_eligibility[key] ? 'Yes' : 'No'}
                                                </div>
                                            </div>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </div>

                        <h3 className='new-permit-title text-cool-grey-800 text-semibold-18 pb6'>Electrical Utility Information</h3>
                        <div className='location-edit-box'>
                            <Row>
                                <Col xs={12}>
                                    <div className='project-popup-detail'>
                                        <div className='label-name text-regular-14 text-cool-grey-500'>Utility</div>
                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                            {getLabelFromEnumKey(ElectricUtilityType, permitInfo?.electrical_utility?.utility)}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className='project-popup-detail'>
                                        <div className='label-name text-regular-14 text-cool-grey-500'>
                                            Does the property receive electric service from a community choice aggregation (CCA)?
                                        </div>
                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                            {permitInfo?.electrical_utility?.does_receive_electric_service_from_CCA ? 'Yes' : 'No'}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={6} lg={3}>
                                    <div className='project-popup-detail'>
                                        <div className='label-name text-regular-14 text-cool-grey-500'>
                                            Who is responsible for paying for electric services in tenant units
                                        </div>
                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                            {getLabelFromEnumKey(
                                                ResponsibleForPayingElectricServicesType,
                                                permitInfo?.electrical_utility?.responsible_for_paying_electric_services,
                                            )}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} lg={3}>
                                    <div className='project-popup-detail'>
                                        <div className='label-name text-regular-14 text-cool-grey-500'>
                                            Numbers of Meters for Common Areas:
                                        </div>
                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                            {permitInfo?.electrical_utility?.meters_of_common_areas}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <h3 className='new-permit-title text-cool-grey-800 text-semibold-18 pb6'>Gas Utility Information</h3>
                        <div className='location-edit-box'>
                            <Row>
                                <Col xs={12}>
                                    <div className='project-popup-detail'>
                                        <div className='label-name text-regular-14 text-cool-grey-500'>Gas Utility</div>
                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                            {getLabelFromEnumKey(GasUtilityType, permitInfo?.gas_utility?.utility)}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
