import Form from 'react-bootstrap/Form';
import React, { useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useDebounce } from 'hooks/useDebounce';
import { selectSearchResultCount } from 'store/selectors';

interface SearchBoxProps {
    formClassName?: string;
    size?: 'sm' | 'lg' | undefined;
    type?: string;
    placeholder: string;
    searchContainerClassName?: string;
    searchClassName?: string;
    onSearch?: (data: string) => void;
}

export const SearchBox = ({
    formClassName,
    searchClassName,
    size,
    type,
    placeholder,
    searchContainerClassName,
    onSearch,
}: SearchBoxProps) => {
    const ref = useRef<HTMLInputElement>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const resultCount = useSelector(selectSearchResultCount);

    const handleDebounced = useCallback(
        (value: string) => {
            setSearchTerm(value);
            onSearch?.(value);
        },
        [onSearch],
    );

    const [debouncedCallback] = useDebounce<string>(handleDebounced, 700);

    return (
        <Form.Group className={`custom_search ${formClassName} ${searchContainerClassName}`}>
            <div className={`search-outer position-relative ${searchClassName}`}>
                <Form.Control
                    ref={ref}
                    className={`${size}`}
                    type={type || 'text'}
                    placeholder={placeholder || ''}
                    onChange={(event) => debouncedCallback(event.target.value)}
                />
                <span className='search-icon'>
                    <i className='fi fi-rr-search' />
                </span>
                <span
                    className={searchTerm.length > 0 ? 'close-icon cursor-pointer' : 'd-none'}
                    onClick={() => {
                        handleDebounced('');

                        if (ref?.current) {
                            ref.current.value = '';
                        }
                    }}
                >
                    <i className='fi fi-rr-circle-xmark' />
                </span>
            </div>
            {resultCount !== undefined && resultCount !== null && resultCount > 0 ? (
                <span className='result-count'>{resultCount} results</span>
            ) : (
                ''
            )}
        </Form.Group>
    );
};
