import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { DashboardLayout } from 'layouts';
import { Button, CardStructure, TextChip, IconButton, DeleteAccountModal, FileDownloadModal, InfoModal, Permit } from 'components';
import { deleteMethod, getMethod } from 'services/api.service';
import { PERMITS } from 'utils/constants';
import { IPermitDetails } from 'models';

export const PermitDetails = () => {
    const [infoModal, setInfoModal] = useState(false);
    const [modalFile, setModalFile] = useState(false);
    const [fileSitePlan, setFileSitePlan] = useState(false);
    const [filePermitApplication, setFilePermitApplication] = useState(false);
    const [deleteProject, setDeleteProject] = useState(false);
    const { permitId } = useParams<{ permitId: string }>();
    const [permitDetails, setPermitDetails] = useState<IPermitDetails>({});
    const [sitePdf, setSitePdf] = useState<string>('');
    const [evPdf, setEvPdf] = useState<string>('');
    const navigate = useNavigate();

    const deletePermit = async () => {
        try {
            const { data } = await deleteMethod(`${PERMITS.DELETE_PERMITS}?id=${permitId}`);

            if (data?.status) {
                toast(data?.message);
                navigate(`/project-info/${permitDetails?.project_id}`);
            }
        } catch (error) {
            // TODO: Error handling
        }
    };

    useEffect(() => {
        const getPermitDetails = async (id: string) => {
            try {
                const { data } = await getMethod(`${PERMITS.PERMITS}?id=${id}`);

                if (data?.status) {
                    setPermitDetails(data?.data);
                    setSitePdf(data?.data?.site_plan_site_line_link);
                    setEvPdf(data?.data?.ev_charging_station_specs_link);
                }
            } catch (error) {
                if (error instanceof AxiosError && error?.response?.data?.status === false) {
                    toast(error?.response?.data?.message);
                    navigate('/projects');
                }
            }
        };

        if (permitId) {
            getPermitDetails(permitId);
        }
    }, [navigate, permitId]);

    return (
        <>
            <DashboardLayout
                headerTitle='Permit Details'
                onClickBackButton={() => {
                    navigate(-1);
                }}
            >
                <div className='create-project-main pt4'>
                    <CardStructure className='card-spacing-24'>
                        <div className='project-info-header d-flex flex-wrap gap-3 permit-details-header align-items-center'>
                            <div className='project-info-left'>
                                <div className='permit-detail-tag d-flex flex-wrap align-items-center gap-3'>
                                    <h4 className='text-cool-grey-800 text-bold-18'>{permitDetails?.name}</h4>
                                    <TextChip
                                        bgColor={
                                            permitDetails?.permit_status === 'Not Started'
                                                ? 'blue'
                                                : permitDetails?.permit_status === 'In Review'
                                                  ? 'yellow'
                                                  : permitDetails?.permit_status === 'Approved'
                                                    ? 'green'
                                                    : 'grey'
                                        }
                                    >
                                        {permitDetails?.permit_status}
                                    </TextChip>
                                </div>
                            </div>
                            <div
                                className={permitDetails?.is_demo ? 'delete-btn-icon disabled' : 'delete-btn-icon'}
                                onClick={() => setDeleteProject(true)}
                            >
                                <IconButton size='sm' disabled={permitDetails?.is_demo ? 'disabled' : ''}>
                                    <i className='fi fi-rr-trash' />
                                </IconButton>
                            </div>
                            <Button
                                variant='primary'
                                size='sm'
                                className='w-100 max-w200'
                                onClick={() => navigate(`/edit-permit/${permitId}`)}
                                disabled={permitDetails?.is_demo ? 'disabled' : ''}
                            >
                                Edit Permit
                            </Button>
                        </div>
                        <hr className='h-border-top' />

                        <Permit />
                    </CardStructure>
                </div>
            </DashboardLayout>

            <InfoModal
                show={infoModal}
                onClose={() => setInfoModal(false)}
                modalTitle='Zip Code'
                title='A ZIP Code is a postal code used by the United States Postal Service (USPS) and is an acronym for Zone Improvement Plan. The first five digits indicate the destination post office or delivery area. The last 4 digits represent specific delivery routes within delivery areas.'
                confirmButtonLabel='Got It'
                confirmButtonClassName='min-w190'
                onConfirm={() => setInfoModal(false)}
            />

            {modalFile && (
                <FileDownloadModal
                    onClose={() => setModalFile(false)}
                    title='EV Charging Station Specs'
                    pdf={evPdf}
                    type='ev_charging_station'
                    permitId={permitDetails.id}
                />
            )}

            {fileSitePlan && (
                <FileDownloadModal
                    onClose={() => setFileSitePlan(false)}
                    title='Site Plan'
                    pdf={sitePdf}
                    type='site_plan'
                    permitId={permitDetails.id}
                />
            )}

            {filePermitApplication && (
                <FileDownloadModal
                    onClose={() => setFilePermitApplication(false)}
                    title='Permit Application'
                    type='all'
                    permitId={permitDetails.id}
                />
            )}
            <DeleteAccountModal
                show={deleteProject}
                onClose={() => setDeleteProject(false)}
                imageUrl='/assets/images/questionmark-icon.svg'
                title={permitDetails?.name}
                subtitle='This action can not be undone'
                leftButtonLabel='Cancel'
                onClickLeftButton={() => setDeleteProject(false)}
                onClickRightButton={() => deletePermit()}
                rightButtonLabel='Delete'
            />
        </>
    );
};
