import React, { ReactNode } from 'react';
import Form from 'react-bootstrap/Form';

interface CheckboxProps {
    id: string;
    label: ReactNode;
    defaultChecked: boolean;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export const Checkbox = ({ onChange, label, id, defaultChecked }: CheckboxProps) => (
    <Form.Check aria-label='option 1' className='cstm-checkbox'>
        <Form.Check.Input id={id} onChange={onChange} defaultChecked={defaultChecked} />
        <Form.Check.Label htmlFor={id} className='text-regular-15 text-cool-grey-500'>
            {label}
        </Form.Check.Label>
    </Form.Check>
);
