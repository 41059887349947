import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { getMethod } from 'services/api.service';
import { PROFILE } from 'utils/constants';
import { ConfirmModal, Button } from 'components';
import { AccordionMenu } from '../AccordionMenu/AccordionMenu';

interface AppBarProps {
    appMenuClassName?: string;
}

export const AppBar = ({ appMenuClassName }: AppBarProps) => {
    const navigate = useNavigate();
    const [logoutModal, setLogoutModal] = useState(false);
    const handleLogout = async () => {
        try {
            const { data } = await getMethod(PROFILE.LOGOUT);

            if (data.status === true) {
                localStorage.clear();
                navigate('/');
            }
        } catch (error) {
            // TODO: Error handling
        }
    };

    return (
        <>
            <div className={`app-bar-main ${appMenuClassName}`}>
                <div className='logo-box mb14 pt7 pl19'>
                    <Link to='/dashboard' className='d-inline-block'>
                        <img src='/assets/images/logo-white.svg' alt='logo-white' />
                    </Link>
                </div>
                <ul className='flex-column side-menu-bar d-flex gap-3 overflow-scroll side-menu-top'>
                    <li>
                        <Link to='/dashboard' className={`nav-link ${window?.location?.href?.includes('/dashboard') && 'active'}`}>
                            <div className='menu-outer-box d-flex align-items-center flex-wrap'>
                                <span className='icon-box'>
                                    <i className='fi fi-rr-layout-fluid' />
                                </span>
                                <span className='menu-title'>Dashboard</span>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <AccordionMenu />
                    </li>
                    <li>
                        <Link to='/explorer-view' className={`nav-link ${window?.location?.href?.includes('/explorer-view') && 'active'}`}>
                            <div className='menu-outer-box d-flex align-items-center flex-wrap'>
                                <span className='icon-box'>
                                    <i className='fi fi-rr-map-marker' />
                                </span>
                                <span className='menu-title'>Explorer View</span>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to='/permits' className={`nav-link ${window?.location?.href?.includes('/permits') && 'active'}`}>
                            <div className='menu-outer-box d-flex align-items-center flex-wrap'>
                                <span className='icon-box'>
                                    <i className='fi fi-rr-document' />
                                </span>
                                <span className='menu-title'>Permits</span>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to='/incentives' className={`nav-link ${window?.location?.href?.includes('/incentives') && 'active'}`}>
                            <div className='menu-outer-box d-flex align-items-center flex-wrap'>
                                <span className='icon-box'>
                                    <i className='fi fi-rr-receipt' />
                                </span>
                                <span className='menu-title'>Incentives</span>
                            </div>
                        </Link>
                    </li>
                    <li className='mt-auto pb6'>
                        <Button variant='outline-white' btnFullClassName='w-100' onClick={() => navigate('/create-project')}>
                            <span className='btn-icon mr5'>
                                <i className='fi fi-rr-plus' />
                            </span>
                            Create New Project
                        </Button>
                    </li>
                    <li>
                        <Link to='/profile' className={`nav-link ${window?.location?.href?.includes('/profile') && 'active'}`}>
                            <div className='menu-outer-box d-flex align-items-center flex-wrap'>
                                <span className='icon-box'>
                                    <i className='fi fi-rr-user' />
                                </span>
                                <span className='menu-title'>Profile</span>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <div className='nav-link cursor-pointer' onClick={() => setLogoutModal(true)}>
                            <div className='menu-outer-box d-flex align-items-center flex-wrap'>
                                <span className='icon-box'>
                                    <i className='fi fi-rr-exit' />
                                </span>
                                <span className='menu-title'>Log Out</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <ConfirmModal
                show={logoutModal}
                onClose={() => setLogoutModal(false)}
                imageUrl='/assets/images/questionmark-icon.svg'
                title='Are You Sure You Want to Log Out?'
                leftButtonLabel='Cancel'
                onClickLeftButton={() => setLogoutModal(false)}
                onClickRightButton={handleLogout}
                rightButtonLabel='Log out'
                className='logout-body'
            />
        </>
    );
};
