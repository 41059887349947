import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { DashboardLayout } from 'layouts';
import { Button, CardStructure, DeleteAccountModal, ConfirmModal } from 'components';
import { getMethod, postWithOutAuthMethod, putWithOutAuthMethod } from 'services/api.service';
import { AUTH, PROFILE } from 'utils/constants';

import { ChangeEmailModal, ChangeEmailSentModal, ChangePasswordModal, CreateNewPasswordModal } from './components';
import { ResetPasswordBody } from './Profile.models';

export const Profile = () => {
    const navigate = useNavigate();
    const [changeEmail, setChangeEmail] = useState(false);
    const [changeEmailSent, setChangeEmailSent] = useState(false);
    const [changeEmailNew, setChangeEmailNew] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const [createNewPassword, setCreateNewPassword] = useState(false);
    const [logoutModal, setLogoutModal] = useState(false);
    const [accountDelete, setAccountDelete] = useState(false);
    const [passwordToken, setPasswordToken] = useState('');
    const closeEmail = () => {
        setChangeEmailSent(true);
        setChangeEmail(false);
    };
    const closeEmailSent = () => {
        setChangeEmailSent(false);
        setChangeEmailNew(true);
    };

    const handleChangePassword = async () => {
        const user_email: string | null = localStorage.getItem('email');
        setChangePassword(true);
        try {
            await postWithOutAuthMethod(AUTH.FORGOT_PASSWORD, {
                email: user_email,
            });
        } catch (error) {
            // TODO: Error handling
        }
    };

    const closeChangePassword = () => {
        setChangePassword(false);
        setCreateNewPassword(true);
    };

    const handleLogout = async () => {
        try {
            const { data } = await getMethod(PROFILE.LOGOUT);

            if (data.status === true) {
                localStorage.clear();
                navigate('/');
            }
        } catch (error) {
            // TODO: Error handling
        }
    };

    const handleDeleteAccount = async () => {
        try {
            const { data } = await getMethod(PROFILE.DELETE_ACCOUNT);

            if (data.status === true) {
                localStorage.clear();
                navigate('/');
            }
        } catch (error) {
            // TODO: Error handling
        }
    };

    const handleResetPassword = async (values: ResetPasswordBody) => {
        const params = { ...values, token: passwordToken };
        try {
            const { data } = await putWithOutAuthMethod(AUTH.RESET_PASSWORD, params);

            if (data?.status === true) {
                toast(data?.message);
                localStorage.removeItem('password-token');
                navigate('/');
            }
        } catch (error) {
            if (error instanceof AxiosError && error?.response?.data?.status === false) {
                toast(error?.response?.data?.message);
            }
        }
    };

    useEffect(() => {
        if (localStorage.getItem('password-token') !== null || undefined) {
            setCreateNewPassword(true);
            const passToken: string | null = localStorage.getItem('password-token');

            if (passToken !== null) {
                setPasswordToken(passToken.replace('/change-password/', ' '));
            }
        }
    }, []);

    return (
        <>
            <DashboardLayout headerTitle='Profile'>
                <CardStructure className='profile-card-outer'>
                    <div className='profile-card-box'>
                        <h4 className='text-cool-grey-800 text-semibold-18 pb8'>Security</h4>
                        <p className='email-name-link pb8 text-regular-16 text-indigo-800 d-none'>new_email@example.com</p>
                        <ul className='profile-list-group'>
                            <li className='d-none'>
                                <Button variant='primary' size='sm' className='' onClick={() => setChangeEmail(true)}>
                                    Resend Instructions
                                </Button>
                            </li>
                            <li className='d-none'>
                                <Button variant='outline-primary' size='sm' className='' onClick={() => setChangeEmail(true)}>
                                    Change Email
                                </Button>
                            </li>
                            <li>
                                <Button variant='outline-primary' size='sm' className='' onClick={handleChangePassword}>
                                    Change Password
                                </Button>
                            </li>
                        </ul>
                    </div>

                    <div className='profile-card-box'>
                        <h4 className='text-cool-grey-800 text-semibold-18 pb8'>Account</h4>
                        <ul className='profile-list-group'>
                            <li className='d-none'>
                                <Button variant='outline-primary' size='sm' className='' onClick={() => setAccountDelete(true)}>
                                    Delete Account
                                </Button>
                            </li>
                            <li>
                                <Button variant='outline-primary' size='sm' className='' onClick={() => setLogoutModal(true)}>
                                    Log Out
                                </Button>
                            </li>
                        </ul>
                    </div>
                </CardStructure>
            </DashboardLayout>

            <ChangeEmailModal
                show={changeEmail}
                onClose={() => setChangeEmail(false)}
                imageUrl='/assets/images/notification-empty.svg'
                title='Provide New Email Address'
                leftButtonLabel='Cancel'
                onClickLeftButton={() => setChangeEmail(false)}
                onClickRightButton={closeEmail}
                rightButtonLabel='Next'
                labelName='Email'
                placeholder='Enter your email'
                imageClassName='sm-img-box'
            />

            <ChangeEmailSentModal
                show={changeEmailSent}
                onClose={() => setChangeEmailSent(false)}
                imageUrl='/assets/images/email-icon.svg'
                title='Email with Instructions Sent to Your Email'
                confirmButtonLabel='Next'
                onConfirm={closeEmailSent}
            />

            <ChangeEmailModal
                show={changeEmailNew}
                onClose={() => setChangeEmailNew(false)}
                imageUrl='/assets/images/email-change.svg'
                title='Change Email'
                leftButtonLabel='Cancel'
                onClickLeftButton={() => setChangeEmailNew(false)}
                rightButtonLabel='Save Changes'
                labelName='Your new email'
                placeholder='Enter your new email'
                imageClassName='email-sent-image'
            />

            <ChangePasswordModal
                show={changePassword}
                onClose={() => setChangePassword(false)}
                imageUrl='/assets/images/notification-empty.svg'
                title='Change Password'
                imageClassName='email-sent-image'
                onClickCard={closeChangePassword}
                pointerClassName='cursor-pointer'
            />

            <CreateNewPasswordModal show={createNewPassword} onClose={() => navigate('/')} onResetPassword={handleResetPassword} />

            <ConfirmModal
                show={logoutModal}
                onClose={() => setLogoutModal(false)}
                imageUrl='/assets/images/questionmark-icon.svg'
                title='Are You Sure You Want to Log Out?'
                leftButtonLabel='Cancel'
                onClickLeftButton={() => setLogoutModal(false)}
                onClickRightButton={() => handleLogout()}
                rightButtonLabel='Log out'
                className='logout-body'
            />

            <DeleteAccountModal
                show={accountDelete}
                onClose={() => setAccountDelete(false)}
                imageUrl='/assets/images/questionmark-icon.svg'
                title='Delete Your Account?'
                subtitle='This action can not be undone'
                leftButtonLabel='Cancel'
                onClickLeftButton={() => setAccountDelete(false)}
                onClickRightButton={() => handleDeleteAccount()}
                rightButtonLabel='Delete'
            />
        </>
    );
};
