import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IProject } from 'models';

export interface IProjectState {
    project: IProject | null;
}

const initialState: IProjectState = {
    project: null,
};

export const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        setProject: (state, action: PayloadAction<IProject>) => {
            return { ...state, project: action.payload };
        },
    },
});

export const { setProject } = projectSlice.actions;
export const { reducer } = projectSlice;
