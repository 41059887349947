import React, { ReactNode } from 'react';

import { AppBar, Header } from './components';

interface DashboardLayoutProps {
    headerTitle?: string;
    wrapperClassName?: string;
    children: ReactNode;
    headerClassName?: string;
    onClickBackButton?: () => void;
}

export const DashboardLayout = ({ headerTitle, wrapperClassName, headerClassName, onClickBackButton, children }: DashboardLayoutProps) => (
    <div className={`wrapper-main ${wrapperClassName}`}>
        <AppBar />
        <div className='page-layout bg-white-10 min-h100v'>
            <Header title={headerTitle} className={headerClassName} onClickBackButton={onClickBackButton} />
            <div className='layout-page-inner pl16 pr16 pb16'>{children}</div>
        </div>
    </div>
);
