import React, { ChangeEvent } from 'react';
import Accordion from 'react-bootstrap/Accordion';

import { SearchBox } from 'components';

interface PermitsFilterAccordionProps {
    name: string;
    statuses?: { id: string; label: string }[];
    selectedStatuses?: string[];
    title?: string;
    onSearch?: (searchValue: string) => void;
    onCheckboxChange: (name: string, statusId: string, checked: boolean) => void;
}

export const PermitsFilterAccordion = ({
    name,
    statuses,
    selectedStatuses = [],
    onCheckboxChange,
    title,
    onSearch,
}: PermitsFilterAccordionProps) => {
    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name: statusId, checked } = event.target;
        onCheckboxChange(name, statusId, checked);
    };

    const handleSearch = async (data: string) => {
        if (onSearch) {
            onSearch(data);
        }
    };

    return (
        <Accordion className='accordion-custom-light' defaultActiveKey={['search_multi_value', 'permitting_status']} alwaysOpen>
            <Accordion.Item eventKey={name}>
                <Accordion.Header>{title}</Accordion.Header>
                <Accordion.Body>
                    <ul className='filter-listing-check'>
                        <div className='search-filter pb6'>
                            {title === 'Projects' && (
                                <div className='search-filter pb6'>
                                    <SearchBox
                                        placeholder='Search by title'
                                        type='text'
                                        size='sm'
                                        searchClassName='w-100'
                                        onSearch={handleSearch}
                                    />
                                </div>
                            )}
                        </div>
                        {statuses && statuses.length > 0 ? (
                            <>
                                {statuses.map(({ id, label }) => (
                                    <li key={id}>
                                        <div className='cstm-checkbox form-check'>
                                            <input
                                                type='checkbox'
                                                name={id}
                                                id={id}
                                                onChange={handleCheckboxChange}
                                                checked={selectedStatuses.includes(id)}
                                                className='form-check-input'
                                            />
                                            <label className='text-regular-15 text-cool-grey-500 form-check-label' htmlFor={id}>
                                                {label}
                                            </label>
                                        </div>
                                    </li>
                                ))}
                            </>
                        ) : (
                            <div className='text-regular-15 text-cool-grey-500'>No project has been found</div>
                        )}
                    </ul>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};
