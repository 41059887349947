import React from 'react';
import { GroupBase, MultiValueProps, components as ReactSelectComponents } from 'react-select';

import { LevelMoreSelectedBadge } from '..';
import { ISelectOption } from 'models';

export const LevelMultiValue = ({
    index,
    getValue,
    ...otherProps
}: MultiValueProps<ISelectOption<string>, true, GroupBase<ISelectOption<string>>>) => {
    const maxToShow = 1;
    const overflow = getValue()
        .slice(maxToShow)
        .map((x) => x.label);

    if (index < maxToShow) return <ReactSelectComponents.MultiValue index={index} getValue={getValue} {...otherProps} />;

    return <LevelMoreSelectedBadge items={overflow} />;
};
