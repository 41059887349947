import { useGoogleLogin } from '@react-oauth/google';
import axios, { AxiosError } from 'axios';
import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import Stack from 'react-bootstrap/Stack';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { Button, Card, HeaderLogo, Input, LinkButton, StrongPasswordList, IconButton, Checkbox } from 'components';
import { postWithOutAuthMethod } from 'services/api.service';
import { AUTH, baseURL } from 'utils/constants';
import { VerifyEmailModal } from '../Login/components';
import { setEmail } from 'store/email';

import { RegisterInput } from './Register.models';

export const Register = () => {
    const dispatch = useDispatch();
    const [showEmailVerifyModal, setShowEmailVerifyModal] = useState(false);
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isGoogle, setIsGoogle] = useState(false);
    const [registerData, setRegisterData] = useState<RegisterInput>({
        email: '',
        password: '',
        is_accept: false,
    });
    const handleClick = () => setShow(!show);

    const handleRegister = async (values: RegisterInput) => {
        setIsLoading(true);
        try {
            setRegisterData(values);
            const { data } = await postWithOutAuthMethod(AUTH.REGISTER, values);

            if (data?.status === true) {
                setShowEmailVerifyModal(!showEmailVerifyModal);
            }
        } catch (error) {
            if (error instanceof AxiosError && error?.response?.data?.status === false) {
                toast(error?.response?.data?.message);
            } else {
                toast('Something went wrong');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
        password: Yup.string()
            .required('No password provided.')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
                'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
            )
            .min(8, 'Password is too short - should be 8 chars minimum.')
            .max(30, 'Password is too long - should be 30 chars maximum.'),
        is_accept: Yup.boolean().oneOf([true], 'You must agree to the terms and conditions.'),
    });

    const handleGoogleRegister = useGoogleLogin({
        onSuccess: (tokenResponse) => {
            const google_token: string = tokenResponse?.access_token;
            const config = {
                headers: { Authorization: google_token },
            };
            axios
                .get(baseURL + AUTH.GOOGLE_AUTH_REGISTER, config)
                .then((res) => {
                    const data = res?.data;

                    if (data?.status === true) {
                        dispatch(setEmail(data?.user_email));
                        setIsGoogle(true);
                        localStorage.setItem('email', data?.user_email);
                        registerData.email = data?.user_email;
                        setShowEmailVerifyModal(!showEmailVerifyModal);
                    }
                })
                .catch((error) => {
                    if (error instanceof AxiosError && (error.response?.status === 404 || error.response?.status === 400)) {
                        toast(error.response.data.message);
                    } else {
                        toast('Something went wrong');
                    }
                });
        },
    });

    return (
        <>
            <div className='form-wrapper position-relative overflow-hidden'>
                <div className='bottom-shape bg-gradient-primary ' />
                <div className='from-inner-box'>
                    <Stack gap={3} className='min-h100vh p20'>
                        <div className='form-header pb15'>
                            <HeaderLogo />
                        </div>
                        <div className='m-auto inner-form-box'>
                            <Card>
                                <Formik
                                    enableReinitialize
                                    initialValues={registerData}
                                    onSubmit={(values) => handleRegister(values)}
                                    validationSchema={validationSchema}
                                >
                                    {({ values, errors, isValid, handleChange, handleSubmit }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <h3 className='text-bold-28 text-center pb16'>Create an Account</h3>
                                            <div className='inner-form-box-detail'>
                                                <Input
                                                    labelName='Email'
                                                    placeholder='Enter your email'
                                                    type='email'
                                                    size='md'
                                                    value={values.email}
                                                    onChange={handleChange('email')}
                                                    isInvalid={!!errors.email}
                                                    errorMsg={errors?.email}
                                                />
                                                <div className='eye-icon-filled'>
                                                    <Input
                                                        labelName='Password'
                                                        placeholder='Create a password'
                                                        type={show ? 'text' : 'password'}
                                                        size='md'
                                                        value={values.password}
                                                        onChange={handleChange('password')}
                                                        isInvalid={!!errors.password}
                                                        errorMsg={errors?.password}
                                                    />
                                                    <IconButton className='password-icon' onClick={handleClick}>
                                                        <i className={!show ? 'fi fi-rr-eye-crossed' : 'fi fi-rr-eye'} />
                                                    </IconButton>
                                                </div>
                                                <StrongPasswordList password={values.password} />

                                                <div className='term-agree pt8'>
                                                    <Checkbox
                                                        id='terms-checkbox'
                                                        label={
                                                            <>
                                                                I agree with the
                                                                <Link
                                                                    to='#'
                                                                    className='text-bold-15 text-decoration-none text-primary-link ml1 mr1'
                                                                >
                                                                    Terms
                                                                </Link>
                                                                and
                                                                <Link
                                                                    to='#'
                                                                    className='text-bold-15 text-decoration-none text-primary-link ml1'
                                                                >
                                                                    Privacy Policy
                                                                </Link>
                                                            </>
                                                        }
                                                        defaultChecked={false}
                                                        onChange={handleChange('is_accept')}
                                                    />
                                                </div>

                                                <div className='btn-list pt15'>
                                                    <Button
                                                        type='submit'
                                                        variant='primary'
                                                        btnFullClassName='w-100'
                                                        size='lg'
                                                        className='mb8'
                                                        disabled={isValid ? '' : 'disabled'}
                                                        spinner={isLoading}
                                                    >
                                                        Next{' '}
                                                        <span className='icon-box icon-box-next ml3'>
                                                            <i className='fi fi-rr-angle-small-right' />
                                                        </span>
                                                    </Button>
                                                    <Button
                                                        variant='outline-primary'
                                                        btnFullClassName='w-100'
                                                        size='lg'
                                                        className='mb8'
                                                        onClick={() => handleGoogleRegister()}
                                                    >
                                                        <span className='icon-box mr4'>
                                                            <img src='/assets/images/google-icon.svg' alt='google-icon' />
                                                        </span>
                                                        Continue with Google
                                                    </Button>
                                                    <div className='account-login'>
                                                        <p className='text-regular-16 text-cool-grey-800 text-center'>
                                                            Already have an account?
                                                            <LinkButton linkTo='/' className='ml2'>
                                                                Login
                                                            </LinkButton>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </Card>
                        </div>
                    </Stack>
                </div>
            </div>
            {showEmailVerifyModal && (
                <VerifyEmailModal onClose={() => setShowEmailVerifyModal(false)} loginData={registerData} isGoogle={isGoogle} />
            )}
        </>
    );
};
