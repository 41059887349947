import Accordion from 'react-bootstrap/Accordion';
import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { IconLinkButton, InfoModal } from 'components';
import { IProjectDetail } from 'models';
import { MapPropertyModal } from '../MapPropertyModal/MapPropertyModal';

interface ProjectDetailsAccordionProps {
    projectDetails?: IProjectDetail;
}

export const ProjectDetailsAccordion = ({ projectDetails }: ProjectDetailsAccordionProps) => {
    const [infoModal, setInfoModal] = useState(false);
    const [propertyMap, setPropertyMap] = useState(false);

    const handleClose = () => {
        setPropertyMap(false);
    };

    return (
        <>
            <Accordion className='accordion-custom-light project-details-accord' defaultActiveKey={['0', '1']} alwaysOpen>
                <div className='project-popup-inner'>
                    <Accordion.Item eventKey='0'>
                        <div className='project-popup-label d-flex flex-wrap align-items-center justify-content-between gap-3'>
                            <h4 className='text-semibold-16 text-cool-grey-800 '>Location</h4>
                            <IconLinkButton
                                className='filter-btn ms-auto view-map-btn'
                                onClick={() => setPropertyMap(true)}
                                disabled={projectDetails?.is_demo ? 'disabled' : ''}
                            >
                                View on Map
                                <span className='icon-sm pl4'>
                                    <i className='fi fi-rr-map-marker' />
                                </span>
                            </IconLinkButton>
                            <Accordion.Header />
                        </div>

                        <Accordion.Body>
                            <div className='project-popup-body'>
                                <Row>
                                    <Col xs='12' md='6' lg='4'>
                                        <div className='project-popup-detail'>
                                            <div className='label-name text-regular-14 text-cool-grey-500'>State</div>
                                            <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                {projectDetails?.location?.state || projectDetails?.state},{' '}
                                                {projectDetails?.location?.country || projectDetails?.country}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs='12' md='6' lg='4'>
                                        <div className='project-popup-detail'>
                                            <div className='label-name text-regular-14 text-cool-grey-500'>City / Municipality</div>
                                            <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                {projectDetails?.location?.city || projectDetails?.city}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs='12' md='6' lg='4'>
                                        <div className='project-popup-detail'>
                                            <div className='label-name text-regular-14 text-cool-grey-500'>Zip Code</div>
                                            <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                {projectDetails?.location?.zip_code}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs='12' md='6' lg='4'>
                                        <div className='project-popup-detail'>
                                            <div className='label-name text-regular-14 text-cool-grey-500'>Address</div>
                                            <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                {projectDetails?.address}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <hr className='h-border-top' />
                </div>

                <div className='project-popup-inner'>
                    <Accordion.Item eventKey='1'>
                        <div className='project-popup-label d-flex flex-wrap align-items-center justify-content-between gap-3'>
                            <h4 className='text-semibold-16 text-cool-grey-800'>Project details</h4>
                            <Accordion.Header />
                        </div>

                        <Accordion.Body>
                            <div className='project-popup-body'>
                                <Row>
                                    <Col xs='12' md='6' lg='4'>
                                        <div className='project-popup-detail'>
                                            <div className='label-name text-regular-14 text-cool-grey-500'>Customer name</div>
                                            <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                {projectDetails?.customer_name}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs='12' md='6' lg='4'>
                                        <div className='project-popup-detail'>
                                            <div className='label-name text-regular-14 text-cool-grey-500'>Contact email</div>
                                            <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                {projectDetails?.contact_email}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs='12' md='6' lg='4'>
                                        <div className='project-popup-detail'>
                                            <div className='label-name text-regular-14 text-cool-grey-500'>Contact phone</div>
                                            <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                {projectDetails?.contact_phone}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs='12' md='6' lg='4'>
                                        <div className='project-popup-detail'>
                                            <div className='label-name text-regular-14 text-cool-grey-500'>Customer address</div>
                                            <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                {projectDetails?.address}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs='12' md='6' lg='4'>
                                        <div className='project-popup-detail'>
                                            <div className='label-name text-regular-14 text-cool-grey-500'>Project type</div>
                                            <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                {projectDetails?.technology}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs='12' md='6' lg='4'>
                                        <div className='project-popup-detail'>
                                            <div className='label-name text-regular-14 text-cool-grey-500'>Status</div>
                                            <div className='project-popup-content text-regular-15 text-cool-grey-800'>Active</div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <hr className='h-border-top' />
                </div>
            </Accordion>

            <InfoModal
                show={infoModal}
                onClose={() => setInfoModal(false)}
                modalTitle='Zip Code'
                title='A ZIP Code is a postal code used by the United States Postal Service (USPS) and is an acronym for Zone Improvement Plan. The first five digits indicate the destination post office or delivery area. The last 4 digits represent specific delivery routes within delivery areas.'
                confirmButtonLabel='Got It'
                confirmButtonClassName='min-w190'
                onConfirm={() => setInfoModal(false)}
            />

            <MapPropertyModal show={propertyMap} locationData={projectDetails?.location} onClose={() => handleClose()} />
        </>
    );
};
