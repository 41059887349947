import React, { ReactNode } from 'react';

interface CardStructureProps {
    className?: string;
    children: ReactNode;
}

export const CardStructure = ({ children, className }: CardStructureProps) => (
    <div className={`card-structure ${className}`}>{children}</div>
);
