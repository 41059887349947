import React, { MouseEvent } from 'react';

import { CardStructure, ProjectCardHeader, ProjectCardBody } from 'components';

interface ProjectCardProps {
    projectName?: string;
    scope?: string;
    onClickDelete?: (e: MouseEvent<HTMLButtonElement>) => void;
    onClickEdit?: (e: MouseEvent<HTMLButtonElement>) => void;
    overallStatus?: string;
    permittingStatus: string;
    incentivesStatus: string;
    overallColor: string;
    permittingColor: string;
    incentivesColor: string;
    isDemo?: boolean;
}

export const ProjectCard = ({
    projectName,
    scope,
    onClickDelete,
    onClickEdit,
    overallStatus,
    permittingStatus,
    incentivesStatus,
    overallColor,
    permittingColor,
    incentivesColor,
    isDemo,
}: ProjectCardProps) => (
    <CardStructure className='card-project-group'>
        <div className='project-card-bx'>
            <ProjectCardHeader
                projectName={projectName}
                scope={scope}
                onClickDelete={onClickDelete}
                onClickEdit={onClickEdit}
                isDemo={isDemo}
            />
            <ProjectCardBody
                overallStatus={overallStatus}
                overallColor={overallColor}
                permittingStatus={permittingStatus}
                permittingColor={permittingColor}
                incentivesStatus={incentivesStatus}
                incentivesColor={incentivesColor}
            />
        </div>
    </CardStructure>
);
