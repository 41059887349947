import React, { useMemo } from 'react';

interface OtpInputProps {
    value: string;
    valueLength: number;
    onChange: (value: string) => void;
}

const RE_DIGIT = new RegExp(/^\d+$/);

export const OtpInput = ({ value, valueLength, onChange }: OtpInputProps) => {
    const valueItems = useMemo(() => {
        const valueArray = value.split('');
        const items: Array<string> = [];

        for (let i = 0; i < valueLength; i++) {
            const char = valueArray[i];

            if (RE_DIGIT.test(char)) {
                items.push(char);
            } else {
                items.push('');
            }
        }

        return items;
    }, [value, valueLength]);

    const inputOnChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
        const { target } = e;
        let targetValue = target.value.trim();
        const isTargetValueDigit = RE_DIGIT.test(targetValue);

        if (!isTargetValueDigit && targetValue !== '') {
            return;
        }

        targetValue = isTargetValueDigit ? targetValue : ' ';

        const targetValueLength = targetValue.length;

        if (targetValueLength === 1) {
            const newValue = value.substring(0, idx) + targetValue + value.substring(idx + 1);

            onChange(newValue);

            if (!isTargetValueDigit) {
                return;
            }

            const nextElementSibling = target.nextElementSibling as HTMLInputElement | null;

            if (nextElementSibling) {
                nextElementSibling.focus();
            }
        } else if (targetValueLength === valueLength) {
            onChange(targetValue);

            target.blur();
        }
    };

    const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        const targetValue = target.value;
        target.setSelectionRange(0, targetValue.length);

        if (e.key !== 'Backspace' || target.value !== '') {
            return;
        }

        const previousElementSibling = target.previousElementSibling as HTMLInputElement | null;

        if (previousElementSibling) {
            previousElementSibling.focus();
        }
    };

    const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        const { target } = e;

        target.setSelectionRange(0, target.value.length);
    };

    return (
        <>
            {valueItems.map((digit, idx) => (
                <input
                    key={idx}
                    type='text'
                    inputMode='numeric'
                    autoComplete='one-time-code'
                    pattern='\d{1}'
                    maxLength={valueLength}
                    className='lg form-control'
                    value={digit}
                    onChange={(e) => inputOnChange(e, idx)}
                    onKeyDown={inputOnKeyDown}
                    onFocus={inputOnFocus}
                />
            ))}
        </>
    );
};
