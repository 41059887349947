import React, { ReactNode } from 'react';
import BootstrapModal from 'react-bootstrap/Modal';

import { IconButton } from 'components/IconButton/IconButton';

interface ModalProps {
    show: boolean;
    onClose?: () => void;
    className?: string;
    title?: string;
    children: ReactNode;
    size?: 'sm' | 'lg' | 'xl';
    backdrop?: 'static' | true;
    scrollable?: boolean;
}

export const Modal = ({ show, onClose, className, title, children, size, backdrop = true, scrollable = true }: ModalProps) => (
    <BootstrapModal
        backdrop={backdrop}
        show={show}
        size={size}
        onHide={onClose}
        centered
        className={`custom-modal ${className}`}
        scrollable={scrollable}
    >
        {title && (
            <BootstrapModal.Header className='pb-4'>
                <div className='verify-email-box text-center w-100'>
                    <div className='text-bold-18 text-cool-grey-800  w-100 text-center headline'>{title}</div>
                </div>
                <IconButton size='sm' className='close-icon' onClick={onClose}>
                    <i className='fi fi-rr-cross-small' />
                </IconButton>
            </BootstrapModal.Header>
        )}

        {children}
    </BootstrapModal>
);
