import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IDashboardFilter } from 'models';

export interface IDashboardFilterState {
    filter: IDashboardFilter | null;
}

const initialState: IDashboardFilterState = {
    filter: null,
};

export const dashboardFilterSlice = createSlice({
    name: 'dashboardFilter',
    initialState,
    reducers: {
        setFilter: (state, action: PayloadAction<IDashboardFilter>) => {
            return { ...state, filter: action.payload };
        },
    },
});

export const { setFilter } = dashboardFilterSlice.actions;
export const { reducer } = dashboardFilterSlice;
