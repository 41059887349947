import React, { useState, useMemo } from 'react';
import OffCanvas from 'react-bootstrap/Offcanvas';

import { FilterAccordion } from '..';
import { Button, IconLinkButton } from 'components';
import { DashboardInfo } from 'pages/Dashboard/Dashboard.models';

interface FilterDrawerProps {
    dashboardInfo: DashboardInfo;
    onApplyFilter: (filterValue: { [key: string]: string[] }) => void;
}

export const FilterDrawer = ({ dashboardInfo, onApplyFilter }: FilterDrawerProps) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectedStatus, setSelectedStatus] = useState<{
        [key: string]: string[];
    }>({});

    const handleCheckboxChange = (title: string, id: string, isChecked: boolean) => {
        setSelectedStatus((prevSelectedStatus) => ({
            ...prevSelectedStatus,
            [title]: isChecked ? [...prevSelectedStatus[title], id] : prevSelectedStatus[title].filter((v) => v !== id),
        }));
    };

    const clearAll = () => {
        setSelectedStatus({});
    };

    const statusCheckboxes = useMemo(
        () =>
            ['technology', 'state'].map((value) => ({
                title: value,
                titleName: value.toUpperCase(),
                statuses: (value === 'technology' ? dashboardInfo.technologies : dashboardInfo.states_list).map((v) => ({
                    id: v,
                    label: v,
                })),
            })),
        [dashboardInfo.states_list, dashboardInfo.technologies],
    );

    return (
        <>
            <IconLinkButton className='filter-btn' onClick={handleShow}>
                Filter
                <span className='icon-sm pl4'>
                    <i className='fi fi-rr-filter' />
                </span>
            </IconLinkButton>

            <OffCanvas show={show} onHide={handleClose} placement='end' className='filter-drawer-card'>
                <OffCanvas.Header>
                    <OffCanvas.Title>Property Filters</OffCanvas.Title>
                </OffCanvas.Header>
                <OffCanvas.Body>
                    <div className='filter-listing-box'>
                        {statusCheckboxes.map(({ title, statuses, titleName }) => (
                            <FilterAccordion
                                key={title}
                                title={title}
                                statuses={statuses}
                                selectedStatuses={selectedStatus[title]}
                                onCheckboxChange={handleCheckboxChange}
                                titleName={titleName}
                            />
                        ))}
                    </div>
                </OffCanvas.Body>
                <div className='filter-footer text-center'>
                    <IconLinkButton
                        className=' mb10'
                        onClick={() => clearAll()}
                        disabled={Object.keys(selectedStatus).length === 0 ? 'disabled' : ''}
                    >
                        Clear All
                        <span className='icon-sm pl4'>
                            <i className='fi fi-rr-cross-small' />
                        </span>
                    </IconLinkButton>
                    <Button
                        variant='primary'
                        btnFullClassName='w-100'
                        size='sm'
                        onClick={() => onApplyFilter(selectedStatus)}
                        disabled={Object.keys(selectedStatus).length === 0 ? 'disabled' : ''}
                    >
                        Apply
                    </Button>
                </div>
            </OffCanvas>
        </>
    );
};
