import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ILocation, IProjectLocation } from 'models';

export interface ILocationState {
    location: IProjectLocation | null;
}

const initialState: ILocationState = {
    location: null,
};

export const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        setLocation: (state, action: PayloadAction<ILocation>) => {
            return { ...state, location: action.payload };
        },
    },
});

export const { setLocation } = locationSlice.actions;
export const { reducer } = locationSlice;
