import React from 'react';

interface LevelMoreSelectedBadgeProps {
    items: string[];
}

export const LevelMoreSelectedBadge = ({ items }: LevelMoreSelectedBadgeProps) => {
    const style = {
        border: '1px solid #e5e7eb',
        fontSize: '12px',
        fontWeight: '700',
        padding: '3px 3px  3px 6px',
        borderRadius: '30px',
        backgroundColor: '#fff',
        height: '26px',
        lineHeight: '24px',
        color: '#6b7280',
    };

    const title = items.join(', ');
    const { length } = items;
    const label = `+ ${length} ...`;

    return (
        <div style={style} title={title}>
            {label}
        </div>
    );
};
