import React, { useCallback, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { Button, CurrentIncentiveCard, CardStructure, ExplorerMap, PermitsTable, ProjectsTable } from 'components';
import { getMethod } from 'services/api.service';
import { DASHBOARD } from 'utils/constants';
import { DashboardLayout } from 'layouts';
import { IPermit, IProject } from 'models';
import { selectSearchTerm } from 'store/selectors';
import { setResultCount } from 'store/search';
import { setFilter } from 'store/dashboardFilter';

import { IncentiveClaimsTable, PendingProjectCard, SearchProperties } from './components';
import { DashboardInfo } from './Dashboard.models';

export const Dashboard = () => {
    const [dashboardInfo, setDashboardInfo] = useState<DashboardInfo>();
    const [permitLists, setPermitLists] = useState<IPermit[]>([]);
    const [changePreview, setChangePreview] = useState<boolean>(false);
    const [projectListing, setProjectListing] = useState<IProject[]>([]);
    const searchTerm = useSelector(selectSearchTerm);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const getDashboardInfo = useCallback(
        async (params?: string) => {
            let url: string;

            if (params) {
                setChangePreview(true);
                url = `${DASHBOARD.DASHBOARD_INFO}?search_project=${params}`;
            } else {
                setChangePreview(false);
                url = DASHBOARD.DASHBOARD_INFO;
            }
            try {
                const { data } = await getMethod(url);

                if (data?.status) {
                    setProjectListing(data?.data?.projects);
                    setDashboardInfo(data?.data);
                    setPermitLists(data?.data?.permit_data);
                    dispatch(setResultCount(data?.number_of_projects));
                }
            } catch (error) {
                if (error instanceof AxiosError && error?.response?.data?.status === false) {
                    toast(error?.response?.data?.message);
                }
            }
        },
        [dispatch],
    );

    const handleApply = (filterValue: { [key: string]: string[] }) => {
        dispatch(setFilter(filterValue));
        navigate('/explorer-view');
    };

    useEffect(() => {
        if (searchTerm) {
            getDashboardInfo(searchTerm);
        }
    }, [getDashboardInfo, searchTerm]);

    return (
        <DashboardLayout>
            {!changePreview ? (
                <>
                    <div className='header-titlebar-main pt4'>
                        <div className='header-titlebar-outer d-flex flex-wrap align-items-center gap-3 justify-content-between'>
                            <div className='title-name-header flex-grow-1 text-bold-20 text-cool-grey-800'>Dashboard</div>
                            <div className='right-btn-header max-w240'>
                                <Button variant='primary' size='sm' className='w-100' onClick={() => navigate('/create-project')}>
                                    <span className='btn-icon mr5'>
                                        <i className='fi fi-rr-plus' />
                                    </span>
                                    Create New Project
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className='card-info-outer pt12'>
                        <Row className='row-gap-3'>
                            <Col xs={12} sm={4} lg={4}>
                                <CardStructure className='card-incentive-outer'>
                                    <CurrentIncentiveCard
                                        icon={<i className='fi fi-rr-list' />}
                                        title='Ongoing Projects'
                                        value={dashboardInfo?.in_progress_projects}
                                    />
                                </CardStructure>
                            </Col>
                            <Col xs={12} sm={4} lg={4}>
                                <CardStructure className='card-incentive-outer'>
                                    <CurrentIncentiveCard
                                        icon={<i className='fi fi-rr-document' />}
                                        title='Pending Permits'
                                        value={dashboardInfo?.pending_permits}
                                    />
                                </CardStructure>
                            </Col>
                            <Col xs={12} sm={4} lg={4}>
                                <CardStructure className='card-incentive-outer'>
                                    <CurrentIncentiveCard
                                        icon={<i className='fi fi-rr-receipt' />}
                                        title='Active Incentive Applications'
                                        value='0'
                                    />
                                </CardStructure>
                            </Col>
                        </Row>
                    </div>

                    <div className='pending-project-main pt12'>
                        <Row className='row-gap-3'>
                            <Col xs={12} md='12' lg='6'>
                                <CardStructure>
                                    <PendingProjectCard title='Active Permit Applications' linkTo='/permits'>
                                        {' '}
                                        View All
                                    </PendingProjectCard>
                                    {permitLists ? <PermitsTable /> : ''}
                                </CardStructure>
                            </Col>
                            <Col xs={12} md='12' lg='6'>
                                <CardStructure>
                                    <PendingProjectCard title='Pending Incentive Claims' linkTo='/incentives'>
                                        {' '}
                                        View All
                                    </PendingProjectCard>
                                    <IncentiveClaimsTable />
                                </CardStructure>
                            </Col>
                        </Row>
                    </div>
                    <div className='search-property-main pt12 d-none'>
                        <CardStructure className='card-spacing-24'>
                            {dashboardInfo && <SearchProperties dashboardInfo={dashboardInfo} onApplyFilter={handleApply} />}
                            <ExplorerMap isSearch={false} />
                        </CardStructure>
                    </div>
                </>
            ) : (
                <div className='latest-permits-table pt4'>
                    {projectListing?.length > 0 ? (
                        <CardStructure>
                            <ProjectsTable projectsList={projectListing} />
                        </CardStructure>
                    ) : (
                        <div className='no-data-search d-flex flex-column justify-content-center align-items-center'>
                            <div className='no-data-inner'>
                                <img src='/assets/images/no-data.svg' alt='no-data' />
                                <p className='text-bold-20 text-cool-grey-800 pt5'>No Data Found</p>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </DashboardLayout>
    );
};
