import React, { ChangeEvent } from 'react';
import { FormCheck, FormLabel } from 'react-bootstrap';

interface ToggleProps {
    id: string;
    name?: string;
    label: string;
    value?: boolean;
    reverse?: boolean;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Toggle = ({ id, name, label, value, reverse, onChange }: ToggleProps) =>
    reverse ? (
        <div className='switch-container'>
            <FormLabel htmlFor={id}>{label}</FormLabel>
            <FormCheck reverse={reverse} type='switch' id={id} name={name} checked={value} onChange={onChange} />
        </div>
    ) : (
        <div>
            <FormLabel htmlFor={id}>{label}</FormLabel>
            <FormCheck reverse={reverse} type='switch' id={id} name={name} checked={value} onChange={onChange} />
        </div>
    );
