import React, { useCallback, useMemo, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { DashboardLayout } from 'layouts';
import { Loader, Pagination, SearchBox } from 'components';
import { postMethod } from 'services/api.service';
import { customStyles } from 'utils/commonFunctions';
import { PROJECTS } from 'utils/constants';
import { IProject } from 'models';

import { FilterProjectsDrawer, ProjectTabs } from './components';
import { ProjectFilterBody } from './Projects.model';

const SORT_BY_OPTIONS = [
    { value: 'all', label: 'All' },
    { value: 'a-z', label: 'A to Z' },
    { value: 'z-a', label: 'Z to A' },
];

const NavPills = [
    { value: 'All', eventKey: 'All' },
    { value: 'Drafts', eventKey: 'Draft' },
    { value: 'In progress', eventKey: 'In_Progress' },
    { value: 'Done', eventKey: 'Done' },
];

const initialFilter = {
    sort: 'all',
    overall_status: [],
    permit_status: [],
    search: '',
    page: 1,
    tab_status: 'All',
};

export const Projects = () => {
    const [projectsList, setProjectsList] = useState<IProject[]>([]);
    const [numItems, setNumItems] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filter, setFilter] = useState<ProjectFilterBody>(initialFilter);

    const getProjectsList = useCallback(async (filter?: ProjectFilterBody) => {
        try {
            const { data } = await postMethod(PROJECTS.PROJECTS_LISTS, filter);

            if (data?.status) {
                setProjectsList(data?.data);
                setNumItems(data?.number_of_projects);
                setTotalPages(Math.ceil(data?.number_of_projects / 10));
                setIsLoading(false);
            }
        } catch (error) {
            if (error instanceof AxiosError && error?.response?.data?.status === false) {
                setIsLoading(false);
                toast(error?.response?.data?.message);
            }
        }
    }, []);

    const handleChangeFilter = useCallback(
        (value: Partial<ProjectFilterBody>) => {
            const newFilter = {
                ...filter,
                ...value,
            };
            setFilter(newFilter);

            getProjectsList(newFilter);
        },
        [filter, getProjectsList],
    );

    const updateProjectList = (deleteValue: boolean) => {
        if (deleteValue === true) {
            setNumItems(numItems - 1);
        }
    };

    useMemo(() => {
        getProjectsList(initialFilter);
    }, [getProjectsList]);

    return (
        <DashboardLayout headerTitle='Latest Projects'>
            <div className='header-titlebar-main project-main-outer pt4'>
                <div className='custom-border-tab'>
                    <Tab.Container id='left-tabs-example' defaultActiveKey='All'>
                        <div className='header-titlebar-outer d-flex flex-wrap align-items-center gap-3 justify-content-between projects-top-bar'>
                            <div className='title-name-header flex-grow-1'>
                                <div className='tab-header'>
                                    <Nav variant='pills' className=''>
                                        {NavPills?.map((item: { value: string; eventKey: string }, index: number) => (
                                            <React.Fragment key={index}>
                                                <Nav.Item>
                                                    <Nav.Link
                                                        eventKey={item.eventKey}
                                                        onClick={() => {
                                                            handleChangeFilter({ tab_status: item.eventKey });
                                                        }}
                                                    >
                                                        {item.value}
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </React.Fragment>
                                        ))}
                                    </Nav>
                                </div>
                            </div>
                            <div className='right-select projects-select'>
                                <div className='custom_form mb7'>
                                    <Select
                                        onChange={(e) => {
                                            handleChangeFilter({ sort: e?.value });
                                        }}
                                        options={SORT_BY_OPTIONS}
                                        className='custom-select-box select-borderless'
                                        placeholder='Sort by'
                                        styles={customStyles}
                                    />
                                </div>
                            </div>
                            <div className='filter-box'>
                                {filter.tab_status !== 'Draft' ? <FilterProjectsDrawer onApplyFilter={handleChangeFilter} /> : ''}
                            </div>
                            <div className='right-btn-header search-wth'>
                                <SearchBox
                                    placeholder='Search by project...'
                                    type='text'
                                    size='sm'
                                    searchClassName='max-w350'
                                    onSearch={(keyword) =>
                                        handleChangeFilter({
                                            search: keyword,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        {!isLoading ? (
                            <div className='project-center-main'>
                                <>
                                    {projectsList.length !== 0 ? (
                                        <>
                                            <ProjectTabs projects={projectsList} onUpdateProjectList={updateProjectList} />
                                            <div className='pagination-project d-flex flex-wrap align-items-center justify-content-between pt7'>
                                                <div className='left-pagination-text'>
                                                    <p className='text-regular-14 text-cool-grey-800'>
                                                        Showing {projectsList?.length} projects of {numItems}
                                                    </p>
                                                </div>
                                                <div className='left-pagination-right'>
                                                    {numItems > 10 ? (
                                                        <Pagination
                                                            totalItems={numItems}
                                                            totalPages={totalPages}
                                                            onPageChange={(page) => handleChangeFilter({ page })}
                                                        />
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className='no-data-search d-flex flex-column justify-content-center align-items-center'>
                                                <div className='no-data-inner'>
                                                    <img src='/assets/images/no-data.svg' alt='no-data' />
                                                    <p className='text-bold-20 text-cool-grey-800 pt5'>No Data Found</p>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            </div>
                        ) : (
                            <Loader />
                        )}
                    </Tab.Container>
                </div>
            </div>
        </DashboardLayout>
    );
};
