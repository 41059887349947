import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'App.css';
import 'style/global.scss';
import 'style/button.scss';
import 'style/dashboard.scss';
import 'style/form.scss';
import 'style/input.scss';
import 'style/appbar.scss';
import 'style/modal.scss';
import 'style/chip.scss';
import 'style/header.scss';
import 'style/search.scss';
import 'style/filter.scss';
import 'style/messenger.scss';
import 'style/pagination.scss';
import 'style/projectcard.scss';
import 'style/stepsHeader.scss';
import 'style/tab.scss';
import 'style/map.scss';
import 'style/table.scss';
import 'style/mobilehome.scss';
import { AppRoutes } from 'routing/AppRoutes';

export function App() {
    return (
        <>
            <AppRoutes />
            <ToastContainer position='top-right' autoClose={1000} hideProgressBar newestOnTop={false} rtl={false} theme='dark' />
        </>
    );
}
