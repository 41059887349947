import Form from 'react-bootstrap/Form';
import React, { ChangeEventHandler } from 'react';

interface RadioProps {
    onChange?: ChangeEventHandler<HTMLInputElement>;
    labelName: string;
    checkId: string;
    htmlFor: string;
    name: string;
    value?: string | number | string[];
    checked?: boolean;
}

export const Radio = ({ onChange, labelName, checkId, htmlFor, name, value, checked }: RadioProps) => (
    <Form.Check aria-label='option 1' className='cstm-radio' type='radio'>
        <Form.Check.Input id={checkId} type='radio' name={name} value={value} onChange={onChange} checked={checked} />
        <Form.Check.Label htmlFor={htmlFor} className='text-regular-15 text-cool-grey-500'>
            {labelName}
        </Form.Check.Label>
    </Form.Check>
);
