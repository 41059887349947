import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { Button, ConfirmModal, IconLinkButton } from 'components';
import { ICreateProject } from 'pages/CreateProjectStepTwo/CreateProjectStepTwo.models';
import { putMethod } from 'services/api.service';
import { PROJECTS } from 'utils/constants';
import { CreateProjectModal, SaveDraftModal } from 'pages/CreateProject/components';

interface EditProjectStepTwoFooterProps {
    projectId?: string;
    isEdit: boolean;
    values: ICreateProject;
}

export const EditProjectStepTwoFooter = ({ projectId, isEdit, values }: EditProjectStepTwoFooterProps) => {
    const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
    const [showSaveDraftModal, setShowSaveDraftModal] = useState(false);
    const [showSavedDraftModal, setShowSavedDraftModal] = useState(false);
    const navigate = useNavigate();

    const handleCreateProjectAsDraft = useCallback(async () => {
        const inputPhoneNumber = values.contact_phone || '';
        const numericPhoneNumber = inputPhoneNumber.replace(/\D/g, '');
        const countryCode = '+';
        const formattedPhoneNumber = numericPhoneNumber.startsWith(countryCode) ? numericPhoneNumber : countryCode + numericPhoneNumber;

        const projectData = {
            ...values,
            is_draft: true,
            contact_phone: formattedPhoneNumber,
        };

        try {
            const { data } = await putMethod(PROJECTS.PROJECT, projectData);

            if (data.status === true) {
                setShowSaveDraftModal(false);
                setShowSavedDraftModal(true);
            }
        } catch (error) {
            if (error instanceof AxiosError && error?.response?.data?.status === false) {
                toast(error?.response?.data?.message);
            }
        }
    }, [values]);

    const handleUpdateProject = useCallback(async () => {
        const inputPhoneNumber = values.contact_phone || '';
        const numericPhoneNumber = inputPhoneNumber.replace(/\D/g, '');
        const countryCode = '+';
        const formattedPhoneNumber = numericPhoneNumber.startsWith(countryCode) ? numericPhoneNumber : countryCode + numericPhoneNumber;

        const projectData = {
            ...values,
            contact_phone: formattedPhoneNumber,
            is_draft: false,
        };

        try {
            const { data } = await putMethod(PROJECTS.PROJECT, projectData);

            if (data.status === true) {
                toast(data?.message);
                navigate(`/project-info/${projectId}`);
            }
        } catch (error) {
            if (error instanceof AxiosError && error?.response?.data?.status === false) {
                toast(error?.response?.data?.message);
            }
        }
    }, [navigate, projectId, values]);

    return (
        <div className='footer-project pt12 d-flex align-items-center gap-3'>
            <Button variant='outline-primary' size='sm' onClick={() => navigate(-1)}>
                Back
            </Button>
            {isEdit ? (
                <>
                    <IconLinkButton className='filter-btn ms-auto' onClick={() => setShowSaveDraftModal(true)}>
                        Save as Draft
                        <span className='icon-sm pl4'>
                            <i className='fi fi-rr-cloud-upload' />
                        </span>
                    </IconLinkButton>
                    <Button variant='primary' size='sm' className='w-100 max-w200' onClick={() => handleUpdateProject()}>
                        Save Edits
                    </Button>
                </>
            ) : (
                <>
                    <IconLinkButton className='filter-btn ms-auto' onClick={() => setShowSaveDraftModal(true)}>
                        Save as Draft
                        <span className='icon-sm pl4'>
                            <i className='fi fi-rr-cloud-upload' />
                        </span>
                    </IconLinkButton>
                    <Button variant='primary' size='sm' className='w-100 max-w200' onClick={() => setShowCreateProjectModal(true)}>
                        Create Project
                    </Button>
                </>
            )}

            <ConfirmModal
                show={showSaveDraftModal}
                onClickLeftButton={() => setShowSaveDraftModal(false)}
                imageUrl='/assets/images/save-draft.svg'
                title='Save this Project as Draft?'
                leftButtonLabel='Cancel'
                rightButtonLabel='Save as Draft'
                onClickRightButton={() => handleCreateProjectAsDraft()}
            />

            <SaveDraftModal
                show={showSavedDraftModal}
                onClose={() => {
                    navigate('/projects');
                }}
                imageUrl='/assets/images/save-drafticon.svg'
                title='Project Saved as Draft'
            />

            {showCreateProjectModal && (
                <CreateProjectModal onClose={() => setShowCreateProjectModal(false)} projectInfo={values} updateProjectStatus />
            )}
        </div>
    );
};
