import { useEffect, useState } from 'react';

interface UseTooltipTimeoutProps {
    delay?: number; // Optional delay, default is 3000ms
}

export const useTooltipTimeout = ({ delay }: UseTooltipTimeoutProps = { delay: 3000 }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (show) {
            timer = setTimeout(() => {
                setShow(false);
            }, delay);
        }

        return () => clearTimeout(timer);
    }, [show, delay]);

    return [show, setShow] as const;
};
