import { ICreatePermit } from 'pages/NewPermit/NewPermit.models';

export type PermitEligibilityQuestionnaireKey = keyof Omit<ICreatePermit['property_eligibility'], 'census_tract_code'>;

export const PERMIT_ELIGIBILITY_QUESTIONNAIRE = new Map<PermitEligibilityQuestionnaireKey, string>([
    [
        'is_within_median_income_area',
        'Do 66% of property residents have incomes at or below 80% of the area median income as determined by the Department of Housing and Community Development?',
    ],
    [
        'is_disadvantaged_community',
        'Is this property located in a disadvantaged community as identified by the California Environmental Protection Agency?',
    ],
    [
        'is_owned_by_a_tribe',
        'Is this property owned by a tribe (pursuant to Senate Bill (SB) 355, “tribe” means a California Native American tribe as defined in Section 21073 of the Public Resources Code)?',
    ],
    [
        'is_rental_housing',
        'Is this property a rental housing property that is owned by one of the following: (i) A public housing authority created pursuant to the Housing Authorities Law OR (ii) A public housing agency, as defined in Section 1437a of Title 42 of the United States Code?',
    ],
    ['has_certificate_of_occupancy', 'Has the Certificate of Occupancy for the property been received?'],
    ['has_solar_construction_started', 'Has construction for the solar system at the project site started?'],
    ['was_referral_to_SOMAH_program', 'Was your project a formal referral to the SOMAH program?'],
]);

export const initialValues: ICreatePermit = {
    name: 'CPUC Solar on Multifamily Affordable Housing Program (SOMAH)',
    project_id: 0,
    host_customer: {
        date: '',
        name: '',
        company: '',
        address: '',
        email: '',
        phone: '',
    },
    project_site: {
        name: '',
        total_units: 0,
        address: '',
        ownership_type: 'FOR_PROFIT',
    },
    property_eligibility: {
        is_within_median_income_area: false,
        is_disadvantaged_community: false,
        is_owned_by_a_tribe: false,
        is_rental_housing: false,
        census_tract_code: '',
        has_certificate_of_occupancy: false,
        has_solar_construction_started: false,
        was_referral_to_SOMAH_program: false,
    },
    electrical_utility: {
        utility: 'PG_AND_E',
        does_receive_electric_service_from_CCA: false,
        responsible_for_paying_electric_services: 'PROPERTY_OWNER',
        meters_of_common_areas: 0,
    },
    gas_utility: {
        utility: 'PG_AND_E',
    },
};
