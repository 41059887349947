import React, { useCallback, useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { DashboardLayout } from 'layouts';
import { getMethod } from 'services/api.service';
import { PROJECTS } from 'utils/constants';
import { IPermit, IProjectDetail } from 'models';

import { IncentiveClaimsTabs, PermitsTab, ProjectDetailsTab, ProjectTimelineTab } from './components';

const mockPermits = [
    {
        id: 999,
        name: 'Environmental Permit',
        is_demo: true,
        permit_status: 'In Review',
    },
    {
        id: 998,
        is_demo: true,
        name: 'Structural Permit',
        permit_status: 'In Review',
    },
    {
        id: 997,
        is_demo: true,
        name: 'Technical Permit',
        permit_status: 'Approved',
    },
    {
        id: 996,
        is_demo: true,
        name: 'Electrical Permit',
        permit_status: 'Not Started',
    },
    {
        id: 995,
        is_demo: true,
        name: 'Interconnection',
        permit_status: 'Not Started',
    },
] as IPermit[];

export const ProjectInfo = () => {
    const { projectId } = useParams<{ projectId: string }>();
    const [projectDetails, setProjectDetails] = useState<IProjectDetail>();
    const [projectPermitList, setProjectPermitList] = useState<IPermit[]>(mockPermits);
    const navigate = useNavigate();

    const getProjectDetails = useCallback(
        async (sort?: string) => {
            let url = '';

            if (sort !== undefined) {
                url = `${PROJECTS.PROJECT}?id=${projectId}&sort=${sort}`;
            } else {
                url = `${PROJECTS.PROJECT}?id=${projectId}&sort=all`;
            }
            try {
                const { data } = await getMethod(url);

                if (data?.status) {
                    setProjectDetails(data?.data);

                    if (data.permits_data) setProjectPermitList((prev) => [...data.permits_data, ...prev]);
                }
            } catch (error) {
                if (error instanceof AxiosError && error?.response?.data?.status === false) {
                    toast(error?.response?.data?.message);
                }
            }
        },
        [projectId],
    );

    const handlePermitSorting = (sortValue: string) => {
        getProjectDetails(sortValue);
    };

    useEffect(() => {
        getProjectDetails();
    }, [getProjectDetails]);

    return (
        <DashboardLayout headerTitle='Project Info' onClickBackButton={() => navigate(-1)}>
            <div className='explorer-main pt4 project-info-main'>
                <div className='custom-border-tab'>
                    <Tab.Container defaultActiveKey='first'>
                        <div className='tab-header'>
                            <Nav variant='pills' className=''>
                                <Nav.Item>
                                    <Nav.Link eventKey='first'>Project Details</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey='second'>Project Timeline</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey='third'>
                                        Permits <span className='count-bx'>{projectPermitList.length}</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey='fourth'>Incentive Claims</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <div className='tab-content pt12'>
                            <Tab.Content>
                                <Tab.Pane eventKey='first' className='project-details'>
                                    <ProjectDetailsTab projectDetails={projectDetails} />
                                </Tab.Pane>
                                <Tab.Pane eventKey='second' className='project-timeline'>
                                    <ProjectTimelineTab />
                                </Tab.Pane>
                                <Tab.Pane eventKey='third' className='project-permits'>
                                    <PermitsTab permitListing={projectPermitList} onPermitSorting={handlePermitSorting} />
                                </Tab.Pane>
                                <Tab.Pane eventKey='fourth' className='project-incentive-claims'>
                                    <IncentiveClaimsTabs />
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </Tab.Container>
                </div>
            </div>
        </DashboardLayout>
    );
};
