import React, { DetailedHTMLProps, HTMLAttributes } from 'react';

interface LevelCheckboxOptionProps {
    innerProps: Partial<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>>;
    label: string;
    isSelected: boolean;
}

export const LevelCheckboxOption = ({ innerProps, label, isSelected }: LevelCheckboxOptionProps) => (
    <div
        className='cstm-checkbox form-check select-check'
        {...innerProps}
        style={{ display: 'flex', alignItems: 'center', marginLeft: '12px' }}
    >
        <input className='form-check-input' type='checkbox' checked={isSelected} onChange={() => null} />
        <label className='text-regular-14 text-cool-grey-500 form-check-label'>{label}</label>
    </div>
);
