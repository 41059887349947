import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Markdown from 'react-markdown';
import { AxiosError } from 'axios';

import { getMethod, postMethod } from 'services/api.service';
import { CHATBOT } from 'utils/constants';
import { CardStructure, IconButton } from 'components';
import { IRegulation } from 'pages/ExplorerView/models';

interface IChatMessageAttachment {
    name: string;
    content: File;
}

interface ChatMessage {
    message: string;
    attachments?: IChatMessageAttachment[];
    isUser: boolean;
}

interface MessengerProps {
    className?: string;
    regulations?: IRegulation[];
}

export const Messenger = ({ className, regulations = [] }: MessengerProps) => {
    const [message, setMessage] = useState<string>('');
    const [previousMessages, setPreviousMessages] = useState<ChatMessage[]>([]);
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const [chatLoader, setChatLoader] = useState<boolean>(false);

    useEffect(() => {
        const getMessages = async () => {
            setChatLoader(true);

            try {
                const messages = await getMethod(CHATBOT.CHAT);

                setPreviousMessages(messages.data.messages?.length ? messages.data.messages : []);
            } catch (error) {
                if (error instanceof AxiosError && error?.response?.data?.status === false) {
                    toast(error?.response?.data?.message);
                }
            } finally {
                setChatLoader(false);
            }
        };

        getMessages();
    }, [setPreviousMessages]);

    const handleSendMessage = useCallback(async () => {
        if (message.length === 0) {
            toast('Message field cannot be empty.');

            return;
        }

        try {
            const userMessage = message;
            setMessage('');
            setChatLoader(true);
            setPreviousMessages((prevChat) => [...prevChat, { message: userMessage, attachments: [], isUser: true }]);
            const { data } = await postMethod(CHATBOT.CHAT, {
                message: userMessage,
                regulation_ids: regulations.map((regulation) => regulation.id),
            });
            setPreviousMessages((prevChat) => [...prevChat, { message: data?.message, isUser: false, attachments: data?.attachments }]);
        } catch (error) {
            if (error instanceof AxiosError && error?.response?.data?.status === false) {
                toast(error?.response?.data?.message);
            }
        } finally {
            setChatLoader(false);
        }
    }, [message, regulations]);

    const handleChangeMessage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(event.target.value);
    }, []);

    const handleKeyDownMessage = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                handleSendMessage();
            }
        },
        [handleSendMessage],
    );

    const handleDownloadAttachment = useCallback((attachment: IChatMessageAttachment) => {
        const excelFile = new File([attachment.content], `${attachment.name}.csv`, { type: 'text/csv' });
        const excelBlob = new Blob([excelFile], { type: 'text/csv' });
        const url = window.URL.createObjectURL(excelBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${attachment.name}.csv`;
        a.click();
    }, []);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [previousMessages]);

    return (
        <>
            <div className={`messenger-card ${className}`}>
                <CardStructure className='messenger-chat-box'>
                    <div className='messenger-heading pb6'>
                        <h4 className='text-semibold-16 text-cool-grey-800' style={{ fontSize: '14px' }}>
                            Ask 1Climate
                        </h4>
                    </div>
                    <div
                        className={
                            previousMessages.length > 0 ? 'messenger-body no-messenger-body d-none' : 'messenger-body no-messenger-body'
                        }
                    >
                        <div className='no-message text-center'>
                            <div className='img-box pb5'>
                                <img src='/assets/images/message-box-icon.svg' alt='message-icon' />
                            </div>
                            <p className='text-cool-grey-800 text-regular-12'>Ask a question about any regulation or incentive!</p>
                        </div>
                    </div>
                    {previousMessages?.length > 0 ? (
                        <div ref={chatContainerRef} className='messenger-body messenger-body-chat mt-auto'>
                            {previousMessages.map((messages, index) => (
                                <div key={index} className={`messenger-sender d-flex ${messages.isUser ? '' : 'regulatory-steps'}`}>
                                    <div className='messenger-avatar'>
                                        {messages?.isUser ? (
                                            <span className='messenger-avatar-initial'>B</span>
                                        ) : (
                                            <img src='/assets/images/avtar-icon.png' alt='message-icon' />
                                        )}
                                    </div>
                                    <div className='message-detail'>
                                        <p>
                                            <Markdown>{messages?.message}</Markdown>
                                        </p>
                                        {messages?.attachments?.map((attachment, index) => (
                                            <div
                                                className='csv-download-btn mt-2'
                                                key={index}
                                                onClick={() => handleDownloadAttachment(attachment)}
                                            >
                                                <i className='fi fi-rr-download' />
                                                <span>{attachment.name}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        ''
                    )}
                    <div className='messenger-bottom'>
                        {chatLoader ? (
                            <div className='jumping-dots-parent'>
                                <span className='jumping-dots'>
                                    <span className='dot-1' />
                                    <span className='dot-2' />
                                    <span className='dot-3' />
                                </span>
                            </div>
                        ) : (
                            ''
                        )}
                        <div className='chat-message-field'>
                            <div className='custom_form mb7 rounded-filed'>
                                <div className='search-outer position-relative'>
                                    <Form.Control
                                        className='xs'
                                        type='text'
                                        placeholder='Ask me about regulations or incentives'
                                        value={message}
                                        onChange={handleChangeMessage}
                                        onKeyDown={handleKeyDownMessage}
                                    />
                                    <IconButton
                                        size='sm'
                                        className='send-message-btn right-search-icon fill-icon send-message-sm'
                                        onClick={handleSendMessage}
                                    >
                                        <i className='fi fi-rr-paper-plane' />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardStructure>
            </div>
        </>
    );
};
