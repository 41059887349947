import { useGoogleLogin } from '@react-oauth/google';
import axios, { AxiosError } from 'axios';
import { Formik, Form } from 'formik';
import React, { useState, useEffect } from 'react';
import Stack from 'react-bootstrap/Stack';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { Button, LinkButton, Input, Card, HeaderLogo, Checkbox, IconButton } from 'components';
import { postWithOutAuthMethod } from 'services/api.service';
import { AUTH, baseURL } from 'utils/constants';
import { setEmail } from 'store/email';

import { OtpPasswordModal, VerifyEmailModal } from './components';
import { LoginInput } from './Login.models';

export const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showOtpVerifyModal, setShowOtpVerifyModal] = useState(false);
    const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false);
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [googleIsLoading, setGoogleIsLoading] = useState(false);
    const [isGoogle, setIsGoogle] = useState(false);
    const [checked, setChecked] = useState(false);

    const [loginData, setLoginData] = useState<LoginInput>({
        email: '',
        password: '',
        remember_me: false,
    });

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
        password: Yup.string().required('No password provided.'),
        remember_me: Yup.boolean(),
    });

    const handleClick = () => setShow(!show);

    const handleLogin = async (values: LoginInput) => {
        setIsLoading(true);
        setLoginData(values);

        try {
            let reqData;

            if (sessionStorage.getItem('email') !== values.email) {
                reqData = { ...values, remember_me: false };
            } else {
                reqData = { ...values };
            }

            const { data } = await postWithOutAuthMethod(AUTH.LOGIN, reqData);

            if (data?.status === true && !data?.access) {
                setShowOtpVerifyModal(!showOtpVerifyModal);
                setIsLoading(false);
            } else {
                setIsLoading(false);
                localStorage.setItem('token', data?.access);
                dispatch(setEmail(data?.user_email));
                localStorage.setItem('email', data?.user_email);
                navigate('/dashboard');
            }
        } catch (error) {
            if (error instanceof AxiosError && error?.response?.data?.status === false) {
                toast(error?.response?.data?.message);
                setIsLoading(false);
            } else {
                toast('Something went wrong');
            }
        }
    };

    useEffect(() => {
        const email = sessionStorage.getItem('email');

        if (loginData.email === email) {
            setChecked(true);
            setLoginData({ ...loginData, remember_me: true });
        }
    }, [loginData]);

    const handleGoogleLogin = useGoogleLogin({
        onSuccess: (tokenResponse) => {
            const google_token: string = tokenResponse?.access_token;
            const config = {
                headers: { Authorization: google_token },
            };
            setGoogleIsLoading(true);

            axios
                .get(baseURL + AUTH.GOOGLE_AUTH_LOGIN, config)
                .then((res) => {
                    const { data } = res;

                    if (data?.status === true) {
                        setIsGoogle(true);
                        setShowOtpVerifyModal(!showOtpVerifyModal);
                        dispatch(setEmail(data?.user_email));
                        localStorage.setItem('email', data?.user_email);
                        setLoginData({ ...loginData, email: data.user_email });
                    }
                })
                .catch((err) => {
                    toast(err?.response?.data?.message);
                })
                .finally(() => {
                    setGoogleIsLoading(false);
                });
        },
    });

    return (
        <>
            <div className='form-wrapper position-relative overflow-hidden'>
                <div className='bottom-shape bg-gradient-primary' />
                <div className='from-inner-box'>
                    <Stack gap={3} className='min-h100vh p20'>
                        <div className='form-header pb15'>
                            <HeaderLogo />
                        </div>
                        <div className='m-auto inner-form-box'>
                            <Card>
                                <Formik
                                    enableReinitialize
                                    initialValues={loginData}
                                    onSubmit={(values) => handleLogin(values)}
                                    validationSchema={validationSchema}
                                >
                                    {({ values, errors, isValid, handleChange, handleSubmit }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <h3 className='text-bold-28 text-center pb16'>Login to your account</h3>
                                            <div className='inner-form-box-detail'>
                                                <Input
                                                    labelName='Email'
                                                    placeholder='Enter your email'
                                                    type='email'
                                                    size='md'
                                                    value={values.email}
                                                    onChange={handleChange('email')}
                                                    isInvalid={!!errors.email}
                                                    errorMsg={errors?.email}
                                                />
                                                <div className='eye-icon-filled'>
                                                    <Input
                                                        labelName='Password'
                                                        placeholder='Enter your password'
                                                        type={show ? 'text' : 'password'}
                                                        size='md'
                                                        value={values.password}
                                                        onChange={handleChange('password')}
                                                        isInvalid={!!errors.password}
                                                        errorMsg={errors?.password}
                                                    />
                                                    <IconButton className='password-icon' onClick={handleClick}>
                                                        <i className={!show ? 'fi fi-rr-eye-crossed' : 'fi fi-rr-eye'} />
                                                    </IconButton>
                                                </div>
                                                <div className='remember-me d-flex align-items-center justify-content-between gap-5 '>
                                                    <div className='left-box'>
                                                        <Checkbox
                                                            id='check-box'
                                                            label='Remember me'
                                                            defaultChecked={checked}
                                                            onChange={handleChange('remember_me')}
                                                        />
                                                    </div>
                                                    <div className='right-box'>
                                                        <LinkButton linkTo='/forgot-password'>Forgot password?</LinkButton>
                                                    </div>
                                                </div>
                                                <div className='btn-list pt15'>
                                                    <Button
                                                        type='submit'
                                                        variant='primary'
                                                        btnFullClassName='w-100'
                                                        size='lg'
                                                        className='mb8'
                                                        disabled={isValid ? '' : 'disabled'}
                                                        spinner={isLoading}
                                                    >
                                                        Login
                                                    </Button>
                                                    <Button
                                                        variant='outline-primary'
                                                        btnFullClassName='w-100'
                                                        size='lg'
                                                        className='mb8 d-none'
                                                        onClick={() => handleGoogleLogin()}
                                                        spinner={googleIsLoading}
                                                    >
                                                        <span className='icon-box mr4'>
                                                            <img src='/assets/images/google-icon.svg' alt='google-icon' />
                                                        </span>
                                                        Continue with Google
                                                    </Button>
                                                    <div className='account-login d-none'>
                                                        <p className='text-regular-16 text-cool-grey-800 text-center'>
                                                            Do not have an account?
                                                            <LinkButton linkTo='/sign-up' className='ml2'>
                                                                Sign Up
                                                            </LinkButton>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </Card>
                        </div>
                    </Stack>
                </div>
            </div>

            {showOtpVerifyModal && (
                <OtpPasswordModal
                    onClose={() => setShowOtpVerifyModal(false)}
                    email={loginData.email}
                    onConfirm={() => {
                        setShowOtpVerifyModal(false);
                        setShowVerifyEmailModal(true);
                    }}
                />
            )}
            {showVerifyEmailModal && (
                <VerifyEmailModal isGoogle={isGoogle} loginData={loginData} onClose={() => setShowVerifyEmailModal(false)} />
            )}
        </>
    );
};
