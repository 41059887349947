import Stack from 'react-bootstrap/Stack';
import React from 'react';

import { DashboardInfo } from 'pages/Dashboard/Dashboard.models';
import { FilterDrawer } from '../FilterDrawer/FilterDrawer';

interface SearchPropertiesProps {
    dashboardInfo: DashboardInfo;
    onApplyFilter: (filterValue: { [key: string]: string[] }) => void;
}

export const SearchProperties = ({ dashboardInfo, onApplyFilter }: SearchPropertiesProps) => (
    <div className='search-main-box'>
        <div className='search-prop-header'>
            <Stack direction='horizontal' gap={3} className='w-100 flex-wrap'>
                <div className='search-prop-left flex-grow-1'>
                    <h4 className='text-semibold-18 text-cool-grey-800'>Search Properties</h4>
                </div>
                <div className='no-result ms-auto'>
                    <p className='text-regular-14 text-cool-grey-800'>0 results</p>
                </div>
                <div className='right-filter'>
                    <FilterDrawer dashboardInfo={dashboardInfo} onApplyFilter={onApplyFilter} />
                </div>
            </Stack>
        </div>
    </div>
);
