import React, { ReactNode } from 'react';

interface CurrentIncentiveCardProps {
    className?: string;
    icon: ReactNode;
    title: string;
    value: number | string | undefined;
}

export const CurrentIncentiveCard = ({ className, icon, title, value }: CurrentIncentiveCardProps) => (
    <div className={`current-incentive-box flex-column d-flex gap-2 ${className}`}>
        <div className='current-header d-flex align-items-center'>
            <span className='icon-box-current'>{icon}</span>
            <span className='header-title text-regular-16 text-cool-grey-500 pl4'>{title}</span>
        </div>
        <div className='current-body-box mt-auto'>
            <h4 className='text-semibold-28 text-cool-grey-800'>{value}</h4>
        </div>
    </div>
);
