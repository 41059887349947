import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, IconButton, Notifications, SearchBox } from 'components';
import { setSearchTerm } from 'store/search';

interface HeaderProps {
    className?: string;
    title?: string;
    onClickBackButton?: () => void;
}

export const Header = ({ className, title, onClickBackButton }: HeaderProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const path = window?.location?.pathname;

    const visibleSearch = useMemo(() => {
        const arr = ['/dashboard', '/permits', '/search-filed'];

        return arr.includes(path);
    }, [path]);

    const hideBackButton = useMemo(() => {
        const arr = ['/explorer-view', '/profile', '/projects'];

        return arr.includes(path);
    }, [path]);

    const showCreateBtn = useMemo(() => {
        const arr = ['/projects'];

        return arr.includes(path);
    }, [path]);

    const handleSearch = (data: string) => {
        dispatch(setSearchTerm(data));
    };

    return (
        <div className={`layout-inner-wrap ${className}`}>
            <div className='d-flex align-items-center justify-content-between gap-2'>
                <div className='left-box'>
                    {visibleSearch && (
                        <SearchBox
                            placeholder='Search by project...'
                            type='text'
                            size='sm'
                            searchClassName='max-w350'
                            searchContainerClassName='custom-result'
                            onSearch={handleSearch}
                        />
                    )}
                    <div className={`header-title-main d-flex flex-wrap align-items-center gap-2 ${visibleSearch && 'd-none'}`}>
                        <div className={`btn-back-header ${hideBackButton && 'd-none'}`}>
                            <IconButton onClick={onClickBackButton} className='back-btn-icon'>
                                <i className='fi fi-rr-angle-left' />
                            </IconButton>
                        </div>
                        {title && (
                            <div className='heading-main'>
                                <div className='title-name-header text-bold-20 text-cool-grey-800'>{title}</div>
                            </div>
                        )}
                    </div>
                </div>
                <div className={`right-btn-header max-w240 header-create-btn ${showCreateBtn && 'd-block'}`}>
                    <Button variant='primary' size='sm' className='w-100' onClick={() => navigate('/create-project')}>
                        <span className='btn-icon mr5'>
                            <i className='fi fi-rr-plus' />
                        </span>
                        Create New Project
                    </Button>
                </div>
                <div className='right-box'>
                    <Notifications />
                </div>
            </div>
        </div>
    );
};
