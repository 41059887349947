import { combineReducers } from '@reduxjs/toolkit';

import { reducer as permitReducer } from './permit';
import { reducer as locationReducer } from './location';
import { reducer as dashboardFilterReducer } from './dashboardFilter';
import { reducer as emailReducer } from './email';
import { reducer as notificationReducer } from './notification';
import { reducer as projectReducer } from './project';
import { reducer as searchReducer } from './search';

export const rootReducer = combineReducers({
    permit: permitReducer,
    location: locationReducer,
    dashboardFilter: dashboardFilterReducer,
    email: emailReducer,
    notification: notificationReducer,
    project: projectReducer,
    search: searchReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
