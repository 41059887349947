import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { deleteMethod } from 'services/api.service';
import { PROJECTS } from 'utils/constants';
import { TextChip, IconButton, DeleteAccountModal } from 'components';
import { IProject } from 'models';
import { decreaseResultCount } from 'store/search';

interface ProjectsTableProp {
    projectsList?: IProject[];
    onUpdateProjectsList?: (isDeleteValue: boolean) => void;
}

export const ProjectsTable = ({ projectsList = [], onUpdateProjectsList }: ProjectsTableProp) => {
    const [projectId, setProjectId] = useState<number>(0);
    const [projectListing, setProjectListing] = useState<IProject[]>([]);
    const [deletePermits, setDeletePermits] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const deleteProject = (id: number) => {
        setProjectId(id);
        setDeletePermits(true);
    };

    const handleDelete = async () => {
        try {
            const { data } = await deleteMethod(`${PROJECTS.PROJECT}?id=${projectId}`);

            if (data.status === true) {
                const indexToRemove = projectListing.findIndex((project) => project.id === projectId);

                if (indexToRemove !== -1) {
                    projectListing.splice(indexToRemove, 1);
                }
                onUpdateProjectsList?.(true);
                dispatch(decreaseResultCount());
                setDeletePermits(false);
                toast(data?.message);
            }
        } catch (error) {
            if (error instanceof AxiosError && error?.response?.data?.status === false) {
                toast(error?.response?.data?.message);
            }
        }
    };

    const handleEditRoute = (projectId: number) => {
        navigate(`/edit-project/${projectId}`);
    };

    useEffect(() => {
        setProjectListing(projectsList);
    }, [projectsList]);

    return (
        <>
            <Table responsive className='custom-table table-project-permits table-search-filled'>
                <thead>
                    <tr>
                        <th className='id-number max-w100'>
                            N<sup>o</sup>{' '}
                        </th>
                        <th className='min-w150'>Project Name</th>
                        <th className='min-w150'>Scope</th>
                        <th className='status-th w-120'>Overall</th>
                        <th className='status-th w-120'>Permitting</th>
                        <th className='status-th w-120'>Incentives</th>
                        <th className='action-th w-90 text-center'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {projectListing?.map((project) => (
                        <React.Fragment key={project?.id}>
                            <tr onClick={() => navigate(`/project-info/${project?.id}`)} className='cursor-pointer'>
                                <td>
                                    <div className='table-content id-num-th'>
                                        <p>{project?.id}</p>
                                    </div>
                                </td>
                                <td>
                                    <div className='table-content'>
                                        <p>{project?.name}</p>
                                    </div>
                                </td>
                                <td>
                                    <div className='table-content'>
                                        <p>{project?.technology}</p>
                                    </div>
                                </td>
                                <td>
                                    <TextChip
                                        bgColor={
                                            project?.overall_status === 'Active'
                                                ? 'blue'
                                                : project?.overall_status === 'Draft'
                                                  ? 'grey'
                                                  : 'grey'
                                        }
                                        className='badge-xs'
                                    >
                                        {project?.overall_status}
                                    </TextChip>
                                </td>
                                <td>
                                    <TextChip bgColor='yellow' className='badge-xs'>
                                        In Progress
                                    </TextChip>
                                </td>
                                <td>
                                    <TextChip bgColor='green' className='badge-xs'>
                                        Done
                                    </TextChip>
                                </td>
                                <td className='action-button text-center'>
                                    <IconButton
                                        size='sm'
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            deleteProject(project?.id);
                                        }}
                                    >
                                        <i className='fi fi-rr-trash' />
                                    </IconButton>
                                    <IconButton
                                        size='sm'
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleEditRoute(project?.id);
                                        }}
                                    >
                                        <i className='fi fi-rr-edit' />
                                    </IconButton>
                                </td>
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </Table>

            <DeleteAccountModal
                show={deletePermits}
                onClose={() => setDeletePermits(false)}
                imageUrl='/assets/images/questionmark-icon.svg'
                title='Delete this Project?'
                subtitle='This action can not be undone'
                leftButtonLabel='Cancel'
                onClickLeftButton={() => setDeletePermits(false)}
                onClickRightButton={handleDelete}
                rightButtonLabel='Delete'
            />
        </>
    );
};
