import React, { ReactNode } from 'react';
import BootstrapButton from 'react-bootstrap/Button';

interface ButtonProps {
    className?: string;
    disabled?: string;
    btnFullClassName?: string;
    size?: 'sm' | 'lg';
    variant?: string;
    children?: ReactNode;
    spinner?: boolean;
    type?: 'submit' | 'reset' | 'button';
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const Button = ({ className, btnFullClassName, disabled, size, variant, children, type, spinner, onClick }: ButtonProps) => (
    <BootstrapButton
        className={`cstm-btn ${className} ${btnFullClassName} ${disabled}`}
        size={size || 'sm'}
        variant={variant || 'primary'}
        onClick={onClick}
        type={type}
    >
        {spinner ? (
            <div className='loader-inner spin-loader'>
                <img src='/assets/images/loader-sm.png' alt='spinner' />
            </div>
        ) : (
            children
        )}
    </BootstrapButton>
);
