import React, { useState, useEffect } from 'react';
import Badge from 'react-bootstrap/Badge';
import OffCanvas from 'react-bootstrap/Offcanvas';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { PERMITS } from 'utils/constants';
import { Button, PermitsFilterAccordion, IconLinkButton } from 'components';
import { postMethod } from 'services/api.service';

const statusCheckboxes = [
    {
        name: 'search_multi_value',
        statuses: [],
        title: 'Projects',
    },
    {
        name: 'permitting_status',
        statuses: [
            { id: 'Not Started', label: 'Not Started' },
            { id: 'In Review', label: 'In Review' },
            { id: 'Pending', label: 'Pending' },
            { id: 'Approved', label: 'Approved' },
        ],
        title: 'Permit Status',
    },
];

// TODO: Define better type for onApplyFilter input params later
interface PermitsFilterDrawerProps {
    onApplyFilter?: (filterValue: { [key: string]: string[] }) => void;
}

export const PermitsFilterDrawer = ({ onApplyFilter }: PermitsFilterDrawerProps) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectedStatus, setSelectedStatus] = useState<{
        [key: string]: string[];
    }>({});
    const getProjectListing = async (data: string) => {
        const projectName = data;
        try {
            const { data } = await postMethod(PERMITS.GET_PERMIT_PROJECT_DETAILS, {
                search: projectName,
            });

            if (data?.status === true) {
                statusCheckboxes.map((status) => {
                    if (status?.title === 'search_multi_value') {
                        if (data.data.length > 0) {
                            status.statuses = data?.data.map((project: string) => {
                                return {
                                    id: project,
                                    label: project,
                                };
                            });
                        } else {
                            status.statuses = [];
                        }
                    }

                    return statusCheckboxes;
                });
            }
        } catch (error) {
            if (error instanceof AxiosError && error?.response?.data?.status === false) {
                toast(error?.response?.data?.message);
            }
        }
    };

    const handleCheckboxChange = (title: string, id: string, isChecked: boolean) => {
        setSelectedStatus((prevSelectedStatus) => {
            const updatedStatus = { ...prevSelectedStatus };

            if (isChecked) {
                updatedStatus[title] = [...(updatedStatus[title] || []), id];
            } else {
                updatedStatus[title] = (updatedStatus[title] || []).filter((selectedId) => selectedId !== id);
            }

            return updatedStatus;
        });
    };

    const handleRemoveChip = (title: string, id: string) => {
        setSelectedStatus((prevSelectedStatus) => {
            const updatedStatus = { ...prevSelectedStatus };
            updatedStatus[title] = (updatedStatus[title] || []).filter((selectedId) => selectedId !== id);

            return updatedStatus;
        });
    };

    const applyFilter = () => {
        setShow(false);

        if (Object.keys(selectedStatus).length === 0) {
            onApplyFilter &&
                onApplyFilter({
                    permitting_status: [],
                    search_multi_value: [],
                });
        } else {
            onApplyFilter && onApplyFilter(selectedStatus);
        }
    };

    const clearAll = () => {
        setSelectedStatus({});
        onApplyFilter &&
            onApplyFilter({
                permitting_status: [],
                search_multi_value: [],
            });
    };

    useEffect(() => {
        getProjectListing('');
    }, []);

    return (
        <>
            <IconLinkButton className='filter-btn' onClick={handleShow}>
                <span className='icon-sm'>
                    <i className='fi fi-rr-filter' />
                </span>
            </IconLinkButton>
            <OffCanvas show={show} onHide={handleClose} placement='end' className='filter-drawer-card projects-filter-sidebar'>
                <OffCanvas.Header>
                    <div className='filter-header-bar d-flex align-items-center justify-content-between gap-2 flex-wrap'>
                        <OffCanvas.Title>Filters</OffCanvas.Title>
                        <IconLinkButton onClick={() => clearAll()} disabled={Object.keys(selectedStatus).length === 0 ? 'disabled' : ''}>
                            Clear All
                            <span className='icon-sm pl4'>
                                <i className='fi fi-rr-cross-small' />
                            </span>
                        </IconLinkButton>
                    </div>
                    <div className='filter-chip-list chip-tag-list pt8'>
                        <div className='regulation-list-chip chips-scroll'>
                            <div className='chip-tag-list'>
                                {Object.entries(selectedStatus).map(([title, chips], index) => (
                                    <React.Fragment key={index}>
                                        {chips.map((chip, chipIndex) => (
                                            <div className='custom-chip' key={chipIndex}>
                                                <Badge pill bg='primary'>
                                                    <span className='chip_title text-trunk'>{chip}</span>
                                                    <button className='btn-cross' onClick={() => handleRemoveChip(title, chip)}>
                                                        <i className='fi fi-rr-cross-small' />
                                                    </button>
                                                </Badge>
                                            </div>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                </OffCanvas.Header>
                <OffCanvas.Body>
                    <div className='filter-listing-box'>
                        {statusCheckboxes.map(({ name, statuses, title }, index: number) => (
                            <PermitsFilterAccordion
                                key={index}
                                name={name}
                                title={title}
                                statuses={statuses}
                                selectedStatuses={selectedStatus[name] || []}
                                onCheckboxChange={handleCheckboxChange}
                            />
                        ))}
                    </div>
                </OffCanvas.Body>
                <div className='filter-footer text-center'>
                    <Button
                        variant='primary'
                        btnFullClassName='w-100'
                        size='sm'
                        onClick={applyFilter}
                        disabled={Object.keys(selectedStatus).length === 0 ? 'disabled' : ''}
                    >
                        Apply
                    </Button>
                </div>
            </OffCanvas>
        </>
    );
};
