import React, { useEffect } from 'react';

import { Modal, ModalBody, IconButton, ExplorerMap } from 'components';
import { ILocation } from 'models';

interface MapPropertyModalProps {
    show: boolean;
    onClose?: () => void;
    locationData?: ILocation;
}

export const MapPropertyModal = ({ show, onClose, locationData }: MapPropertyModalProps) => {
    useEffect(() => {
        if (!show) {
            localStorage.removeItem('locationValue');
        }
    }, [show]);

    return (
        <Modal show={show} onClose={onClose} className='modal-wrapper modal-info property-map-modal' size='xl'>
            <ModalBody>
                <div className='property-map-inner'>
                    <div className='property-map-header d-flex flex-wrap gap-3'>
                        <div className='prop-map-text'>
                            <h3 className='text-bold-20 text-cool-grey-800'>Property on Map View</h3>
                        </div>
                        <div className='right-close-btn'>
                            <IconButton size='sm' className='close-icon' onClick={onClose}>
                                <i className='fi fi-rr-cross-small' />
                            </IconButton>
                        </div>
                    </div>
                    <div className='property-map-frame pt12'>
                        <div className='map-image-frame position-relative'>
                            <ExplorerMap isSearch={false} locationData={locationData} fullScreenView={false} />
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};
