import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface ISearchState {
    searchTerm: string | null;
    count: number;
}

const initialState: ISearchState = {
    searchTerm: null,
    count: 0,
};

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setSearchTerm: (state, action: PayloadAction<string>) => {
            state.searchTerm = action.payload;
        },
        setResultCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        decreaseResultCount: (state) => {
            state.count -= 1;
        },
    },
});

export const { setSearchTerm, setResultCount, decreaseResultCount } = searchSlice.actions;
export const { reducer } = searchSlice;
