import React, { ReactNode } from 'react';
import BootstrapModal from 'react-bootstrap/Modal';

interface ModalFooterProps {
    children: ReactNode;
    className?: string;
}

export const ModalFooter = ({ children, className }: ModalFooterProps) => (
    <BootstrapModal.Footer className={className}>{children}</BootstrapModal.Footer>
);
