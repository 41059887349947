import React, { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface PublicRouteProps {
    children: ReactNode;
}

export const PublicRoute = ({ children }: PublicRouteProps) => {
    const location = useLocation();

    const token = localStorage.getItem('token');

    const isAuthenticated = token !== null;

    if (isAuthenticated) {
        if (location.pathname.includes('/change-password')) {
            localStorage.setItem('password-token', location?.pathname);

            return <Navigate to='/profile' state={{ from: location }} />;
        }

        return <Navigate to='/dashboard' state={{ from: location }} />;
    }

    return children;
};
