/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig } from 'axios';

import { baseURL } from '../utils/constants';
import httpClient from '../utils/interceptor';

//   GET METHOD
export const getMethod = async (url: string, config?: AxiosRequestConfig) => {
    const result = await httpClient.get(url, config);

    return result;
};

// POST METHOD
export const postMethod = async (url: string, values: any) => {
    const result = await httpClient.post(url, values);

    return result;
};

export const postMethodReadFile = async (url: string, values: any, res: any) => {
    const result = await httpClient.post(url, values, res);

    return result;
};

//   UPDATE METHOD
export const putMethod = async (url: string, values?: any) => {
    const result = await httpClient.put(url, values);

    return result;
};

//   DELETE METHOD
export const deleteMethod = async (url: string) => {
    const result = await httpClient.delete(url);

    return result;
};

// without auto mehtods

export const postWithOutAuthMethod = async (url: string, values: any) => {
    const result = await axios.post(baseURL + url, values);

    return result;
};

export const getWithOutAuthMethod = async (url: string) => {
    const result = await axios.get(baseURL + url);

    return result;
};

export const putWithOutAuthMethod = async (url: string, values: any) => {
    const result = await axios.put(baseURL + url, values);

    return result;
};
