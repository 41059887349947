import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { useDispatch } from 'react-redux';

import { customStyles } from 'utils/commonFunctions';
import { Button, CardStructure } from 'components';
import { ProjectPermitsTable } from '../ProjectPermitsTable/ProjectPermitsTable';
import { IPermit, ISelectOption } from 'models';
import { setPermit } from 'store/permit';

const SORT_BY_OPTIONS: ISelectOption<string>[] = [
    { value: 'all', label: 'All' },
    { value: 'a-z', label: 'A to Z' },
    { value: 'z-a', label: 'Z to A' },
];

interface PermitsTabProps {
    permitListing: IPermit[];
    onPermitSorting?: (sort: string) => void;
}

export const PermitsTab = ({ permitListing, onPermitSorting }: PermitsTabProps) => {
    const { projectId } = useParams<{ projectId: string }>();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleAddNewPermit = () => {
        dispatch(setPermit(null));
        setTimeout(() => navigate(`/new-permit/${projectId}`), 500);
    };

    return (
        <>
            <div className='project-permits-main'>
                <div className='permits-header d-flex flex-wrap align-items-center gap-3'>
                    <div className='permits-heading'>
                        <h4 className='text-bold-18 text-cool-grey-800'>Project Permits</h4>
                    </div>
                    <div className='permits-sort projects-select ms-auto'>
                        <div className='right-select projects-select'>
                            <div className='custom_form mb7'>
                                <Select
                                    onChange={(e) => {
                                        if (onPermitSorting && e) {
                                            onPermitSorting(e.value);
                                        }
                                    }}
                                    options={SORT_BY_OPTIONS}
                                    className='custom-select-box select-borderless'
                                    placeholder='Sort by'
                                    styles={customStyles}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='permits-right-btn max-w240'>
                        <div className='project-cont-btn'>
                            <Button variant='primary' size='sm' className='w-100' onClick={handleAddNewPermit}>
                                Add new Permit
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='permits-table-main pt12'>
                {permitListing.length > 0 ? (
                    <CardStructure>
                        <div className='custom-permits-table'>
                            <ProjectPermitsTable permitListing={permitListing} />
                        </div>
                    </CardStructure>
                ) : (
                    <div className='no-data-search d-flex flex-column justify-content-center align-items-center'>
                        <div className='no-data-inner'>
                            <img src='/assets/images/no-data.svg' alt='no-data' />
                            <p className='text-bold-20 text-cool-grey-800 pt5'>No Permits Found</p>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
