import React, { useState } from 'react';
import Stack from 'react-bootstrap/Stack';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { IconLinkButton, Card, HeaderLogo, LinkButton } from 'components';
import { postWithOutAuthMethod } from 'services/api.service';
import { AUTH } from 'utils/constants';
import { selectEmail } from 'store/selectors';

export const LinkSent = () => {
    const email = useSelector(selectEmail);
    const [emailSent, setEmailSent] = useState(false);

    const handleResendEmail = async () => {
        try {
            const { data } = await postWithOutAuthMethod(AUTH.FORGOT_PASSWORD, email);

            if (data.status === true) {
                setEmailSent(true);
            }
        } catch (error) {
            toast('Something went wrong');
        }
    };

    return (
        <div className='form-wrapper position-relative overflow-hidden'>
            <div className='bottom-shape bg-gradient-primary' />
            <div className='from-inner-box'>
                <Stack gap={3} className='min-h100vh p20'>
                    <div className='form-header pb15'>
                        <HeaderLogo />
                    </div>
                    <div className='m-auto inner-form-box'>
                        <Card>
                            <div className='form-header'>
                                <div className='header-icon-box pb16 text-center'>
                                    <img src='/assets/images/email-icon.svg' alt='email-icon' />
                                </div>
                                <h3 className='text-bold-28 text-center pb5'>Link has been sent</h3>
                                <p className='text-center text-cool-grey-500 text-regular-16 mb-0 max-w280 m-auto'>
                                    A link to reset password has been sent to your email.
                                </p>
                            </div>
                            <div className='inner-form-box-detail'>
                                <div className='btn-list pt15'>
                                    <div className='resend-link text-center pb16'>
                                        <IconLinkButton
                                            className='filter-btn'
                                            onClick={handleResendEmail}
                                            disabled={emailSent ? 'disabled' : ''}
                                        >
                                            {emailSent !== true ? 'Resend email link' : 'Link has been resent'}
                                        </IconLinkButton>
                                    </div>
                                    <div className='account-login'>
                                        <p className='text-regular-16 text-cool-grey-800 text-center'>
                                            Back to
                                            <LinkButton linkTo='/' className='ml2'>
                                                Sign In
                                            </LinkButton>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Stack>
            </div>
        </div>
    );
};
