import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { postMethod, putMethod } from 'services/api.service';
import { CREATE_PROJECT } from 'utils/constants';
import { Button, ConfirmModal, IconLinkButton, Modal, ModalBody, ModalFooter } from 'components';
import { SaveDraftModal } from '../SaveDraftModal/SaveDraftModal';
import { ICreateProject } from 'pages/CreateProjectStepTwo/CreateProjectStepTwo.models';
import { ProjectCardAccordion } from '../ProjectCardAccordion/ProjectCardAccordion';
import { ILocation } from 'models';

interface CreateProjectModalProp {
    modalTitle?: string;
    title?: string;
    projectInfo?: ICreateProject;
    location?: ILocation;
    updateProjectStatus?: boolean;
    onClose: () => void;
}

export const CreateProjectModal = ({ onClose, modalTitle, title, projectInfo, location, updateProjectStatus }: CreateProjectModalProp) => {
    const [saveDraft, setSaveDraft] = useState(false);
    const [createdSuccessfully, setCreatedSuccessfully] = useState(false);
    const [saveDraftIcon, setSaveDraftIcon] = useState(false);
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [titleName, setTitleName] = useState<string>('');
    const closeCreateProject = () => {
        setSaveDraft(true);
        onClose();
    };
    const navigate = useNavigate();
    const createProjectMethod = async (isValid: boolean) => {
        const inputPhoneNumber = projectInfo?.contact_phone;
        const numericPhoneNumber = inputPhoneNumber?.replace(/\D/g, '');
        const countryCode = '+';
        const formattedPhoneNumber = numericPhoneNumber?.startsWith(countryCode) ? numericPhoneNumber : countryCode + numericPhoneNumber;

        const payload = {
            ...projectInfo,
            location,
            is_draft: isValid,
            contact_phone: formattedPhoneNumber,
        };

        setTitleName(isValid ? 'Project Saved as Draft' : 'Project Created Successfully');
        try {
            if (isUpdate) {
                const { data } = await putMethod(CREATE_PROJECT.CREATE_PROJECT, payload);

                if (data?.status === true) {
                    setCreatedSuccessfully(true);
                    localStorage.removeItem('locationValue');
                }
            } else {
                const { data } = await postMethod(CREATE_PROJECT.CREATE_PROJECT, payload);

                if (data?.status === true) {
                    setCreatedSuccessfully(true);
                    localStorage.removeItem('locationValue');
                }
            }
        } catch (error) {
            if (error instanceof AxiosError && error?.response?.data?.status === false) {
                toast(error?.response?.data?.message);
            }
        }
    };

    const closing = (isDraft: boolean) => {
        if (isDraft === true) {
            setCreatedSuccessfully(false);
            navigate('/projects');
        } else {
            setSaveDraftIcon(false);
            navigate('/projects');
        }
    };

    useEffect(() => {
        if (updateProjectStatus) {
            setIsUpdate(updateProjectStatus);
        }
    }, [updateProjectStatus]);

    return (
        <>
            <Modal
                show={!createdSuccessfully && !saveDraft && !saveDraftIcon}
                size='lg'
                onClose={onClose}
                title={modalTitle}
                className='modal-wrapper modal-project'
            >
                <ModalBody>
                    <div className='verify-email-box'>
                        <div className='text-bold-18 pb4 text-cool-grey-800  w-100'>{projectInfo?.name}</div>
                        <div className='subtitle text-cool-grey-500 text-regular-15'>{title}</div>
                    </div>
                    <div className='project-popup-card-outer-box'>
                        <div className='project-popup-card-outer'>
                            <div className='project-popup-main'>
                                <ProjectCardAccordion projectInfo={{ ...projectInfo, location }} />
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className='flex-nowrap gap-2'>
                    <Button variant='outline-primary' size='sm' onClick={onClose}>
                        Cancel
                    </Button>
                    <IconLinkButton className='filter-btn ms-auto' onClick={() => closeCreateProject()}>
                        Save as Draft
                        <span className='icon-sm pl4'>
                            <i className='fi fi-rr-cloud-upload' />
                        </span>
                    </IconLinkButton>
                    <Button variant='primary' size='sm' className='w-100 max-w200' onClick={() => createProjectMethod(false)}>
                        Create Project
                    </Button>
                </ModalFooter>
            </Modal>

            <ConfirmModal
                show={saveDraft}
                onClickLeftButton={() => setSaveDraft(false)}
                imageUrl='/assets/images/save-draft.svg'
                title='Save this Project as Draft?'
                leftButtonLabel='Cancel'
                rightButtonLabel='Save as Draft'
                onClickRightButton={() => createProjectMethod(true)}
            />

            <SaveDraftModal
                show={saveDraftIcon}
                onClose={() => {
                    closing(false);
                    onClose?.();
                }}
                imageUrl='/assets/images/save-drafticon.svg'
                title='Project Saved as Draft'
            />

            <SaveDraftModal
                show={createdSuccessfully}
                onClose={() => {
                    closing(true);
                    onClose?.();
                }}
                imageUrl='/assets/images/save-drafticon.svg'
                title={titleName}
            />
        </>
    );
};
