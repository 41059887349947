import { TreeSelect } from 'antd';
import React from 'react';

import { ICategory } from 'pages/ExplorerView/models';

interface CategoriesCheckboxProps {
    categories: ICategory[];
    selectedCategories: string[];
    onChangeCategories: (selectedCategories: string[]) => void;
}

export const CategoriesCheckbox = ({ categories, selectedCategories, onChangeCategories }: CategoriesCheckboxProps) => {
    return (
        <TreeSelect
            className='multi-tree-dropdown'
            treeData={categories}
            value={selectedCategories}
            onChange={onChangeCategories}
            treeCheckable
            placeholder='Categories'
            maxTagCount={1}
            style={{
                width: '100%',
            }}
        />
    );
};
