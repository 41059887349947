import { Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DashboardLayout } from 'layouts';
import { Button, CardStructure, Input, ExplorerMap, ProjectStepHeader } from 'components';
import { GoogleMapAddress } from 'components/ExplorerMap/ExplorerMap.models';
import { ILocation } from 'models';
import { setLocation } from 'store/location';

export const CreateProject = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(true);
    const [initialValues, setInitialValues] = useState<ILocation>({
        street: '',
        city: '',
        state: '',
        zip_code: '',
    });
    const [locationData, setLocationData] = useState<ILocation>({});

    const handleAddress = (address?: GoogleMapAddress) => {
        if (!address?.location) {
            return;
        }

        const components = address?.address?.address_components || [];
        const updatedValues: { [key: string]: string } = {};
        let streetName = '';
        const initialValuesUpdate: ILocation = {};
        const componentMap: {
            [key: string]: string;
        } = {
            locality: 'city',
            administrative_area_level_1: 'state',
            country: 'country',
            postal_code: 'zip_code',
        };

        if (address?.address?.address_components !== undefined) {
            setIsDisabled(false);
        }
        components.forEach((element) => {
            if (element.types.includes('street_number') || element.types.includes('route')) {
                streetName += `${element.long_name} `;
            }

            if (element.types.includes('route')) {
                initialValuesUpdate.street = element.long_name;
            }
            const type = element.types.find((t: string) => componentMap[t]);

            if (type) {
                const field = componentMap[type];
                updatedValues[field] = element.long_name;
            }
        });
        setLocationData((prevLocationData) => ({
            ...prevLocationData,
            position: address.location ? { ...address.location } : undefined,
        }));
        setInitialValues((prevValues) => ({
            ...prevValues,
            ...initialValuesUpdate,
            street: streetName.trim(),
        }));
        setLocationData((prevLocationData) => ({
            ...prevLocationData,
            ...initialValues,
            customer_address: address?.address?.formatted_address,
        }));
        setInitialValues((prevValues) => ({ ...prevValues, ...updatedValues }));
    };

    const handleProject = () => {
        dispatch(setLocation(locationData));
        setTimeout(() => navigate('/create-project-step-two'), 1000);
    };

    useEffect(() => {
        const locationValue = localStorage.getItem('locationValue');

        if (locationValue) {
            const locationDataParsed = JSON.parse(locationValue);

            if (locationDataParsed !== null && locationDataParsed !== undefined) {
                if (locationDataParsed.isBack === true) {
                    const keys = Object.keys(locationDataParsed);
                    setIsDisabled(false);
                    keys.forEach(() => {
                        setInitialValues((prevValues) => ({
                            ...prevValues,
                            zip_code: locationDataParsed.zip_code,
                            street: locationDataParsed.street,
                            city: locationDataParsed.city,
                            country: locationDataParsed.country,
                            state: locationDataParsed.state,
                        }));
                    });
                }
            }
            setLocationData(locationDataParsed);
            localStorage.removeItem('locationValue');
        }
    }, []);

    return (
        <DashboardLayout headerTitle='Create New Project' onClickBackButton={() => navigate(-1)}>
            <div className='create-project-main pt4'>
                <CardStructure className='card-spacing-24'>
                    <ProjectStepHeader
                        activeStep1='active'
                        value1='1'
                        value2='2'
                        step1Label='Select Property'
                        step2Label='Project Details'
                    />
                    <div className='create-project-body d-flex flex-wrap pt12'>
                        <div className='map-left'>
                            <ExplorerMap onChangeAddress={handleAddress} isSearch coordinates={locationData?.position} />
                        </div>
                        <div className='form-right pl12'>
                            <div className='location-form-inner v-border min-h100 pl12'>
                                <div className='location-header'>
                                    <h5>Location</h5>
                                    <p className='d-none'>
                                        <strong>19,051</strong> properties found
                                    </p>
                                </div>
                                <div className='location-form-detail pt12'>
                                    <Formik
                                        initialValues={initialValues}
                                        enableReinitialize
                                        onSubmit={async (values) => {
                                            await new Promise((r) => setTimeout(r, 500));
                                            alert(JSON.stringify(values, null, 2));
                                        }}
                                    >
                                        {({ values, handleChange }) => {
                                            return (
                                                <Form>
                                                    <Input
                                                        labelName='Street'
                                                        placeholder='Street'
                                                        type='text'
                                                        size='sm'
                                                        className='sm-label'
                                                        value={values?.street}
                                                        onChange={handleChange('street')}
                                                    />
                                                    <Input
                                                        labelName='City'
                                                        placeholder='City'
                                                        type='text'
                                                        size='sm'
                                                        className='sm-label'
                                                        value={values?.city}
                                                        onChange={handleChange('city')}
                                                    />
                                                    <Input
                                                        labelName='State'
                                                        placeholder='State'
                                                        type='text'
                                                        size='sm'
                                                        className='sm-label'
                                                        value={values?.state}
                                                        onChange={handleChange('state')}
                                                    />
                                                    <Input
                                                        labelName='Zip Code'
                                                        placeholder='Zip Code'
                                                        type='text'
                                                        size='sm'
                                                        className='sm-label'
                                                        isIcon
                                                        iconBtnClassName='grey-icon-sm ml3'
                                                        value={values?.zip_code}
                                                        onChange={handleChange('zip_code')}
                                                    />
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='footer-project text-end pt12'>
                        <Button variant='primary' size='sm' onClick={handleProject} disabled={isDisabled ? 'disabled' : ''}>
                            Next
                        </Button>
                    </div>
                </CardStructure>
            </div>
        </DashboardLayout>
    );
};
