import { Link } from 'react-router-dom';
import React, { ReactNode } from 'react';

interface LinkButtonProps {
    className?: string;
    children: ReactNode;
    linkTo: string;
}

export const LinkButton = ({ className, children, linkTo }: LinkButtonProps) => (
    <Link to={linkTo} className={`text-bold-16 text-decoration-none text-primary-link ${className}`}>
        {children}
    </Link>
);
