import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { Button, IconLinkButton, CardStructure, PermitModal, ProjectStepHeader, Permit } from 'components';
import { DashboardLayout } from 'layouts';
import { PERMITS } from 'utils/constants';
import { selectPermit } from 'store/selectors';
import { putMethod } from 'services/api.service';
import { ICreatePermit, PermitStatus } from 'pages/NewPermit/NewPermit.models';
import { mapCreatePermitToModel } from 'pages/NewPermit/NewPermit.mapper';

export const EditPermitStepTwo = () => {
    const [filePermitApplication, setFilePermitApplication] = useState(false);
    const permitInfo = useSelector(selectPermit);
    const { permitId } = useParams();
    const navigate = useNavigate();

    const handleCreatePermit = async () => {
        const payload = { ...permitInfo } as ICreatePermit;
        payload.status = PermitStatus.IN_REVIEW;

        try {
            const { data } = await putMethod(PERMITS.PERMITS, mapCreatePermitToModel(payload));

            if (data.status) {
                toast(data.message);
                setTimeout(
                    () =>
                        navigate(`/project-info/${data?.data?.project_data?.id}`, {
                            replace: true,
                        }),
                    2000,
                );
            }
        } catch (error) {
            if (error instanceof AxiosError && error?.response?.data?.status === false) {
                toast(error?.response?.data?.message);
            }
        }
    };

    return (
        <>
            <DashboardLayout headerTitle='Generate Permit'>
                <div className='create-project-main pt4'>
                    <CardStructure className='card-spacing-24'>
                        <ProjectStepHeader
                            prevStep1='prev'
                            lineActive='lineActive'
                            value1={<i className='fi fi-rr-check' />}
                            value2='2'
                            activeStep2='active current'
                            step1Label='Fill the Project Info'
                            step2Label='Submit Application'
                        />
                        <Permit />

                        <div className='footer-project pt12 d-flex align-items-center gap-3 generate-perm-step-two flex-wrap'>
                            <Button variant='outline-primary' size='sm' onClick={() => navigate(-1)}>
                                <span className='icon-sm pr4'>
                                    <i className='fi fi-rr-angle-small-left' />
                                </span>
                                Back
                            </Button>
                            <IconLinkButton className='filter-btn ms-auto' onClick={() => setFilePermitApplication(true)}>
                                <span className='icon-sm pr4'>
                                    <i className='fi fi-rr-download' />
                                </span>
                                Download Application
                            </IconLinkButton>

                            <Button variant='primary' size='sm' className='w-100 max-w200' onClick={handleCreatePermit}>
                                Save Edits
                            </Button>
                        </div>
                    </CardStructure>
                </div>
            </DashboardLayout>

            <PermitModal
                show={filePermitApplication}
                onClose={() => setFilePermitApplication(false)}
                title='Permit Application'
                permitId={permitInfo?.id || permitId}
            />
        </>
    );
};
