import React, { useState, useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { deleteMethod } from 'services/api.service';
import { PROJECTS } from 'utils/constants';
import { CardStructure, ProjectCard, ProjectCardHeader, DeleteAccountModal } from 'components';
import { IProject } from 'models';

interface ProjectTabsProps {
    projects?: IProject[];
    onUpdateProjectList?: (isDeleteProject: boolean) => void;
}

export const ProjectTabs = ({ projects = [], onUpdateProjectList }: ProjectTabsProps) => {
    const navigate = useNavigate();
    const [accountDelete, setAccountDelete] = useState(false);
    const [projectId, setProjectId] = useState<number>(0);
    const [projectsList, setProjectsList] = useState<IProject[]>([]);

    const handleDeleteProject = (id: number) => {
        setProjectId(id);
        setAccountDelete(true);
    };

    const handleDelete = async () => {
        try {
            const { data } = await deleteMethod(`${PROJECTS.PROJECT}?id=${projectId}`);

            if (data.status === true) {
                const indexToRemove = projectsList.findIndex((project) => project.id === projectId);

                if (indexToRemove !== -1) {
                    projectsList.splice(indexToRemove, 1);
                }
                onUpdateProjectList?.(true);
                setAccountDelete(false);
                toast(data?.message);
            }
        } catch (error) {
            if (error instanceof AxiosError && error?.response?.data?.status === false) {
                toast(error?.response?.data?.message);
            }
        }
    };

    const handleEditRoute = (projectId: number) => {
        navigate(`/edit-project/${projectId}`);
    };

    useEffect(() => {
        setProjectsList(projects);
    }, [projects]);

    return (
        <>
            <div className='tab-content pt14'>
                <Tab.Content>
                    <Tab.Pane eventKey='All' className='project-all'>
                        <div className='project-tab-detail'>
                            {projectsList?.map((project) => (
                                <div
                                    key={project?.id}
                                    className='project-card-outer'
                                    onClick={() => navigate(`/project-info/${project?.id}`)}
                                >
                                    <div className='project-link text-decoration-none cursor-pointer'>
                                        <ProjectCard
                                            isDemo={project?.is_demo}
                                            projectName={project?.name}
                                            scope={project?.technology}
                                            overallStatus={project?.overall_status}
                                            overallColor={
                                                project?.overall_status === 'Active'
                                                    ? 'blue'
                                                    : project?.overall_status === 'Draft'
                                                      ? 'grey'
                                                      : 'yellow'
                                            }
                                            permittingStatus={
                                                project?.permitting_status === null
                                                    ? 'Not Started'
                                                    : project?.permitting_status === 'Pending'
                                                      ? 'Pending'
                                                      : project.permitting_status === 'In Review'
                                                        ? 'In Review'
                                                        : 'Approved'
                                            }
                                            permittingColor={
                                                project?.permitting_status === null
                                                    ? 'blue'
                                                    : project?.permitting_status === 'Pending'
                                                      ? 'grey'
                                                      : project?.permitting_status === 'In Review'
                                                        ? 'yellow'
                                                        : 'blue'
                                            }
                                            incentivesStatus='Done'
                                            incentivesColor='green'
                                            onClickDelete={(e) => {
                                                e.stopPropagation();
                                                handleDeleteProject(project?.id);
                                            }}
                                            onClickEdit={(e) => {
                                                e.stopPropagation();
                                                handleEditRoute(project?.id);
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey='Draft' className='project-draft'>
                        <div className='project-tab-detail'>
                            {projectsList?.map((project, index: number) => (
                                <div key={index} className='project-card-outer'>
                                    <div className='project-link text-decoration-none cursor-pointer draft-link'>
                                        {project?.overall_status === 'Draft' && (
                                            <CardStructure className='card-project-group'>
                                                <div className='project-card-bx' onClick={() => navigate(`/project-info/${project?.id}`)}>
                                                    <ProjectCardHeader
                                                        projectName={project?.name}
                                                        scope={project?.technology}
                                                        onClickDelete={(e) => {
                                                            e.stopPropagation();
                                                            handleDeleteProject(project?.id);
                                                        }}
                                                        onClickEdit={(e) => {
                                                            e.stopPropagation();
                                                            handleEditRoute(project?.id);
                                                        }}
                                                    />
                                                </div>
                                            </CardStructure>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey='In_Progress' className='project-inprogress'>
                        <div className='project-tab-detail'>
                            <div className='project-card-outer'>
                                <div className='project-link text-decoration-none cursor-pointer'>
                                    <ProjectCard
                                        projectName='Harvard University'
                                        scope='Rooftop Solar Battery Storage'
                                        overallStatus='Draft'
                                        overallColor='grey'
                                        permittingStatus='In Progress'
                                        permittingColor='yellow'
                                        incentivesStatus='Done'
                                        incentivesColor='green'
                                        onClickDelete={() => setAccountDelete(true)}
                                        onClickEdit={() => navigate('/create-project')}
                                    />
                                </div>
                            </div>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey='Done' className='project-done'>
                        <div className='project-tab-detail'>
                            <div className='project-card-outer'>
                                <div className='project-link text-decoration-none cursor-pointer'>
                                    <ProjectCard
                                        projectName='Harvard University'
                                        scope='Rooftop Solar Battery Storage'
                                        overallStatus='Draft'
                                        overallColor='grey'
                                        permittingStatus='In Progress'
                                        permittingColor='yellow'
                                        incentivesStatus='Done'
                                        incentivesColor='green'
                                        onClickDelete={() => setAccountDelete(true)}
                                        onClickEdit={() => navigate('/create-project')}
                                    />
                                </div>
                            </div>
                        </div>
                    </Tab.Pane>
                </Tab.Content>
            </div>

            <DeleteAccountModal
                show={accountDelete}
                onClose={() => setAccountDelete(false)}
                imageUrl='/assets/images/questionmark-icon.svg'
                title='Delete this Project?'
                subtitle='This action can not be undone'
                leftButtonLabel='Cancel'
                onClickLeftButton={() => setAccountDelete(false)}
                onClickRightButton={handleDelete}
                rightButtonLabel='Delete'
            />
        </>
    );
};
