import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { getMethod } from 'services/api.service';
import { PROJECTS } from 'utils/constants';
import { IRecentProject } from 'models';

export const AccordionMenu = () => {
    const [recentProjects, setRecentProjects] = useState<IRecentProject[]>([]);

    useEffect(() => {
        const getRecentProjects = async () => {
            try {
                const { data } = await getMethod(PROJECTS.GET_RECENT_PROJECT);

                if (data?.status) {
                    setRecentProjects(data?.data);
                }
            } catch (error) {
                // TODO: Error handling
            }
        };

        getRecentProjects();
    }, []);

    return (
        <Accordion className='accordion-custom-menu'>
            <Accordion.Item eventKey='0'>
                <Link
                    to='/projects'
                    className={`nav-link project-management-menu ${window?.location?.href?.includes('/projects') && 'active'}`}
                >
                    <div className='menu-outer-box d-flex align-items-center flex-wrap'>
                        <span className='icon-box'>
                            <i className='fi fi-rr-list' />
                        </span>
                        <span className='menu-title'>Projects</span>
                    </div>
                    <div className='project-manage-accord'>
                        <div className='menu-count-box'>
                            <span className='count-num'>{1}</span>
                        </div>
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <Accordion.Header />
                        </div>
                    </div>
                </Link>
                <Accordion.Body>
                    <ul className='projects side-menu-bar'>
                        {recentProjects?.map((project) => {
                            return (
                                <li key={project?.id}>
                                    <Link
                                        to={`/project-info/${project?.id}`}
                                        className={`nav-link ${
                                            window?.location?.href?.includes(`/project-info/${project?.id}`) && 'active'
                                        }`}
                                    >
                                        <div className='menu-outer-box d-flex align-items-center flex-wrap'>
                                            <span
                                                className={`round-box ${
                                                    project?.status === 'Active'
                                                        ? 'bg-indigo-400'
                                                        : project?.status === 'Draft'
                                                          ? 'bg-cool-grey-400'
                                                          : 'bg-red-400'
                                                }`}
                                            />
                                            <span className='menu-title'>{project?.name}</span>
                                        </div>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};
