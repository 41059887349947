import React from 'react';

import { Modal, ModalBody } from 'components';
import { IStateData } from 'pages/ExplorerView/models';

interface StateOverviewModalProps {
    onClose: () => void;
    stateData: IStateData | undefined;
}

export const StateOverviewModal: React.FC<StateOverviewModalProps> = ({ stateData, onClose }) => {
    return (
        <Modal show onClose={onClose} size='lg' title={`${stateData?.name} Overview`}>
            <ModalBody>
                {stateData?.overview ? (
                    <div dangerouslySetInnerHTML={{ __html: stateData.overview }} />
                ) : (
                    <p>No state overview available.</p>
                )}
            </ModalBody>
        </Modal>
    );
};
