import React, { ChangeEvent } from 'react';
import { useField } from 'formik';
import { toast } from 'react-toastify';

import { IconButton, UploadInput } from 'components';

interface UploadInputFieldProps {
    label: string;
    uploadButtonLabel: string;
    fieldName: string;
    fileTypes?: string[];
}

export const UploadInputField = ({ label, uploadButtonLabel, fieldName, fileTypes = [] }: UploadInputFieldProps) => {
    const [field, , helpers] = useField(fieldName);

    const handleFileUpload = (data: ChangeEvent<HTMLInputElement>) => {
        const fileInput = data?.target;
        const selectedFile = fileInput?.files?.[0];

        if (selectedFile) {
            if (fileTypes.length === 0 || fileTypes.includes(selectedFile?.type)) {
                helpers.setValue(selectedFile);
            } else {
                toast('Invalid file type. Please select a PDF file.');
            }
        }
    };

    return (
        <div className='upload-file-inner'>
            <div className='label-box'>{label}</div>
            <div className={field.value ? 'd-none' : 'upload-btn-file pl10 pt3'}>
                <UploadInput
                    name={fieldName}
                    labelName={uploadButtonLabel}
                    id={fieldName}
                    htmlFor={fieldName}
                    onUploadFileChange={handleFileUpload}
                />
            </div>
            <div
                className={
                    field.value
                        ? 'file-detail d-flex align-items-center flex-wrap'
                        : 'file-detail d-flex align-items-center flex-wrap d-none'
                }
            >
                <div className='file-extension'>
                    <p className='text-indigo-600 text-semibold-15'>{field?.value.name}</p>
                </div>
                <div className='file-delete'>
                    <IconButton size='sm' onClick={() => helpers.setValue(null)}>
                        <i className='fi fi-rr-trash' />
                    </IconButton>
                </div>
            </div>
        </div>
    );
};
