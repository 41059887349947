import React from 'react';
import { Form, FormGroup, FormLabel } from 'react-bootstrap';
import Select from 'react-select';
import { useField } from 'formik';

import { ISelectOption } from 'models';
import { customStyles } from 'utils/commonFunctions';

interface SelectFieldProps<T> {
    className?: string;
    label: string;
    fieldName: string;
    placeholder?: string;
    options: ISelectOption<T>[];
    classes?: {
        select: string;
    };
}

export const SelectField = <T,>({ className, label, fieldName, placeholder, options, classes }: SelectFieldProps<T>) => {
    const [field, meta, helpers] = useField(fieldName);

    return (
        <FormGroup className={`custom_form mb7 ${className}`}>
            <div className='form-label-outer d-flex align-items-center flex-wrap justify-content-between gap-2'>
                <FormLabel className='text-regular-15 text-cool-grey-800 flex-grow-1'>{label}</FormLabel>
                {meta.error && (
                    <Form.Control.Feedback type='invalid' className='text-regular-12 text-red-400 mb2 error-msg'>
                        {meta.error}
                    </Form.Control.Feedback>
                )}
            </div>
            <Select
                options={options}
                onChange={(e) => e?.value && helpers.setValue(e?.value)}
                value={options.find(({ value }) => value === field.value)}
                placeholder={placeholder}
                className={classes?.select}
                styles={customStyles}
            />
        </FormGroup>
    );
};
