import React from 'react';

import { Modal, ModalBody, IconButton, ExplorerMap } from 'components';
import { ILocation } from 'models';

interface PropertyMapModalProp {
    show: boolean;
    modalTitle?: string;
    newLocationData?: ILocation;
    locationData?: ILocation;
    center?: { lat: number; lng: number };
    mapTypeId?: string;
    onClose?: () => void;
    showPPC?: boolean;
    showCEJST?: boolean;
    showCoalClosure?: boolean;
    showMSA?: boolean;
}

export const PropertyMapModal = ({
    show,
    onClose,
    modalTitle,
    newLocationData,
    center,
    mapTypeId,
    locationData,
    showPPC,
    showCEJST,
    showCoalClosure,
    showMSA,
}: PropertyMapModalProp) => (
    <Modal show={show} onClose={onClose} title={modalTitle} className='modal-wrapper modal-info property-map-modal' size='xl'>
        <ModalBody>
            <div className='property-map-inner'>
                <div className='property-map-header d-flex flex-wrap gap-3'>
                    <div className='prop-map-text' />
                    <div className='right-close-btn'>
                        <IconButton size='sm' className='close-icon' onClick={onClose}>
                            <i className='fi fi-rr-cross-small' />
                        </IconButton>
                    </div>
                </div>
                <div className='property-map-frame pt12'>
                    <div className='map-image-frame position-relative'>
                        {locationData !== undefined ? (
                            <ExplorerMap
                                isSearch={false}
                                locationData={locationData}
                                fullScreenView={false}
                                coordinates={center}
                                mapType={mapTypeId}
                                showPPC={showPPC}
                                showCEJST={showCEJST}
                                showCoalClosure={showCoalClosure}
                                showMSA={showMSA}
                            />
                        ) : (
                            <ExplorerMap
                                isSearch={false}
                                locationData={newLocationData}
                                fullScreenView={false}
                                coordinates={center}
                                mapType={mapTypeId}
                                showPPC={showPPC}
                                showCEJST={showCEJST}
                                showCoalClosure={showCoalClosure}
                                showMSA={showMSA}
                            />
                        )}
                    </div>
                </div>
            </div>
        </ModalBody>
    </Modal>
);
