// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-box {
  color: var(--indigo-600);
}`, "",{"version":3,"sources":["webpack://./src/pages/NewPermitSubmitted/NewPermitSubmitted.styles.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;AACF","sourcesContent":[".icon-box {\n  color: var(--indigo-600);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
