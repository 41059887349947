import { Formik, FormikProps } from 'formik';
import React, { useRef, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { DashboardLayout } from 'layouts';
import { Button, CardStructure, InfoModal, ProjectStepHeader, SelectField, RadioField, TextField, DateField } from 'components';
import { getMethod } from 'services/api.service';
import { PERMITS } from 'utils/constants';
import { setPermit } from 'store/permit';
import { createSelectOptionsFromEnum } from 'components/Fields/SelectField/helpers';
import { initialValues, PERMIT_ELIGIBILITY_QUESTIONNAIRE } from 'pages/NewPermit/NewPermit.constants';
import { mapBackendPayloadToCreatePermit } from 'pages/NewPermit/NewPermit.mapper';
import {
    ElectricUtilityType,
    GasUtilityType,
    ICreatePermit,
    PropertyOwnershipType,
    ResponsibleForPayingElectricServicesType,
} from '../NewPermit/NewPermit.models';
import { selectPermit } from 'store/selectors';
import { validationSchema } from 'pages/NewPermit/NewPermit.schema';

export const EditPermit = () => {
    const { permitId } = useParams();
    const permitInfoStore = useSelector(selectPermit);
    const formikRef = useRef<FormikProps<ICreatePermit>>(null);

    const [infoModal, setInfoModal] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleUpdatePermit = async (formPayload: ICreatePermit) => {
        dispatch(setPermit(formPayload));
        navigate(`/edit-permit-step-two/${permitId}`);
    };

    const getPermitData = async (id: string) => {
        try {
            const { data } = await getMethod(`${PERMITS.PERMITS}?id=${id}`);

            if (data?.status) {
                const permitData = mapBackendPayloadToCreatePermit(data?.data);

                formikRef.current?.resetForm({ values: permitData });
            }
        } catch (error) {
            if (error instanceof AxiosError && error?.response?.data?.status === false) {
                toast(error?.response?.data?.message);
            }
        }
    };

    useEffect(() => {
        if (permitInfoStore?.id && Number(permitInfoStore.id) === Number(permitId)) {
            formikRef.current?.resetForm({ values: permitInfoStore });
        } else if (permitId) {
            getPermitData(permitId);
        }
    }, [permitId, permitInfoStore]);

    return (
        <>
            <DashboardLayout headerTitle='Generate Permit' onClickBackButton={() => navigate(-1)}>
                <div className='create-project-main pt4'>
                    <CardStructure className='card-spacing-24 position-relative'>
                        <a
                            href='https://www.cpuc.ca.gov/somah'
                            target='_blank'
                            rel='noreferrer'
                            className='new-permit-titleLink text-cool-grey-800 text-bold-20'
                        >
                            CPUC Solar on Multifamily Affordable Housing Program (SOMAH)
                        </a>
                        <Formik<ICreatePermit>
                            innerRef={formikRef}
                            enableReinitialize
                            initialValues={initialValues}
                            onSubmit={handleUpdatePermit}
                            validationSchema={validationSchema}
                        >
                            {({ isValid, handleSubmit, values }) => (
                                <Form onSubmit={handleSubmit}>
                                    <ProjectStepHeader
                                        activeStep1='active'
                                        value1='1'
                                        value2='2'
                                        step1Label='Application Details'
                                        step2Label='Submit Application'
                                    />

                                    <div className='create-project-body d-flex flex-wrap pt12'>
                                        <div className='form-left-project'>
                                            <div className='new-permit-form'>
                                                <div className='new-permit-card'>
                                                    <h3 className='new-permit-title text-cool-grey-800 text-semibold-18 pb6'>
                                                        Host Customer & Project Site Information
                                                    </h3>
                                                    <Row>
                                                        <Col xs={12}>
                                                            <TextField
                                                                fieldName='name'
                                                                labelName='Permit Name:'
                                                                placeholder='Enter permit name'
                                                                type='text'
                                                                size='sm'
                                                                disabled
                                                                className='sm-label'
                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={6}>
                                                            <DateField
                                                                fieldName='host_customer.date'
                                                                labelName='Date:'
                                                                placeholder='MM/DD/YYYY'
                                                                size='sm'
                                                                className='sm-label'
                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={6}>
                                                            <TextField
                                                                fieldName='host_customer.name'
                                                                labelName='Name:'
                                                                placeholder='Enter customer name'
                                                                type='text'
                                                                size='sm'
                                                                className='sm-label'
                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={6}>
                                                            <TextField
                                                                fieldName='host_customer.company'
                                                                labelName='Company:'
                                                                placeholder='Enter customer company'
                                                                type='text'
                                                                size='sm'
                                                                className='sm-label'
                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={6}>
                                                            <TextField
                                                                fieldName='host_customer.address'
                                                                labelName='Applicant Address:'
                                                                placeholder='Enter applicant address'
                                                                type='text'
                                                                size='sm'
                                                                className='sm-label'
                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={6}>
                                                            <TextField
                                                                fieldName='host_customer.email'
                                                                labelName='Email:'
                                                                placeholder='Enter customer email'
                                                                type='text'
                                                                size='sm'
                                                                className='sm-label'
                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={6}>
                                                            <TextField
                                                                fieldName='host_customer.phone'
                                                                labelName='Phone:'
                                                                placeholder='Enter customer phone'
                                                                type='text'
                                                                size='sm'
                                                                className='sm-label'
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className='new-permit-card'>
                                                    <h3 className='new-permit-title text-cool-grey-800 text-semibold-18 pb6'>
                                                        Project Site Information
                                                    </h3>
                                                    <Row>
                                                        <Col xs={12} sm={6}>
                                                            <TextField
                                                                fieldName='project_site.name'
                                                                labelName='Property Name:'
                                                                placeholder='Enter property name'
                                                                type='text'
                                                                size='sm'
                                                                className='sm-label'
                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={6}>
                                                            <TextField
                                                                fieldName='project_site.total_units'
                                                                labelName='Total # Units:'
                                                                placeholder='Enter total units'
                                                                type='number'
                                                                size='sm'
                                                                className='sm-label'
                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={6}>
                                                            <TextField
                                                                fieldName='project_site.address'
                                                                labelName='Project Site Address:'
                                                                placeholder='Enter project site address'
                                                                type='text'
                                                                size='sm'
                                                                className='sm-label'
                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={6}>
                                                            <SelectField
                                                                label='Select Ownership Type'
                                                                options={createSelectOptionsFromEnum(PropertyOwnershipType)}
                                                                fieldName='project_site.ownership_type'
                                                                classes={{ select: 'custom-select-box' }}
                                                                placeholder='Ownership Type'
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className='new-permit-card'>
                                                    <h3 className='new-permit-title text-cool-grey-800 text-semibold-18 pb6'>
                                                        Property Eligibility
                                                    </h3>
                                                    <Row>
                                                        <Col xs={12}>
                                                            <RadioField
                                                                fieldName='property_eligibility.is_within_median_income_area'
                                                                label={
                                                                    PERMIT_ELIGIBILITY_QUESTIONNAIRE.get('is_within_median_income_area') ||
                                                                    ''
                                                                }
                                                                options={[
                                                                    {
                                                                        label: 'Yes',
                                                                        name: 'Yes',
                                                                        value: true,
                                                                    },
                                                                    {
                                                                        label: 'No',
                                                                        name: 'No',
                                                                        value: false,
                                                                    },
                                                                ]}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className='new-permit-card'>
                                                    <Row>
                                                        <Col xs={12}>
                                                            <RadioField
                                                                fieldName='property_eligibility.is_disadvantaged_community'
                                                                label={
                                                                    PERMIT_ELIGIBILITY_QUESTIONNAIRE.get('is_disadvantaged_community') || ''
                                                                }
                                                                options={[
                                                                    {
                                                                        label: 'Yes',
                                                                        name: 'Yes',
                                                                        value: true,
                                                                    },
                                                                    {
                                                                        label: 'No',
                                                                        name: 'No',
                                                                        value: false,
                                                                    },
                                                                ]}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className='new-permit-card'>
                                                    <Row>
                                                        <Col xs={12}>
                                                            <RadioField
                                                                fieldName='property_eligibility.is_owned_by_a_tribe'
                                                                label={PERMIT_ELIGIBILITY_QUESTIONNAIRE.get('is_owned_by_a_tribe') || ''}
                                                                options={[
                                                                    {
                                                                        label: 'Yes',
                                                                        name: 'Yes',
                                                                        value: true,
                                                                    },
                                                                    {
                                                                        label: 'No',
                                                                        name: 'No',
                                                                        value: false,
                                                                    },
                                                                ]}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className='new-permit-card'>
                                                    <Row>
                                                        <Col xs={12}>
                                                            <RadioField
                                                                fieldName='property_eligibility.is_rental_housing'
                                                                label={PERMIT_ELIGIBILITY_QUESTIONNAIRE.get('is_rental_housing') || ''}
                                                                options={[
                                                                    {
                                                                        label: 'Yes',
                                                                        name: 'Yes',
                                                                        value: true,
                                                                    },
                                                                    {
                                                                        label: 'No',
                                                                        name: 'No',
                                                                        value: false,
                                                                    },
                                                                ]}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <Col xs={12} sm={6}>
                                                    <TextField
                                                        fieldName='property_eligibility.census_tract_code'
                                                        labelName='Census Tract Code:'
                                                        placeholder='Enter Census Tract code'
                                                        type='text'
                                                        size='sm'
                                                        className='sm-label'
                                                    />
                                                </Col>

                                                <div className='new-permit-card'>
                                                    <Row>
                                                        <Col xs={12}>
                                                            <RadioField
                                                                fieldName='property_eligibility.has_certificate_of_occupancy'
                                                                label={
                                                                    PERMIT_ELIGIBILITY_QUESTIONNAIRE.get('has_certificate_of_occupancy') ||
                                                                    ''
                                                                }
                                                                options={[
                                                                    {
                                                                        label: 'Yes',
                                                                        name: 'Yes',
                                                                        value: true,
                                                                    },
                                                                    {
                                                                        label: 'No',
                                                                        name: 'No',
                                                                        value: false,
                                                                    },
                                                                ]}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className='new-permit-card'>
                                                    <Row>
                                                        <Col xs={12}>
                                                            <RadioField
                                                                fieldName='property_eligibility.has_solar_construction_started'
                                                                label={
                                                                    PERMIT_ELIGIBILITY_QUESTIONNAIRE.get(
                                                                        'has_solar_construction_started',
                                                                    ) || ''
                                                                }
                                                                options={[
                                                                    {
                                                                        label: 'Yes',
                                                                        name: 'Yes',
                                                                        value: true,
                                                                    },
                                                                    {
                                                                        label: 'No',
                                                                        name: 'No',
                                                                        value: false,
                                                                    },
                                                                ]}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className='new-permit-card'>
                                                    <Row>
                                                        <Col xs={12}>
                                                            <RadioField
                                                                fieldName='property_eligibility.was_referral_to_SOMAH_program'
                                                                label={
                                                                    PERMIT_ELIGIBILITY_QUESTIONNAIRE.get('was_referral_to_SOMAH_program') ||
                                                                    ''
                                                                }
                                                                options={[
                                                                    {
                                                                        label: 'Yes',
                                                                        name: 'Yes',
                                                                        value: true,
                                                                    },
                                                                    {
                                                                        label: 'No',
                                                                        name: 'No',
                                                                        value: false,
                                                                    },
                                                                ]}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className='new-permit-card'>
                                                    <h3 className='new-permit-title text-cool-grey-800 text-semibold-18 pb6'>
                                                        Electric Utility Information
                                                    </h3>
                                                    <Row>
                                                        <Col xs={12} sm={6}>
                                                            <SelectField
                                                                label='Utility:'
                                                                fieldName='electrical_utility.utility'
                                                                options={createSelectOptionsFromEnum(ElectricUtilityType)}
                                                                classes={{ select: 'custom-select-box' }}
                                                                placeholder='Electric Utility'
                                                            />
                                                        </Col>
                                                        <Col xs={12}>
                                                            <RadioField
                                                                label='Does the property receive electric service from a community choice aggregation (CCA)?'
                                                                fieldName='electrical_utility.does_receive_electric_service_from_CCA'
                                                                options={[
                                                                    {
                                                                        label: 'Yes',
                                                                        name: 'Yes',
                                                                        value: true,
                                                                    },
                                                                    {
                                                                        label: 'No',
                                                                        name: 'No',
                                                                        value: false,
                                                                    },
                                                                ]}
                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={6}>
                                                            <SelectField
                                                                label='Who is responsible for paying for electric services in tenant units?'
                                                                fieldName='electrical_utility.responsible_for_paying_electric_services'
                                                                options={createSelectOptionsFromEnum(
                                                                    ResponsibleForPayingElectricServicesType,
                                                                )}
                                                                classes={{ select: 'custom-select-box' }}
                                                                placeholder='Select the responsible'
                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={6}>
                                                            <TextField
                                                                fieldName='electrical_utility.meters_of_common_areas'
                                                                labelName='Number of Meters of Common Areas:'
                                                                placeholder='Enter number of meters'
                                                                type='number'
                                                                size='sm'
                                                                className='sm-label'
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className='new-permit-card'>
                                                    <h3 className='new-permit-title text-cool-grey-800 text-semibold-18 pb6'>
                                                        Gas Utility Information
                                                    </h3>

                                                    <Row>
                                                        <Col xs={12} sm={6}>
                                                            <SelectField
                                                                label='Gas Utility:'
                                                                options={createSelectOptionsFromEnum(GasUtilityType)}
                                                                fieldName='gas_utility.utility'
                                                                classes={{ select: 'custom-select-box' }}
                                                                placeholder='Select Gas Utility'
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='right-following-info pl12'>
                                            <div className='location-form-inner v-border min-h100 pl12 d-flex flex-wrap'>
                                                <div className='info-following-content'>
                                                    <h4 className='title-main text-cool-grey-800 text-semibold-18 pb8'>Project Info:</h4>
                                                    <div className='info-content-follow'>
                                                        <div className='location-edit-box'>
                                                            <div className='location-header pb4'>
                                                                <h5>Location</h5>
                                                            </div>
                                                            <Row>
                                                                <Col xs={12} sm={6}>
                                                                    <div className='project-popup-detail'>
                                                                        <div className='label-name text-regular-14 text-cool-grey-500'>
                                                                            State
                                                                        </div>
                                                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                                            {values?.project_data?.state}, {values?.project_data?.country}
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12} sm={6}>
                                                                    <div className='project-popup-detail'>
                                                                        <div className='label-name text-regular-14 text-cool-grey-500'>
                                                                            City / Municipality
                                                                        </div>
                                                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                                            {values?.project_data?.city}
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12} sm={6}>
                                                                    <div className='project-popup-detail'>
                                                                        <div className='label-name text-regular-14 text-cool-grey-500'>
                                                                            Address
                                                                        </div>
                                                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                                            {values?.project_data?.customer_address}
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12} sm={6}>
                                                                    <div className='project-popup-detail'>
                                                                        <div className='label-name text-regular-14 text-cool-grey-500'>
                                                                            Zip Code
                                                                        </div>
                                                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                                            96080
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <hr className='h-border-top' />
                                                        </div>

                                                        <div className='location-edit-box'>
                                                            <div className='location-header pb4'>
                                                                <h5>Project Details</h5>
                                                            </div>
                                                            <Row>
                                                                <Col xs={12} sm={6}>
                                                                    <div className='project-popup-detail'>
                                                                        <div className='label-name text-regular-14 text-cool-grey-500'>
                                                                            Customer Name
                                                                        </div>
                                                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                                            {values?.project_data?.customer_name}
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12} sm={6}>
                                                                    <div className='project-popup-detail'>
                                                                        <div className='label-name text-regular-14 text-cool-grey-500'>
                                                                            Contact email
                                                                        </div>
                                                                        <div className='project-popup-content text-regular-15 text-cool-grey-800 project-email'>
                                                                            {values?.project_data?.contact_email}
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <div className='project-popup-detail'>
                                                                        <div className='label-name text-regular-14 text-cool-grey-500'>
                                                                            Contact phone
                                                                        </div>
                                                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                                            {values?.project_data?.contact_phone}
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12} sm={6}>
                                                                    <div className='project-popup-detail'>
                                                                        <div className='label-name text-regular-14 text-cool-grey-500'>
                                                                            Project type
                                                                        </div>
                                                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                                            {values?.project_data?.technology}
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12} sm={6}>
                                                                    <div className='project-popup-detail'>
                                                                        <div className='label-name text-regular-14 text-cool-grey-500'>
                                                                            Technology size
                                                                        </div>
                                                                        <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                                                            Technology size
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='footer-project text-end pt12'>
                                        <Button type='submit' variant='primary' size='sm' disabled={isValid ? '' : 'disabled'}>
                                            Next
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </CardStructure>
                </div>
            </DashboardLayout>

            <InfoModal
                show={infoModal}
                onClose={() => setInfoModal(false)}
                modalTitle='Zip Code'
                title='A ZIP Code is a postal code used by the United States Postal Service (USPS) and is an acronym for Zone Improvement Plan. The first five digits indicate the destination post office or delivery area. The last 4 digits represent specific delivery routes within delivery areas.'
                confirmButtonLabel='Got It'
                confirmButtonClassName='min-w190'
                onConfirm={() => setInfoModal(false)}
            />
        </>
    );
};
