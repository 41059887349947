import React from 'react';
import Form from 'react-bootstrap/Form';

import { Modal, ModalBody, ModalFooter, Button } from 'components';

interface InfoModalProps {
    show: boolean;
    modalTitle?: string;
    title?: string;
    confirmButtonLabel?: string;
    confirmButtonClassName?: string;
    onClose?: () => void;
    onConfirm?: () => void;
}

export const InfoModal = ({ show, onClose, modalTitle, title, confirmButtonLabel, onConfirm, confirmButtonClassName }: InfoModalProps) => (
    <Modal show={show} onClose={onClose} title={modalTitle} className='modal-wrapper modal-info' size='sm'>
        <ModalBody>
            <div className='verify-email-box'>
                <Form>
                    <div className='subtitle'>
                        <p className='mb-0 text-center text-regular-16 text-cool-grey-500 pb16'>{title}</p>
                    </div>
                </Form>
            </div>
        </ModalBody>
        <ModalFooter className='flex-nowrap gap-2 m-auto'>
            <Button variant='primary' size='sm' btnFullClassName='w-100' onClick={onConfirm} className={confirmButtonClassName}>
                {confirmButtonLabel}
            </Button>
        </ModalFooter>
    </Modal>
);
