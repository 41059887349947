import React from 'react';

export const IncentiveClaimsTabs = () => (
    <div className='project-incentive-blank blank-section-claims'>
        <div className='proj-incentive-info text-center'>
            <div className='img-box pb8'>
                <img src='/assets/images/available-version.svg' alt='icon' />
            </div>
            <div className='label-tex text-bold-24 text-cool-grey-800'>Coming Soon!!</div>
        </div>
    </div>
);
