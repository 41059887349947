import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Modal, ModalBody, ModalFooter, IconLinkButton } from 'components';
import { postMethod } from 'services/api.service';
import { AUTH } from 'utils/constants';
import { selectEmail } from 'store/selectors';

interface ChangePasswordModalProps {
    show: boolean;
    onClose?: () => void;
    modalTitle?: string;
    imageUrl?: string;
    title?: string;
    imageClassName?: string;
    onClickCard?: () => void;
    pointerClassName?: string;
}

export const ChangePasswordModal = ({
    show,
    onClose,
    modalTitle,
    imageUrl,
    title,
    onClickCard,
    imageClassName,
    pointerClassName,
}: ChangePasswordModalProps) => {
    const email = useSelector(selectEmail);
    const [emailSent, setEmailSent] = useState(false);

    const handleEmailClick = async () => {
        const payload = {
            email: email || localStorage.getItem('email'),
        };
        try {
            const { data } = await postMethod(AUTH.FORGOT_PASSWORD, payload);

            if (data.status === true) {
                setEmailSent(true);
            }
        } catch (error) {
            // TODO: Error handling
        }
    };

    return (
        <>
            <Modal show={show} onClose={onClose} title={modalTitle} className='modal-wrapper modal-header-none' size='sm'>
                <ModalBody>
                    <div className='verify-email-box max-w440 m-auto'>
                        <div className='header-icon-box pb16 text-center '>
                            <img src={imageUrl} alt='email-icon' className={`${imageClassName}`} />
                        </div>
                        <div
                            role='button'
                            tabIndex={0}
                            className={`change-pass-modal-card mb16 ${pointerClassName}`}
                            onClick={onClickCard}
                            onKeyDown={onClickCard}
                        >
                            <div className='text-bold-28 pb8 text-cool-grey-800 text-center w-100'>{title}</div>
                            <div className='change-pass-subtitle'>
                                <p className='text-cool-grey-800 mb0 text-regular-16 text-center'>
                                    You will receive an email with a link to reset your password. Please check your inbox.
                                </p>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className='flex-nowrap gap-2 max-w440 m-auto justify-content-center'>
                    <IconLinkButton className='filter-btn' onClick={handleEmailClick} disabled={emailSent ? 'disabled' : ''}>
                        {emailSent !== true ? 'Resend email link' : 'Link has been resent'}
                    </IconLinkButton>
                </ModalFooter>
            </Modal>
        </>
    );
};
