import React, { ReactNode } from 'react';
import Badge from 'react-bootstrap/Badge';

interface ChipProps {
    children: ReactNode;
    onClick?: () => void;
    className?: string;
}

export const Chip = ({ children, onClick, className }: ChipProps) => (
    <div className='custom-chip'>
        <Badge pill bg='primary' className={`${className}`}>
            {children}
            <button type='button' className='btn-cross' onClick={() => onClick?.()}>
                <i className='fi fi-rr-cross-small' />
            </button>
        </Badge>
    </div>
);
