import React, { ReactNode } from 'react';

import { LinkButton } from 'components';

interface PendingProjectCardProps {
    className?: string;
    linkTo: string;
    title?: string;
    children: ReactNode;
}

export const PendingProjectCard = ({ className, linkTo, title, children }: PendingProjectCardProps) => (
    <div className={`pending-project-main pb6 ${className}`}>
        <div className='pending-top-bar d-flex align-items-center justify-content-between'>
            <div className='left-title'>
                <h4 className='text-semibold-18 text-cool-grey-800'>{title}</h4>
            </div>
            <div className='right-view-all'>
                <LinkButton linkTo={linkTo} className='ml2 d-flex align-items-center'>
                    {children}
                    <span className='icon-box icon-box-next ml3'>
                        <i className='fi fi-rr-angle-small-right' />
                    </span>
                </LinkButton>
            </div>
        </div>
    </div>
);
