import React, { ChangeEvent } from 'react';
import Accordion from 'react-bootstrap/Accordion';

import { IListItem } from 'models';

interface FilterProjectsAccordionProps {
    title: string;
    statuses: IListItem[];
    selectedStatuses: string[];
    onCheckboxChange: (title: string, name: string, checked: boolean) => void;
    titleName?: string;
}

export const FilterProjectsAccordion = ({
    title,
    statuses,
    selectedStatuses,
    onCheckboxChange,
    titleName,
}: FilterProjectsAccordionProps) => {
    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        onCheckboxChange(title, name, checked);
    };

    return (
        <Accordion
            className='accordion-custom-light'
            defaultActiveKey={['overall_status', 'permitting_status', 'incentives_status']}
            alwaysOpen
        >
            <Accordion.Item eventKey={title}>
                <Accordion.Header>{titleName}</Accordion.Header>
                <Accordion.Body>
                    <ul className='filter-listing-check'>
                        {statuses.map(({ id, label }) => (
                            <li key={id}>
                                <div className='cstm-checkbox form-check'>
                                    <input
                                        type='checkbox'
                                        name={id}
                                        id={`${title}-${id}`}
                                        onChange={handleCheckboxChange}
                                        checked={selectedStatuses.includes(id)}
                                        className='form-check-input'
                                    />
                                    <label className='text-regular-15 text-cool-grey-500 form-check-label' htmlFor={`${title}-${id}`}>
                                        {label}
                                    </label>
                                </div>
                            </li>
                        ))}
                    </ul>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};
