import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import React from 'react';

import { ICreateProject } from 'pages/CreateProjectStepTwo/CreateProjectStepTwo.models';

interface ProjectCardAccordionProps {
    projectInfo?: ICreateProject;
}

export const ProjectCardAccordion = ({ projectInfo }: ProjectCardAccordionProps) => (
    <Accordion className='accordion-custom-light' defaultActiveKey={['0', '1']} alwaysOpen>
        <div className='project-popup-inner'>
            <Accordion.Item eventKey='0'>
                <div className='project-popup-label d-flex flex-wrap align-items-center justify-content-between gap-2 pb9'>
                    <h4 className='text-semibold-16 text-cool-grey-800 '>Location</h4>
                    <Accordion.Header />
                </div>

                <Accordion.Body>
                    <div className='project-popup-body'>
                        <Row>
                            <Col xs='12' md='6' lg='4'>
                                <div className='project-popup-detail'>
                                    <div className='label-name text-regular-14 text-cool-grey-500'>State</div>
                                    <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                        {projectInfo?.location?.state}, {projectInfo?.location?.country}
                                    </div>
                                </div>
                            </Col>
                            <Col xs='12' md='6' lg='4'>
                                <div className='project-popup-detail'>
                                    <div className='label-name text-regular-14 text-cool-grey-500'>City / Municipality</div>
                                    <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                        {projectInfo?.location?.city}
                                    </div>
                                </div>
                            </Col>
                            <Col xs='12' md='6' lg='4'>
                                <div className='project-popup-detail'>
                                    <div className='label-name text-regular-14 text-cool-grey-500'>Zip Code</div>
                                    <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                        {projectInfo?.location?.zip_code}
                                    </div>
                                </div>
                            </Col>
                            <Col xs='12' md='6' lg='4'>
                                <div className='project-popup-detail'>
                                    <div className='label-name text-regular-14 text-cool-grey-500'>Address</div>
                                    <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                        {projectInfo?.customer_address}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </div>

        <div className='project-popup-inner'>
            <Accordion.Item eventKey='1'>
                <div className='project-popup-label d-flex flex-wrap align-items-center justify-content-between gap-2 pb9'>
                    <h4 className='text-semibold-16 text-cool-grey-800'>Project details</h4>
                    <Accordion.Header />
                </div>

                <Accordion.Body>
                    <div className='project-popup-body'>
                        <Row>
                            <Col xs='12' md='6' lg='4'>
                                <div className='project-popup-detail'>
                                    <div className='label-name text-regular-14 text-cool-grey-500'>Customer name</div>
                                    <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                        {projectInfo?.customer_name}
                                    </div>
                                </div>
                            </Col>
                            <Col xs='12' md='6' lg='4'>
                                <div className='project-popup-detail'>
                                    <div className='label-name text-regular-14 text-cool-grey-500'>Contact email</div>
                                    <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                        {projectInfo?.contact_email}
                                    </div>
                                </div>
                            </Col>
                            <Col xs='12' md='6' lg='4'>
                                <div className='project-popup-detail'>
                                    <div className='label-name text-regular-14 text-cool-grey-500'>Contact phone</div>
                                    <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                        {projectInfo?.contact_phone}
                                    </div>
                                </div>
                            </Col>
                            <Col xs='12' md='6' lg='4'>
                                <div className='project-popup-detail'>
                                    <div className='label-name text-regular-14 text-cool-grey-500'>Customer Address</div>
                                    <div className='project-popup-content text-regular-15 text-cool-grey-800'>{projectInfo?.address}</div>
                                </div>
                            </Col>
                            <Col xs='12' md='6' lg='4'>
                                <div className='project-popup-detail'>
                                    <div className='label-name text-regular-14 text-cool-grey-500'>Project type</div>
                                    <div className='project-popup-content text-regular-15 text-cool-grey-800'>
                                        {projectInfo?.technology}
                                    </div>
                                </div>
                            </Col>
                            <Col xs='12' md='6' lg='4'>
                                <div className='project-popup-detail'>
                                    <div className='label-name text-regular-14 text-cool-grey-500'>Technology size</div>
                                    <div className='project-popup-content text-regular-15 text-cool-grey-800'>Technology size</div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </div>
    </Accordion>
);
