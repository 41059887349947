import { ActionReducerMapBuilder, PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { INotification } from 'models';
import { getMethod, putMethod } from 'services/api.service';
import { NOTIFICATIONS } from 'utils/constants';

interface INotificationReturnType {
    data: INotification[];
    number_of_unread_notifications: number;
}

export interface INotificationState {
    notifications: INotification[];
    number_of_unread_notifications: number;
}

const initialState: INotificationState = {
    notifications: [],
    number_of_unread_notifications: 0,
};

export const fetchNotifications = createAsyncThunk('notifications/fetchNotifications', async () => {
    const { data } = await getMethod(NOTIFICATIONS.NOTIFICATION);

    return data;
});

export const readNotification = createAsyncThunk('notifications/readNotification', async (notificationId: number) => {
    const { data } = await putMethod(`${NOTIFICATIONS.NOTIFICATION}?notification_id=${notificationId}`);

    return data;
});

export const readNotifications = createAsyncThunk('notifications/readNotifications', async () => {
    const { data } = await putMethod(NOTIFICATIONS.NOTIFICATION);

    return data;
});

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {},
    extraReducers: (builder: ActionReducerMapBuilder<INotificationState>) => {
        builder.addCase(fetchNotifications.fulfilled, (state, action: PayloadAction<INotificationReturnType>) => {
            state.notifications = action.payload.data;
            state.number_of_unread_notifications = action.payload.number_of_unread_notifications;
        });
        builder.addCase(readNotification.fulfilled, (state, action: PayloadAction<INotificationReturnType>) => {
            state.notifications = action.payload.data;
            state.number_of_unread_notifications = action.payload.number_of_unread_notifications;
        });
        builder.addCase(readNotifications.fulfilled, (state, action: PayloadAction<INotificationReturnType>) => {
            state.notifications = action.payload.data;
            state.number_of_unread_notifications = 0;
        });
    },
});

export const { reducer } = notificationsSlice;
