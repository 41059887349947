import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IEmailState {
    email: string | null;
}

const initialState: IEmailState = {
    email: null,
};

export const emailSlice = createSlice({
    name: 'email',
    initialState,
    reducers: {
        setEmail: (state, action: PayloadAction<string>) => {
            return { ...state, email: action.payload };
        },
    },
});

export const { setEmail } = emailSlice.actions;
export const { reducer } = emailSlice;
