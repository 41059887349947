import * as Yup from 'yup';

import {
    ElectricUtilityType,
    GasUtilityType,
    ICreatePermit,
    PropertyOwnershipType,
    ResponsibleForPayingElectricServicesType,
} from './NewPermit.models';

export const validationSchema: Yup.Schema<Omit<ICreatePermit, 'project_id'>> = Yup.object({
    name: Yup.string().required('Name is required'),
    host_customer: Yup.object({
        date: Yup.string()
            .required('Date is required')
            .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must be in YYYY-MM-DD format'),
        name: Yup.string().required('Customer name is required'),
        company: Yup.string().required('Company name is required'),
        address: Yup.string().required('Address is required'),
        email: Yup.string().required('Email is required').email('Invalid email format'),
        phone: Yup.string()
            .required('Phone number is required')
            .matches(/^\+?[1-9]\d{1,14}$/, 'Invalid phone number'),
    }).required(),
    project_site: Yup.object({
        name: Yup.string().required('Project site name is required'),
        total_units: Yup.number().required('Total units is required').positive('Total units must be a positive number'),
        address: Yup.string().required('Project site address is required'),
        ownership_type: Yup.mixed<keyof typeof PropertyOwnershipType>()
            .oneOf(Object.keys(PropertyOwnershipType) as (keyof typeof PropertyOwnershipType)[])
            .required('Ownership type is required'),
    }).required(),
    property_eligibility: Yup.object({
        is_within_median_income_area: Yup.boolean().required('Field is required'),
        is_disadvantaged_community: Yup.boolean().required('Field is required'),
        is_owned_by_a_tribe: Yup.boolean().required('Field is required'),
        is_rental_housing: Yup.boolean().required('Field is required'),
        census_tract_code: Yup.string().required('Census tract code is required'),
        has_certificate_of_occupancy: Yup.boolean().required('Field is required'),
        has_solar_construction_started: Yup.boolean().required('Field is required'),
        was_referral_to_SOMAH_program: Yup.boolean().required('Field is required'),
    }).required(),
    electrical_utility: Yup.object({
        utility: Yup.mixed<keyof typeof ElectricUtilityType>()
            .oneOf(Object.keys(ElectricUtilityType) as (keyof typeof ElectricUtilityType)[])
            .required('Utility type is required'),
        does_receive_electric_service_from_CCA: Yup.boolean().required('Field is required'),
        responsible_for_paying_electric_services: Yup.mixed<keyof typeof ResponsibleForPayingElectricServicesType>()
            .oneOf(Object.keys(ResponsibleForPayingElectricServicesType) as (keyof typeof ResponsibleForPayingElectricServicesType)[])
            .required('Responsible for paying electric services is required'),
        meters_of_common_areas: Yup.number().required('Meters of common areas is required').positive('Must be a positive number'),
    }).required(),
    gas_utility: Yup.object({
        utility: Yup.mixed<keyof typeof GasUtilityType>()
            .oneOf(Object.keys(GasUtilityType) as (keyof typeof GasUtilityType)[])
            .required('Gas utility type is required'),
    }).required(),
});
