import React, { useState, useEffect, useCallback } from 'react';
import Badge from 'react-bootstrap/Badge';
import OffCanvas from 'react-bootstrap/Offcanvas';

import { Button, IconLinkButton } from 'components';
import { FilterProjectsAccordion } from '../FilterProjectsAccordion/FilterProjectsAccordion';
import { ProjectFilter } from '../../Projects.model';

interface FilterProjectsDrawerProps {
    onApplyFilter: (filterValue: ProjectFilter) => void;
}

const statusCheckboxes = [
    {
        title: 'overall_status',
        statuses: [
            { id: 'Draft', label: 'Draft' },
            { id: 'Active', label: 'Active' },
            { id: 'In Progress', label: 'In Progress' },
            { id: 'Done', label: 'Done' },
        ],
        titleName: 'Overall Status',
    },
    {
        title: 'permit_status',
        statuses: [
            { id: 'Not Started', label: 'Not Started' },
            { id: 'In Review', label: 'In Review' },
            { id: 'Pending', label: 'Pending' },
            { id: 'Approved', label: 'Approved' },
        ],
        titleName: 'Permitting Status',
    },
    {
        title: 'incentives_status',
        statuses: [
            { id: 'Active', label: 'Active' },
            { id: 'In Progress', label: 'In Progress' },
            { id: 'Done', label: 'Done' },
        ],
        titleName: 'Incentives Status',
    },
];

export const FilterProjectsDrawer = ({ onApplyFilter }: FilterProjectsDrawerProps) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectedStatus, setSelectedStatus] = useState<ProjectFilter>({});
    const [isSelectedValueEmpty, setIsSelectedValueEmpty] = useState<boolean>(false);
    const handleCheckboxChange = (title: string, id: string, isChecked: boolean) => {
        setSelectedStatus((prevSelectedStatus) => {
            const updatedStatus = { ...prevSelectedStatus };

            if (isChecked) {
                updatedStatus[title as keyof ProjectFilter] = [...(updatedStatus[title as keyof ProjectFilter] || []), id];
            } else {
                updatedStatus[title as keyof ProjectFilter] = (updatedStatus[title as keyof ProjectFilter] || []).filter(
                    (selectedId) => selectedId !== id,
                );
            }

            return updatedStatus;
        });
    };

    const handleRemoveChip = (title: string, id: string) => {
        setSelectedStatus((prevSelectedStatus) => {
            const updatedStatus = {
                ...prevSelectedStatus,
                [title]: (prevSelectedStatus[title as keyof ProjectFilter] || []).filter((v) => v !== id),
            };
            setIsSelectedValueEmpty(Object.values(updatedStatus).every((array) => array.length === 0));

            return updatedStatus;
        });
    };

    const clearAll = useCallback(() => {
        setSelectedStatus({});
        onApplyFilter({
            overall_status: [],
            permit_status: [],
            incentives_status: [],
        });
    }, [onApplyFilter]);

    const handleApply = () => {
        setShow(false);

        if (Object.keys(selectedStatus).length === 0) {
            onApplyFilter({
                overall_status: [],
                permit_status: [],
                incentives_status: [],
            });
        } else {
            const isAllFilterEmpty = Object.values(selectedStatus).every((x) => !x.length);

            if (isAllFilterEmpty) {
                clearAll();
            }
            onApplyFilter(selectedStatus);
        }
    };

    useEffect(() => {
        if (isSelectedValueEmpty) {
            clearAll();
        }
    }, [clearAll, isSelectedValueEmpty]);

    return (
        <>
            <IconLinkButton className='filter-btn' onClick={handleShow}>
                <span className='icon-sm'>
                    <i className='fi fi-rr-filter' />
                </span>
            </IconLinkButton>
            <OffCanvas show={show} onHide={handleClose} placement='end' className='filter-drawer-card projects-filter-sidebar'>
                <OffCanvas.Header>
                    <div className='filter-header-bar d-flex align-items-center justify-content-between gap-2 flex-wrap'>
                        <OffCanvas.Title>Filters</OffCanvas.Title>
                        <IconLinkButton onClick={() => clearAll()} disabled={Object.keys(selectedStatus).length === 0 ? 'disabled' : ''}>
                            Clear All
                            <span className='icon-sm pl4'>
                                <i className='fi fi-rr-cross-small' />
                            </span>
                        </IconLinkButton>
                    </div>
                    <div className='filter-chip-list chip-tag-list pt8'>
                        <div className='regulation-list-chip chips-scroll'>
                            <div className='chip-tag-list'>
                                {Object.entries(selectedStatus).map(([title, chips], index) => (
                                    <React.Fragment key={index}>
                                        {chips.map((chip: string) => (
                                            <div className='custom-chip' key={chip}>
                                                <Badge pill bg='primary'>
                                                    <span className='chip_title text-trunk'>{chip}</span>
                                                    <button
                                                        type='button'
                                                        className='btn-cross'
                                                        onClick={() => handleRemoveChip(title, chip)}
                                                    >
                                                        <i className='fi fi-rr-cross-small' />
                                                    </button>
                                                </Badge>
                                            </div>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                </OffCanvas.Header>
                <OffCanvas.Body>
                    <div className='filter-listing-box'>
                        {statusCheckboxes.map(({ title, statuses, titleName }) => (
                            <FilterProjectsAccordion
                                key={title}
                                title={title}
                                statuses={statuses}
                                selectedStatuses={selectedStatus[title as keyof ProjectFilter] || []}
                                onCheckboxChange={handleCheckboxChange}
                                titleName={titleName}
                            />
                        ))}
                    </div>
                </OffCanvas.Body>
                <div className='filter-footer text-center'>
                    <Button
                        variant='primary'
                        btnFullClassName='w-100'
                        size='sm'
                        onClick={handleApply}
                        disabled={Object.keys(selectedStatus).length === 0 ? 'disabled' : ''}
                    >
                        Apply
                    </Button>
                </div>
            </OffCanvas>
        </>
    );
};
