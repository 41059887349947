import React, { forwardRef } from 'react';

interface ForwardedCustomInputProps {
    label: string;
}

function ForwardedCustomInput({ label }: ForwardedCustomInputProps, forwardedRef: React.ForwardedRef<HTMLInputElement>) {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label htmlFor='custom-input' style={{ marginBlockEnd: 10 }}>
                {label}
            </label>
            <input ref={forwardedRef} id='custom-input' style={{ borderRadius: 10, padding: 10, borderColor: 'blue' }} />
        </div>
    );
}

export const PhoneNumberMaskInput = forwardRef(ForwardedCustomInput);
