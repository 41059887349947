import React from 'react';

import { Modal, ModalBody, ModalFooter, Button } from 'components';

interface ConfirmModalProps {
    show: boolean;
    modalTitle?: string;
    imageUrl?: string;
    title?: string;
    className?: string;
    leftButtonLabel?: string;
    rightButtonLabel?: string;
    onClose?: () => void;
    onClickLeftButton?: () => void;
    onClickRightButton?: () => void;
}

export const ConfirmModal = ({
    show,
    modalTitle,
    imageUrl,
    title,
    leftButtonLabel,
    rightButtonLabel,
    className,
    onClose,
    onClickLeftButton,
    onClickRightButton,
}: ConfirmModalProps) => (
    <Modal show={show} onClose={onClose} title={modalTitle} className='modal-wrapper modal-header-none' size='sm'>
        <ModalBody>
            <div className={`verify-email-box max-w440 m-auto ${className}`}>
                <div className='header-icon-box pb16 text-center'>
                    <img src={imageUrl} alt='email-icon' />
                </div>
                <div className='text-bold-28 pb16 text-cool-grey-800 text-center w-100'>{title}</div>
            </div>
        </ModalBody>
        <ModalFooter className='flex-nowrap gap-2 max-w440 m-auto'>
            <Button onClick={onClickLeftButton} variant='outline-primary' btnFullClassName='w-100' size='sm'>
                {leftButtonLabel}
            </Button>
            <Button variant='primary' size='sm' btnFullClassName='w-100' onClick={onClickRightButton}>
                {rightButtonLabel}
            </Button>
        </ModalFooter>
    </Modal>
);
