import React from 'react';

import { Modal, ModalBody, ModalFooter, Button, VSelect } from 'components';
import { ISelectOption } from 'models';

interface SelectProjectModalProps {
    show: boolean;
    modalTitle?: string;
    imageUrl?: string;
    title?: string;
    leftButtonLabel?: string;
    rightButtonLabel?: string;
    placeholder?: string;
    imageClassName?: string;
    projectOptions: ISelectOption<string>[];
    disableButton?: boolean;
    onClose?: () => void;
    onClickLeftButton?: () => void;
    onClickRightButton?: () => void;
    onSelectProject?: (projectName: string) => void;
}

export const SelectProjectModal = ({
    show,
    onClose,
    modalTitle,
    imageUrl,
    title,
    onClickLeftButton,
    leftButtonLabel,
    onClickRightButton,
    rightButtonLabel,
    placeholder,
    imageClassName,
    projectOptions,
    onSelectProject,
    disableButton,
}: SelectProjectModalProps) => (
    <Modal show={show} onClose={onClose} title={modalTitle} className='modal-wrapper modal-header-none' size='sm'>
        <ModalBody>
            <div className='verify-email-box max-w440 m-auto'>
                <div className='header-icon-box pb16 text-center '>
                    <img src={imageUrl} alt='email-icon' className={`${imageClassName}`} />
                </div>
                <div className='text-bold-28 pb12 text-cool-grey-800 text-center w-100'>{title}</div>
                <div className='email-address-field pb8'>
                    <VSelect
                        options={projectOptions}
                        isLabel
                        placeholder={placeholder || ''}
                        onSelect={(type) => onSelectProject?.(type)}
                    />
                </div>
            </div>
        </ModalBody>
        <ModalFooter className='flex-nowrap gap-2 max-w440 m-auto'>
            <Button onClick={onClickLeftButton} variant='outline-primary' btnFullClassName='w-100' size='sm'>
                {leftButtonLabel}
            </Button>
            <Button
                variant='primary'
                size='sm'
                btnFullClassName='w-100'
                onClick={onClickRightButton}
                disabled={disableButton ? 'disabled' : ''}
            >
                {rightButtonLabel}
            </Button>
        </ModalFooter>
    </Modal>
);
