import { RootState } from './rootReducer';

export const selectPermit = ({ permit }: RootState) => permit.permit;
export const selectProjectLocation = ({ location }: RootState) => location.location;
export const selectDashboardFilter = ({ dashboardFilter }: RootState) => dashboardFilter.filter;
export const selectEmail = ({ email }: RootState) => email.email;
export const selectNotifications = ({ notification }: RootState) => notification.notifications;
export const selectProject = ({ project }: RootState) => project.project;
export const selectSearchTerm = ({ search }: RootState) => search.searchTerm;
export const selectSearchResultCount = ({ search }: RootState) => search.count;
