import React from 'react';
import { FormGroup, FormLabel } from 'react-bootstrap';
import { useField } from 'formik';

import { Radio } from 'components';

import { RadioFieldItem } from './RadioField.models';

interface RadioFieldProps {
    className?: string;
    label: string;
    fieldName: string;
    options: RadioFieldItem[];
}

export const RadioField = ({ className, label, fieldName, options }: RadioFieldProps) => {
    const [field, , helpers] = useField(fieldName);

    return (
        <FormGroup className={`custom_form mb7 ${className}`}>
            <div className='form-label-outer d-flex align-items-center flex-wrap justify-content-between gap-2'>
                <FormLabel className='text-regular-15 text-cool-grey-800 flex-grow-1'>{label}</FormLabel>
            </div>
            <div className='radio-btn-outer d-flex flex-wrap align-items-center gap-5 pt3 pb4'>
                {options.map((option) => (
                    <Radio
                        key={option.name}
                        labelName={option.label}
                        name={fieldName}
                        value={option.name}
                        checked={field.value === option.value}
                        checkId={`${fieldName}-${option.name}`}
                        htmlFor={`${fieldName}-${option.name}`}
                        onChange={() => helpers.setValue(option.value)}
                    />
                ))}
            </div>
        </FormGroup>
    );
};
