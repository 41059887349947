import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, ConfirmModal, IconLinkButton } from 'components';
import { ICreateProject } from 'pages/CreateProjectStepTwo/CreateProjectStepTwo.models';
import { postMethod } from 'services/api.service';
import { PROJECTS } from 'utils/constants';
import { CreateProjectModal, SaveDraftModal } from 'pages/CreateProject/components';
import { ILocation } from 'models';

interface CreateProjectStepTwoFooterProps {
    isValid: boolean;
    values: ICreateProject;
    location: ILocation;
    onBackToProject: () => void;
}

export const CreateProjectStepTwoFooter = ({ isValid, values, location, onBackToProject }: CreateProjectStepTwoFooterProps) => {
    const navigate = useNavigate();
    const [showSaveDraftModal, setShowSaveDraftModal] = useState(false);
    const [showSavedDraftModal, setShowSavedDraftModal] = useState(false);
    const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);

    const handleCreateProjectAsDraft = useCallback(async () => {
        const inputPhoneNumber = values?.contact_phone || '';
        const numericPhoneNumber = inputPhoneNumber.replace(/\D/g, '');
        const countryCode = '+';
        const formattedPhoneNumber = numericPhoneNumber.startsWith(countryCode) ? numericPhoneNumber : countryCode + numericPhoneNumber;

        const payload = {
            ...values,
            is_draft: true,
            contact_phone: formattedPhoneNumber,
            location,
        };

        try {
            const { data } = await postMethod(PROJECTS.PROJECT, payload);

            if (data.status === true) {
                setShowSaveDraftModal(false);
                setShowSavedDraftModal(true);
                localStorage.removeItem('locationValue');
            }
        } catch (error) {
            // TODO: Error handling
        }
    }, [location, values]);

    return (
        <div className='footer-project pt12 d-flex align-items-center gap-3'>
            <Button variant='outline-primary' size='sm' onClick={() => onBackToProject()}>
                Back
            </Button>
            <IconLinkButton className='filter-btn ms-auto' onClick={() => setShowSaveDraftModal(true)} disabled={isValid ? '' : 'disabled'}>
                Save as Draft
                <span className='icon-sm pl4'>
                    <i className={isValid ? 'fi fi-rr-cloud-upload' : 'fi fi-rr-document'} />
                </span>
            </IconLinkButton>
            <Button
                variant='primary'
                size='sm'
                className='w-100 max-w200'
                disabled={isValid ? '' : 'disabled'}
                onClick={() => setShowCreateProjectModal(true)}
            >
                Create Project
            </Button>

            <ConfirmModal
                show={showSaveDraftModal}
                onClickLeftButton={() => setShowSaveDraftModal(false)}
                imageUrl='/assets/images/save-draft.svg'
                title='Save this Project as Draft?'
                leftButtonLabel='Cancel'
                rightButtonLabel='Save as Draft'
                onClickRightButton={handleCreateProjectAsDraft}
            />

            <SaveDraftModal
                show={showSavedDraftModal}
                onClose={() => {
                    setShowSavedDraftModal(false);
                    navigate('/projects');
                }}
                imageUrl='/assets/images/save-drafticon.svg'
                title='Project Saved as Draft'
            />

            {showCreateProjectModal && (
                <CreateProjectModal onClose={() => setShowCreateProjectModal(false)} projectInfo={values} location={location} />
            )}
        </div>
    );
};
