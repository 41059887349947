import React from 'react';
import Select from 'react-select';

import { ISelectOption } from 'models';
import { customStyles } from 'utils/commonFunctions';

interface VSelectProps<T> {
    options: ISelectOption<T>[];
    label?: string;
    placeholder?: string;
    isLabel?: boolean;
    selectClassName?: string;
    onSelect?: (item: T) => void;
}

export const VSelect = <T,>({ options, label, placeholder, isLabel, onSelect, selectClassName }: VSelectProps<T>) => (
    <div className='custom_form mb7'>
        {!isLabel && <label className='select-label'>{label}</label>}
        <Select
            onChange={(e) => {
                if (onSelect && e) {
                    onSelect?.(e?.value);
                }
            }}
            options={options}
            styles={customStyles}
            className={`custom-select-box ${selectClassName}`}
            placeholder={placeholder}
        />
    </div>
);
