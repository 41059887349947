import Button from 'react-bootstrap/Button';
import React, { MouseEvent, ReactNode } from 'react';

interface IconButtonProps {
    className?: string;
    disabled?: string;
    size?: 'sm' | 'lg';
    variant?: string;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    children?: ReactNode;
}

export const IconButton = ({ className, disabled, size = 'sm', variant = 'link', onClick, children }: IconButtonProps) => (
    <Button className={`cstm-icon-btn ${className} ${disabled}`} size={size} variant={variant} onClick={(event) => onClick?.(event)}>
        {children}
    </Button>
);
