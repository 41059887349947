import React from 'react';
import { Link } from 'react-router-dom';

export const HeaderLogo = () => (
    <div className='form-logo'>
        <Link to='/dashboard'>
            <img src='/assets/images/Logo.svg' alt='logo' />
        </Link>
    </div>
);
