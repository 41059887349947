import { useState, useEffect, useMemo, useCallback } from 'react';

import { kmlMapping } from 'hooks/useKmlVisibility/kmlMapping';

const useKmlVisibility = (
    map: google.maps.Map | undefined,
    isLoaded: boolean,
    showCoalClosure?: boolean,
    showMSA?: boolean,
    showCEJST?: boolean,
    showPPC?: boolean,
    showCategory1?: boolean,
    showMediumScaleSolar?: boolean,
    showLargeScaleSolar?: boolean,
) => {
    const [allKmlUrls, setAllKmlUrls] = useState<string[]>([]);
    const [visibleKmlUrls, setVisibleKmlUrls] = useState<string[]>([]);

    const stateBoundingBoxes: { [state: string]: google.maps.LatLngBounds } = useMemo(() => {
        if (!isLoaded) {
            return {
                Alabama: {} as google.maps.LatLngBounds,
                Alaska: {} as google.maps.LatLngBounds,
                Arizona: {} as google.maps.LatLngBounds,
                Arkansas: {} as google.maps.LatLngBounds,
                California: {} as google.maps.LatLngBounds,
                Colorado: {} as google.maps.LatLngBounds,
                Connecticut: {} as google.maps.LatLngBounds,
                Delaware: {} as google.maps.LatLngBounds,
                Florida: {} as google.maps.LatLngBounds,
                Georgia: {} as google.maps.LatLngBounds,
                Hawaii: {} as google.maps.LatLngBounds,
                Idaho: {} as google.maps.LatLngBounds,
                Illinois: {} as google.maps.LatLngBounds,
                Indiana: {} as google.maps.LatLngBounds,
                Iowa: {} as google.maps.LatLngBounds,
                Kansas: {} as google.maps.LatLngBounds,
                Kentucky: {} as google.maps.LatLngBounds,
                Louisiana: {} as google.maps.LatLngBounds,
                Maine: {} as google.maps.LatLngBounds,
                Maryland: {} as google.maps.LatLngBounds,
                Massachusetts: {} as google.maps.LatLngBounds,
                Michigan: {} as google.maps.LatLngBounds,
                Minnesota: {} as google.maps.LatLngBounds,
                Mississippi: {} as google.maps.LatLngBounds,
                Missouri: {} as google.maps.LatLngBounds,
                Montana: {} as google.maps.LatLngBounds,
                Nebraska: {} as google.maps.LatLngBounds,
                Nevada: {} as google.maps.LatLngBounds,
                'New Hampshire': {} as google.maps.LatLngBounds,
                'New Jersey': {} as google.maps.LatLngBounds,
                'New Mexico': {} as google.maps.LatLngBounds,
                'New York': {} as google.maps.LatLngBounds,
                'North Carolina': {} as google.maps.LatLngBounds,
                'North Dakota': {} as google.maps.LatLngBounds,
                Ohio: {} as google.maps.LatLngBounds,
                Oklahoma: {} as google.maps.LatLngBounds,
                Oregon: {} as google.maps.LatLngBounds,
                Pennsylvania: {} as google.maps.LatLngBounds,
                'Rhode Island': {} as google.maps.LatLngBounds,
                'South Carolina': {} as google.maps.LatLngBounds,
                'South Dakota': {} as google.maps.LatLngBounds,
                Tennessee: {} as google.maps.LatLngBounds,
                Texas: {} as google.maps.LatLngBounds,
                Utah: {} as google.maps.LatLngBounds,
                Vermont: {} as google.maps.LatLngBounds,
                Virginia: {} as google.maps.LatLngBounds,
                Washington: {} as google.maps.LatLngBounds,
                'West Virginia': {} as google.maps.LatLngBounds,
                Wisconsin: {} as google.maps.LatLngBounds,
                Wyoming: {} as google.maps.LatLngBounds,
                Southbridge: {} as google.maps.LatLngBounds,
            };
        }

        return {
            Alabama: new google.maps.LatLngBounds(
                new google.maps.LatLng(30.223334, -88.473227),
                new google.maps.LatLng(35.008028, -84.888246),
            ),
            Alaska: new google.maps.LatLngBounds(
                new google.maps.LatLng(51.214183, -179.148909),
                new google.maps.LatLng(71.365162, 179.77847),
            ),
            Arizona: new google.maps.LatLngBounds(
                new google.maps.LatLng(31.332177, -114.81871),
                new google.maps.LatLng(37.00426, -109.045223),
            ),
            Arkansas: new google.maps.LatLngBounds(
                new google.maps.LatLng(33.004106, -94.617919),
                new google.maps.LatLng(36.499767, -89.644395),
            ),
            California: new google.maps.LatLngBounds(
                new google.maps.LatLng(32.528832, -124.482003),
                new google.maps.LatLng(42.009517, -114.131211),
            ),
            Colorado: new google.maps.LatLngBounds(
                new google.maps.LatLng(36.992426, -109.060253),
                new google.maps.LatLng(41.003444, -102.041524),
            ),
            Connecticut: new google.maps.LatLngBounds(
                new google.maps.LatLng(40.980831, -73.727775),
                new google.maps.LatLng(42.050587, -71.787239),
            ),
            Delaware: new google.maps.LatLngBounds(
                new google.maps.LatLng(38.451013, -75.789265),
                new google.maps.LatLng(39.839007, -75.048939),
            ),
            Florida: new google.maps.LatLngBounds(
                new google.maps.LatLng(24.523096, -87.634938),
                new google.maps.LatLng(31.000888, -80.031362),
            ),
            Georgia: new google.maps.LatLngBounds(
                new google.maps.LatLng(30.357851, -85.605165),
                new google.maps.LatLng(35.000659, -80.839729),
            ),
            Hawaii: new google.maps.LatLngBounds(
                new google.maps.LatLng(18.910361, -178.334698),
                new google.maps.LatLng(28.402123, -154.806773),
            ),
            Idaho: new google.maps.LatLngBounds(
                new google.maps.LatLng(41.988057, -117.243027),
                new google.maps.LatLng(49.001146, -111.043564),
            ),
            Illinois: new google.maps.LatLngBounds(
                new google.maps.LatLng(36.970298, -91.513079),
                new google.maps.LatLng(42.508481, -87.494756),
            ),
            Indiana: new google.maps.LatLngBounds(
                new google.maps.LatLng(37.771742, -88.09776),
                new google.maps.LatLng(41.760592, -84.784579),
            ),
            Iowa: new google.maps.LatLngBounds(
                new google.maps.LatLng(40.375501, -96.639704),
                new google.maps.LatLng(43.501196, -90.140061),
            ),
            Kansas: new google.maps.LatLngBounds(
                new google.maps.LatLng(36.993016, -102.051744),
                new google.maps.LatLng(40.003162, -94.588413),
            ),
            Kentucky: new google.maps.LatLngBounds(
                new google.maps.LatLng(36.497129, -89.571203),
                new google.maps.LatLng(39.147458, -81.964971),
            ),
            Louisiana: new google.maps.LatLngBounds(
                new google.maps.LatLng(28.928609, -94.043147),
                new google.maps.LatLng(33.019457, -88.817017),
            ),
            Maine: new google.maps.LatLngBounds(
                new google.maps.LatLng(43.064817, -71.083924),
                new google.maps.LatLng(47.459686, -66.949895),
            ),
            Maryland: new google.maps.LatLngBounds(
                new google.maps.LatLng(37.911717, -79.487651),
                new google.maps.LatLng(39.723043, -75.048939),
            ),
            Massachusetts: new google.maps.LatLngBounds(
                new google.maps.LatLng(41.237964, -73.508142),
                new google.maps.LatLng(42.886589, -69.928393),
            ),
            Michigan: new google.maps.LatLngBounds(
                new google.maps.LatLng(41.696118, -90.418136),
                new google.maps.LatLng(48.2388, -82.413474),
            ),
            Minnesota: new google.maps.LatLngBounds(
                new google.maps.LatLng(43.499356, -97.239209),
                new google.maps.LatLng(49.384358, -89.491739),
            ),
            Mississippi: new google.maps.LatLngBounds(
                new google.maps.LatLng(30.173943, -91.655009),
                new google.maps.LatLng(34.996052, -88.097888),
            ),
            Missouri: new google.maps.LatLngBounds(
                new google.maps.LatLng(35.995683, -95.774704),
                new google.maps.LatLng(40.61364, -89.098843),
            ),
            Montana: new google.maps.LatLngBounds(
                new google.maps.LatLng(44.358221, -116.050003),
                new google.maps.LatLng(49.00139, -104.039138),
            ),
            Nebraska: new google.maps.LatLngBounds(
                new google.maps.LatLng(39.999998, -104.053514),
                new google.maps.LatLng(43.001708, -95.30829),
            ),
            Nevada: new google.maps.LatLngBounds(
                new google.maps.LatLng(35.001857, -120.005746),
                new google.maps.LatLng(42.002207, -114.039648),
            ),
            'New Hampshire': new google.maps.LatLngBounds(
                new google.maps.LatLng(42.69698, -72.557247),
                new google.maps.LatLng(45.305476, -70.610621),
            ),
            'New Jersey': new google.maps.LatLngBounds(
                new google.maps.LatLng(38.928519, -75.559614),
                new google.maps.LatLng(41.357423, -73.893979),
            ),
            'New Mexico': new google.maps.LatLngBounds(
                new google.maps.LatLng(31.332301, -109.050173),
                new google.maps.LatLng(37.000232, -103.001964),
            ),
            'New York': new google.maps.LatLngBounds(
                new google.maps.LatLng(40.477399, -79.76259),
                new google.maps.LatLng(45.01585, -71.856214),
            ),
            'North Carolina': new google.maps.LatLngBounds(
                new google.maps.LatLng(33.842316, -84.321869),
                new google.maps.LatLng(36.588117, -75.460621),
            ),
            'North Dakota': new google.maps.LatLngBounds(
                new google.maps.LatLng(45.935054, -104.050575),
                new google.maps.LatLng(49.000574, -96.554507),
            ),
            Ohio: new google.maps.LatLngBounds(
                new google.maps.LatLng(38.403202, -84.820159),
                new google.maps.LatLng(41.977523, -80.518693),
            ),
            Oklahoma: new google.maps.LatLngBounds(
                new google.maps.LatLng(33.615833, -103.002565),
                new google.maps.LatLng(37.002206, -94.430662),
            ),
            Oregon: new google.maps.LatLngBounds(
                new google.maps.LatLng(41.991794, -124.566244),
                new google.maps.LatLng(46.292035, -116.463504),
            ),
            Pennsylvania: new google.maps.LatLngBounds(
                new google.maps.LatLng(39.7198, -80.519891),
                new google.maps.LatLng(42.269861, -74.689516),
            ),
            'Rhode Island': new google.maps.LatLngBounds(
                new google.maps.LatLng(41.146339, -71.862772),
                new google.maps.LatLng(42.018798, -71.120491),
            ),
            'South Carolina': new google.maps.LatLngBounds(
                new google.maps.LatLng(32.0346, -83.35391),
                new google.maps.LatLng(35.215402, -78.54203),
            ),
            'South Dakota': new google.maps.LatLngBounds(
                new google.maps.LatLng(42.479635, -104.057698),
                new google.maps.LatLng(45.945362, -96.436589),
            ),
            Tennessee: new google.maps.LatLngBounds(
                new google.maps.LatLng(34.982972, -90.310298),
                new google.maps.LatLng(36.678118, -81.6469),
            ),
            Texas: new google.maps.LatLngBounds(
                new google.maps.LatLng(25.837377, -106.645646),
                new google.maps.LatLng(36.500704, -93.508292),
            ),
            Utah: new google.maps.LatLngBounds(
                new google.maps.LatLng(36.997968, -114.052962),
                new google.maps.LatLng(42.001567, -109.041058),
            ),
            Vermont: new google.maps.LatLngBounds(
                new google.maps.LatLng(42.726853, -73.437741),
                new google.maps.LatLng(45.016659, -71.505372),
            ),
            Virginia: new google.maps.LatLngBounds(
                new google.maps.LatLng(36.540738, -83.675395),
                new google.maps.LatLng(39.466012, -75.242266),
            ),
            Washington: new google.maps.LatLngBounds(
                new google.maps.LatLng(45.543541, -124.848974),
                new google.maps.LatLng(49.002494, -116.916071),
            ),
            'West Virginia': new google.maps.LatLngBounds(
                new google.maps.LatLng(37.201483, -82.644739),
                new google.maps.LatLng(40.638801, -77.719519),
            ),
            Wisconsin: new google.maps.LatLngBounds(
                new google.maps.LatLng(42.491983, -92.888114),
                new google.maps.LatLng(47.080621, -86.805415),
            ),
            Wyoming: new google.maps.LatLngBounds(
                new google.maps.LatLng(40.994746, -111.056888),
                new google.maps.LatLng(45.005904, -104.052154),
            ),
            Southbridge: new google.maps.LatLngBounds(new google.maps.LatLng(42.0481, -72.0861), new google.maps.LatLng(42.1076, -71.9711)),
        };
    }, [isLoaded]);

    const getIntersectingStates = useCallback(
        (url: string, mapBounds?: google.maps.LatLngBounds): string[] => {
            if (!mapBounds) return [];

            return Object.keys(stateBoundingBoxes).filter((state) => {
                const stateBounds = stateBoundingBoxes[state];
                const intersects = mapBounds.intersects(stateBounds);

                return (
                    intersects &&
                    (Object.values((kmlMapping.CoalClosure as Record<string, string[]>)[state] ?? []).includes(url) ||
                        Object.values((kmlMapping.MSA_NMSA as Record<string, string[]>)[state] ?? []).includes(url) ||
                        Object.values((kmlMapping.lowIncomeCEJST as Record<string, string[]>)[state] ?? []).includes(url) ||
                        Object.values((kmlMapping.lowIncomeCategory1 as Record<string, string[]>)[state] ?? []).includes(url) ||
                        Object.values((kmlMapping.lowIncomePPC as Record<string, string[]>)[state] ?? []).includes(url) ||
                        Object.values((kmlMapping.solarMedium as Record<string, string[]>)[state] ?? []).includes(url) ||
                        Object.values((kmlMapping.solarLarge as Record<string, string[]>)[state] ?? []).includes(url))
                );
            });
        },
        [stateBoundingBoxes],
    );

    const handleBoundsChanged = useCallback(() => {
        if (!map) return;

        const bounds = map.getBounds();

        if (bounds) {
            const newVisibleKmlUrls = allKmlUrls.filter((url) => {
                const intersectingStates = getIntersectingStates(url, bounds);

                return intersectingStates.length > 0;
            });

            // Update state only if there is a change
            if (JSON.stringify(newVisibleKmlUrls) !== JSON.stringify(visibleKmlUrls)) {
                setVisibleKmlUrls(newVisibleKmlUrls);
            }
        }
    }, [map, allKmlUrls, getIntersectingStates, visibleKmlUrls]);

    useEffect(() => {
        const urls: string[] = [];

        if (showCoalClosure) urls.push(...Object.values(kmlMapping.CoalClosure).flat());

        if (showMSA) urls.push(...Object.values(kmlMapping.MSA_NMSA).flat());

        if (showCEJST) urls.push(...Object.values(kmlMapping.lowIncomeCEJST).flat());

        if (showPPC) urls.push(...Object.values(kmlMapping.lowIncomePPC).flat());

        if (showCategory1) urls.push(...Object.values(kmlMapping.lowIncomeCategory1).flat());

        if (showMediumScaleSolar) urls.push(...Object.values(kmlMapping.solarMedium).flat());

        if (showLargeScaleSolar) urls.push(...Object.values(kmlMapping.solarLarge).flat());

        setAllKmlUrls(urls);
    }, [showCoalClosure, showMSA, showCEJST, showPPC, showCategory1, showMediumScaleSolar, showLargeScaleSolar]);

    useEffect(() => {
        handleBoundsChanged();
    }, [allKmlUrls]);

    return { visibleKmlUrls, handleBoundsChanged };
};

export default useKmlVisibility;
