import React from 'react';
import { useField } from 'formik';

import { Input, InputProps } from 'components';

interface DateFieldProps extends InputProps {
    fieldName: string;
}

export const DateField = ({ fieldName, ...inputProps }: DateFieldProps) => {
    const [field, meta, helpers] = useField(fieldName);

    return (
        <Input
            {...inputProps}
            type='date'
            name={fieldName}
            onChange={(e) => helpers.setValue(e.target.value)}
            value={field.value}
            isInvalid={!!meta.error}
            errorMsg={meta.error}
        />
    );
};
