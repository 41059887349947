// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cstm-icon-btn.map-fullscreen-icon, .cstm-icon-btn.map-control-icon, .cstm-icon-btn.map-toggle-boundaries-icon {
  border-radius: 12px;
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
}

.map-toggle-boundaries-icon {
  color: gray;
}

.map-type-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  color: var(--cool-grey-800);
  padding-bottom: 10px;
}

.map-type-inner {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}
.map-type-inner .map-view-box {
  border: none;
}
.map-type-inner .map-view-box.active {
  border-radius: 12px;
  border: 2px solid var(--indigo-600);
}
.map-type-inner .map-view-box img {
  border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/ExplorerMap/ExplorerMap.styles.scss"],"names":[],"mappings":"AACE;EAGE,mBAAA;EACA,aAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;AAFJ;;AAMA;EACE,WAAA;AAHF;;AAMA;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,2BAAA;EACA,oBAAA;AAHF;;AAMA;EACE,aAAA;EACA,eAAA;EACA,SAAA;AAHF;AAKE;EACE,YAAA;AAHJ;AAKI;EACE,mBAAA;EACA,mCAAA;AAHN;AAMI;EACE,kBAAA;AAJN","sourcesContent":[".cstm-icon-btn {\n  &.map-fullscreen-icon,\n  &.map-control-icon,\n  &.map-toggle-boundaries-icon {\n    border-radius: 12px;\n    display: flex;\n    padding: 6px;\n    justify-content: center;\n    align-items: center;\n    width: 32px;\n    height: 32px;\n  }\n}\n\n.map-toggle-boundaries-icon {\n  color: gray;\n}\n\n.map-type-label {\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 1.2;\n  color: var(--cool-grey-800);\n  padding-bottom: 10px;\n}\n\n.map-type-inner {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 25px;\n\n  .map-view-box {\n    border: none;\n\n    &.active {\n      border-radius: 12px;\n      border: 2px solid var(--indigo-600);\n    }\n\n    img {\n      border-radius: 8px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
